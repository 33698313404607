import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { a3, a38, a39, a40, a5, s3 } from "../../Api/RootApiPath";
import { useDispatch, useSelector } from "react-redux";
import { userLoggedIn, userLoggedOut } from "../../../redux/action/Actions";
import { useNavigate } from "react-router-dom";
import CustomerDetailsEditBox from "./CustomerDetailsEditBox";
import { BsBoxSeam } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import { BiUserCircle, BiMessageDetail, BiLogOut } from "react-icons/bi";
import { InfinitySpin } from "react-loader-spinner";
import { AiOutlineEdit } from "react-icons/ai";
import { RxCross2, RxCheck } from "react-icons/rx";

export default function CustomerDetails({ csData, orderId }) {
  const [detailsType, setDetailsType] = useState("Orders");
  const [popUp, setPopup] = useState(false);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [logOut, setLogOut] = useState(false);
  const [allOrder, setAllOrder] = useState([]);
  const [myOrder, setMyOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [myInfoBox, setMyInfoBox] = useState(false);
  const [myBillingBox, setMyBillingBox] = useState(false);
  const [myDeliveryBox, setMyDeliveryBox] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [firstNameChanged, setFirstNameChanged] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [dayChanged, setDayChanged] = useState(false);
  const [monthChanged, setMonthChanged] = useState(false);
  const [yearChanged, setYearChanged] = useState(false);
  const [mobile, setMobile] = useState("");
  const [mobileChanged, setMobileChanged] = useState(false);
  const [perAdd, setPerAdd] = useState("");
  const [perAddChanged, setPerAddChanged] = useState(false);
  const [town, setTown] = useState("");
  const [townChanged, setTownChanged] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [pinCodeChanged, setPinCodeChanged] = useState(false);
  const [state, setState] = useState("");
  const [stateChanged, setStateChanged] = useState(false);
  const [currAdd, setCurrAdd] = useState("");
  const [currAddChanged, setCurrAddChanged] = useState(false);
  const [currTown, setCurrTown] = useState("");
  const [currTownChanged, setCurrTownChanged] = useState(false);
  const [currPinCode, setCurrPinCode] = useState("");
  const [currPinCodeChanged, setCurrPinCodeChanged] = useState(false);
  const [currState, setCurrState] = useState("");
  const [currStateChanged, setCurrStateChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [today] = useState(new Date());

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedOut = (index) => {
    dispatch(userLoggedOut(index));
  };
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  const csStates = useSelector((state) => state);
  const userDetails = csStates.reducer4;

  const [customerData, setCustomerData] = useState(userDetails);
  // console.log(csData);
  // console.log(csData);
  // console.log(csData);
  // console.log(csData);
  // console.log(csData);
  // console.log(userDetails);
  const updatedetails = (entry) => {
    setPopup(true);
  };
  const handleBirthDayChange = (day, month, year) => {
    const updatedUserDetails = userDetails.map((user) => {
      return {
        ...user,
        dob: `${day}/${month}/${year}`,
      };
    });

    loggedIn(updatedUserDetails);
  };
  const handleDayChange = (e) => {
    const input = e.target.value;
    // Validate day input
    if (
      input === "" ||
      (input.match(/^[0-9]{1,2}$/) && parseInt(input) <= 31)
    ) {
      setDay(input);
      handleBirthDayChange(input, month, year);
      setDayChanged(true);
    }
  };

  const handleMonthChange = (e) => {
    const input = e.target.value;
    // Validate month input
    if (
      input === "" ||
      (input.match(/^[0-9]{1,2}$/) && parseInt(input) <= 12)
    ) {
      setMonth(input);
      handleBirthDayChange(day, input, year);
      setMonthChanged(true);
    }
  };
  const handleYearChange = (e) => {
    const input = e.target.value;
    // Validate year input
    if (
      input === "" ||
      (input.match(/^[0-9]{1,4}$/) &&
        parseInt(input) <= new Date().getFullYear())
    ) {
      setYear(input);
      setYearChanged(true);
      handleBirthDayChange(day, month, input);
    }
  };

  // isDateOfBirthIncomplete = !day || !month || !year;
  let isDateOfBirthIncomplete = !day || !month || !year;
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e, property) => {
    const updatedUserDetails = userDetails.map((user) => {
      return {
        ...user,
        [property]: e.target.value,
      };
    });

    loggedIn(updatedUserDetails);
  };
  const scrollToCenter = (elementId) => {
    const element = document.getElementById(elementId);
    if (elementId === "detailsTypeMainBox") {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else if (elementId === "orderDetailsBox") {
      null;
    } else {
      // const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };
  const handleOrderStatusChange = (orderNumber, newStatus) => {
    // Make the API request to update the order status
    const formData = {
      orderNumber: orderNumber,
      OrderStatus: newStatus,
    };
    fetch(a39, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        fetchAllOrders();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const editMyInfoOld = async () => {
    let updatedUserDetails = [];
    try {
      const response = await fetch(a40, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails[0]),
      });
      const data = await response.json();
      // console.log(data);
      updatedUserDetails = [data.data];
      loggedIn(updatedUserDetails);
      scrollToCenter("detailsTypeMainBox");
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const checkClass = () => {
    let firstNameInput = document.getElementById("firstNameInput");
    let lastNameInput = document.getElementById("lastNameInput");
    let dobInput = document.getElementById("dobInput");
    let mobileInput = document.getElementById("mobileInput");

    if (firstNameChanged && !firstNameInput.classList.contains("validBorder")) {
      firstNameInput.classList.add("invalidBorder");
      setFirstNameChanged(true);
      scrollToCenter("firstNameInput");
    } else if (
      lastNameChanged &&
      !lastNameInput.classList.contains("validBorder")
    ) {
      lastNameInput.classList.add("invalidBorder");
      setLastNameChanged(true);
      scrollToCenter("lastNameInput");
    } else if (
      (dayChanged || monthChanged || yearChanged) &&
      csData.dob.length == 0 &&
      !dobInput.classList.contains("validBorder")
    ) {
      dobInput.classList.add("invalidBorder");
      setDayChanged(true);
      scrollToCenter("dobInput");
    } else if (
      mobileChanged &&
      !mobileInput.classList.contains("validBorder")
    ) {
      mobileInput.classList.add("invalidBorder");
      setMobileChanged(true);
      scrollToCenter("mobileInput");
    } else if (
      !firstNameInput.classList.contains("invalidBorder") &&
      !lastNameInput.classList.contains("invalidBorder") &&
      !dobInput.classList.contains("invalidBorder") &&
      !mobileInput.classList.contains("invalidBorder")
    ) {
      // console.log("all Correct");
      // // addMyInfoNew();
      // // setAllUserDetails(true);
      setMyInfoBox(!myInfoBox);
      editMyInfoOld();
    }
  };
  const checkBillingClass = () => {
    let perAddInput = document.getElementById("perAddInput");
    let townInput = document.getElementById("townInput");
    let pinCodeInput = document.getElementById("pinCodeInput");
    let stateInput = document.getElementById("stateInput");

    if (perAddChanged && !perAddInput.classList.contains("validBorder")) {
      perAddInput.classList.add("invalidBorder");
      setPerAddChanged(true);
      scrollToCenter("townInput");
    } else if (townChanged && !townInput.classList.contains("validBorder")) {
      townInput.classList.add("invalidBorder");
      setTownChanged(true);
      scrollToCenter("townInput");
    } else if (
      pinCodeChanged &&
      !pinCodeInput.classList.contains("validBorder")
    ) {
      pinCodeInput.classList.add("invalidBorder");
      setPinCodeChanged(true);
      scrollToCenter("pinCodeInput");
    } else if (stateChanged && !stateInput.classList.contains("validBorder")) {
      stateInput.classList.add("invalidBorder");
      setStateChanged(true);
      scrollToCenter("stateInput");
    } else if (
      !perAddInput.classList.contains("invalidBorder") &&
      !townInput.classList.contains("invalidBorder") &&
      !pinCodeInput.classList.contains("invalidBorder") &&
      !stateInput.classList.contains("invalidBorder")
    ) {
      // console.log("all Billing Correct");
      // // addMyInfoNew();
      // // setAllUserDetails(true);
      setMyBillingBox(!myBillingBox);
      editMyInfoOld();
    }
  };
  const checkDeliveryClass = () => {
    let currAddInput = document.getElementById("currAddInput");
    let currTownInput = document.getElementById("currTownInput");
    let currPinCodeInput = document.getElementById("currPinCodeInput");
    let currStateInput = document.getElementById("currStateInput");

    if (currAddChanged && !currAddInput.classList.contains("validBorder")) {
      currAddInput.classList.add("invalidBorder");
      setCurrAddChanged(true);
      scrollToCenter("currTownInput");
    } else if (
      currTownChanged &&
      !currTownInput.classList.contains("validBorder")
    ) {
      currTownInput.classList.add("invalidBorder");
      setCurrTownChanged(true);
      scrollToCenter("currTownInput");
    } else if (
      currPinCodeChanged &&
      !currPinCodeInput.classList.contains("validBorder")
    ) {
      currPinCodeInput.classList.add("invalidBorder");
      setCurrPinCodeChanged(true);
      scrollToCenter("currPinCodeInput");
    } else if (
      currStateChanged &&
      !currStateInput.classList.contains("validBorder")
    ) {
      currStateInput.classList.add("invalidBorder");
      setCurrStateChanged(true);
      scrollToCenter("currStateInput");
    } else if (
      !currAddInput.classList.contains("invalidBorder") &&
      !currTownInput.classList.contains("invalidBorder") &&
      !currPinCodeInput.classList.contains("invalidBorder") &&
      !currStateInput.classList.contains("invalidBorder")
    ) {
      // console.log("all Delivery Correct");
      // // addMyInfoNew();
      // // setAllUserDetails(true);
      setMyDeliveryBox(!myDeliveryBox);
      editMyInfoOld();
    }
  };
  const fetchAllOrders = () => {
    fetch(a38)
      .then((res) => res.json())
      .then((data) => {
        setAllOrder(data.data);
        setMyOrder(
          data.data.filter(
            (x) =>
              x.customer_Id === userDetails[0].id || x.customer_Id === csData.id
          )
        );
        setLoading(false);
      });
  };
  // fetch all orders
  useEffect(() => {
    fetch(a38)
      .then((res) => res.json())
      .then((data) => {
        setAllOrder(data.data);
        setMyOrder(
          data.data.filter(
            (x) =>
              x.customer_Id === userDetails[0].id || x.customer_Id === csData.id
          )
        );
        setLoading(false);
      });
  }, []);
  // console.log(allOrder);
  // console.log(myOrder);
  // console.log(csData);
  const updatedetailsBox = async (parameter2) => {
    // const formData = new FormData();
    // formData.append("Customer_id", csData.Customer_id);
    // formData.append("FieldName", parameter);
    // formData.append("FieldValue", formValue);
    const formData = {
      Id: userDetails[0].id,
      [parameter2]: formValue,
    };
    try {
      const response = await fetch(a5, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // console.log(data);
      if (data.status == "success") {
        setPopup(false);
        // alert(`${parameter} Changed Successfully`);
        // orderUpdated(csData.Customer_id, "Account Details");
        orderUpdated(userDetails[0].id, "Account Details");
        // setDetailsType("Account Details");
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(csData);
  const accountSettings = () => {
    return (
      <div className="detailsBox">
        <h1 style={{ color: "#1a4c2d", textAlign: "center" }}>{detailsType}</h1>
        <div className="myAccountSettingsOuterbox">
          <div className="myAccountSettingsMyDetailsMainBox">
            <div className="myAccountSettingsHeadingBox">
              <h4 style={{ color: "#1a4c2d" }}>My details</h4>
              <AiOutlineEdit
                className="customerprofileMyAccountEditIcon"
                style={{ cursor: "pointer" }}
                size={"20px"}
                onClick={() => {
                  setMyInfoBox(!myInfoBox);
                  setMyBillingBox(false);
                  setMyDeliveryBox(false);
                  scrollToCenter("myAccountSettingInfoBox");
                }}
              />
            </div>
            {myInfoBox ? (
              <div
                id="myAccountSettingInfoBox"
                className="myAccountSettingsMyDetailsEditBox"
              >
                <label>
                  First name <sup style={{ color: "red" }}>*</sup>
                </label>
                <input
                  id="firstNameInput"
                  className={`checkOutInput ${
                    firstNameChanged && firstName === "" ? "invalid" : ""
                  } ${firstName !== "" ? "validBorder" : ""}`}
                  type="text"
                  value={csData.firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    handleInputChange(e, "firstName");
                    setFirstNameChanged(true);
                  }}
                />
                {firstName === "" && firstNameChanged && (
                  <p
                    style={{ margin: "5px 0px", padding: "0" }}
                    className="invalid"
                  >
                    Please enter a First name
                  </p>
                )}
                <label>
                  Last name <sup style={{ color: "red" }}>*</sup>
                </label>
                <input
                  id="lastNameInput"
                  className={`checkOutInput ${
                    lastName !== "" ? "validBorder" : ""
                  } ${lastNameChanged && lastName === "" ? "invalid" : ""}`}
                  type="text"
                  // value={lastName}
                  value={userDetails[0].lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    handleInputChange(e, "lastName");
                    setLastNameChanged(true);
                  }}
                />
                {lastName === "" && lastNameChanged && (
                  <p style={{ margin: "5px 0px" }} className="invalid">
                    Please enter a Last name
                  </p>
                )}
                <label>Date of Birth</label>
                <div
                  id="dobInput"
                  className={`dobInputContainer ${
                    !(dayChanged && monthChanged && yearChanged)
                      ? day === "" && month === "" && year === ""
                        ? ""
                        : "invalidBorder"
                      : isDateOfBirthIncomplete
                      ? "invalidBorder"
                      : "validBorder"
                  }`}
                >
                  <input
                    style={{ marginBottom: "0" }}
                    type="text"
                    maxLength={2}
                    value={day}
                    onChange={handleDayChange}
                    placeholder="DD"
                  />
                  <span>/</span>
                  <input
                    style={{ marginBottom: "0" }}
                    type="text"
                    maxLength={2}
                    value={month}
                    onChange={handleMonthChange}
                    placeholder="MM"
                  />
                  <span>/</span>
                  <input
                    style={{ marginBottom: "0" }}
                    type="text"
                    maxLength={4}
                    value={year}
                    onChange={handleYearChange}
                    placeholder="YYYY"
                  />
                </div>
                {(!(dayChanged && monthChanged && yearChanged) ||
                  isDateOfBirthIncomplete) &&
                  (day !== "" || month !== "" || year !== "") && (
                    <p style={{ margin: "5px 0px" }} className="invalid">
                      Please enter a valid date of birth
                    </p>
                  )}
                {day && month && year ? (
                  <>
                    <p
                      style={{
                        margin: "5px 0px",
                        marginBottom: "20px",
                      }}
                    >
                      Date of Birth: {day}/{month}/{year}
                    </p>
                  </>
                ) : (
                  <>
                    <p
                      style={{
                        fontWeight: "bold",
                        margin: "5px 0px",
                        marginBottom: "20px",
                        color: "green",
                      }}
                    >
                      Date of Birth: {csData.dob}
                    </p>
                  </>
                )}
                <label>Phone number</label>
                <input
                  id="mobileInput"
                  className={`checkOutInput ${
                    mobile && mobile.length >= 9 ? "validBorder" : ""
                  } ${
                    mobileChanged && mobile.length < 8 ? "invalidBorder" : ""
                  }`}
                  maxLength={12}
                  value={csData.mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                    handleInputChange(e, "mobile");
                    setMobileChanged(true);
                  }}
                  type="number"
                />
                {mobile === "" && mobile.length < 8 && mobileChanged ? (
                  <p style={{ margin: "5px 0px" }} className="invalid">
                    Please enter valid number
                  </p>
                ) : null}
                <label>Gender</label>
                <select>
                  <option>Male</option>
                  <option>Female</option>
                </select>
                <div className="checkoutNewButtonBox">
                  <button onClick={() => checkClass()}>Save</button>
                </div>
                <p
                  onClick={() => {
                    orderUpdated(csData.id, "Account Setting"),
                      setMyInfoBox(!myInfoBox);
                  }}
                  style={{
                    textAlign: "center",
                    margin: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <RxCross2 style={{ marginRight: "10px" }} size={"20px"} />
                  Cancel
                </p>
              </div>
            ) : (
              <div
                // id="myAccountSettingInfoBox"
                className="myAccountSettingsMyDetailsBox"
              >
                <h6>Email</h6>
                <p>{csData.email}</p>
                <h6>First name</h6>
                <p>{csData.firstName}</p>
                <h6>Last name</h6>
                <p>{csData.lastName}</p>
                <h6>Date of birth</h6>
                <p>{csData.dob}</p>
                <h6>Phone number</h6>
                <p>{csData.mobile}</p>
                <h6>Gender</h6>
                <p>{csData.gender}</p>
              </div>
            )}
          </div>
          <div className="myAccountSettingsMyDetailsMainBox">
            <div className="myAccountSettingsHeadingBox">
              <h4 style={{ color: "#1a4c2d" }}>Billing Address</h4>
              <AiOutlineEdit
                className="customerprofileMyAccountEditIcon"
                style={{ cursor: "pointer" }}
                size={"20px"}
                onClick={() => {
                  setMyInfoBox(false);
                  setMyBillingBox(!myBillingBox);
                  setMyDeliveryBox(false);
                  scrollToCenter("myAccountSettingBillingBox");
                }}
              />
            </div>
            {myBillingBox ? (
              <div
                id="myAccountSettingBillingBox"
                className="myAccountSettingsMyDetailsEditBox"
              >
                <label style={{ fontWeight: "bold", margin: "20px 0" }}>
                  Enter your billing address
                </label>
                <label>
                  Address <sup style={{ color: "red" }}>*</sup>
                </label>
                <div className="checkOutInputBox">
                  <input
                    id="perAddInput"
                    className={`checkOutInput ${
                      perAddChanged && perAdd === "" ? "invalid" : ""
                    } ${perAdd !== "" ? "validBorder" : ""}`}
                    type="text"
                    value={userDetails[0].perAddStreet}
                    placeholder={userDetails[0].perAddStreet}
                    onChange={(e) => {
                      setPerAdd(e.target.value);
                      handleInputChange(e, "perAddStreet");
                      setPerAddChanged(true);
                    }}
                  />
                  {perAdd !== "" && (
                    <RxCheck
                      style={{ top: "35%" }}
                      className="checkMark"
                      size="20px"
                    />
                  )}
                  {perAdd === "" && perAddChanged && (
                    <p style={{ margin: "5px 0px" }} className="invalid">
                      Please enter an Address
                    </p>
                  )}
                  {!perAddChanged || perAdd !== "" ? (
                    <p style={{ margin: "5px 0px" }}>Street Address</p>
                  ) : null}
                </div>
                <div className="checkOutNameMainBox">
                  <label>
                    Town/City <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <label>
                    Pincode <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <div className="checkOutInputBox">
                    <input
                      id="townInput"
                      className={`checkOutInput ${
                        townChanged && town === "" ? "invalid" : ""
                      } ${town !== "" ? "validBorder" : ""}`}
                      type="text"
                      value={userDetails[0].perAddTown}
                      placeholder={userDetails[0].perAddTown}
                      onChange={(e) => {
                        setTown(e.target.value);
                        handleInputChange(e, "perAddTown");
                        setTownChanged(true);
                      }}
                    />
                    {town !== "" && (
                      <RxCheck
                        style={{ top: "35%" }}
                        className="checkMark"
                        size="20px"
                      />
                    )}
                    {town === "" && townChanged && (
                      <p style={{ margin: "5px 0px" }} className="invalid">
                        Enter a town/city
                      </p>
                    )}
                    {!townChanged || town !== "" ? (
                      <p style={{ margin: "5px 0px" }}>City/Town</p>
                    ) : null}
                  </div>
                  <div className="checkOutInputBox">
                    <input
                      id="pinCodeInput"
                      className={`checkOutInput ${
                        pinCode !== "" ? "validBorder" : ""
                      } ${pinCodeChanged && pinCode === "" ? "invalid" : ""}`}
                      type="number"
                      placeholder={userDetails[0].perAddPinCode}
                      value={userDetails[0].perAddPinCode}
                      onChange={(e) => {
                        setPinCode(e.target.value);
                        handleInputChange(e, "perAddPinCode");
                        setPinCodeChanged(true);
                      }}
                    />
                    {pinCode !== "" && (
                      <RxCheck
                        style={{ top: "35%" }}
                        className="checkMark"
                        size="20px"
                      />
                    )}
                    {pinCode === "" && pinCodeChanged && (
                      <p style={{ margin: "5px 0px" }} className="invalid">
                        Please enter a pincode (400001)
                      </p>
                    )}
                    {!pinCodeChanged || pinCode !== "" ? (
                      <p style={{ margin: "5px 0px" }}>
                        Enter your postcode. E.g. 400070
                      </p>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "20px" }} className="checkOutInputBox">
                  <label>
                    State <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    id="stateInput"
                    className={`checkOutInput ${
                      stateChanged && state === "" ? "invalid" : ""
                    } ${state !== "" ? "validBorder" : ""}`}
                    placeholder={userDetails[0].perAddState}
                    value={userDetails[0].perAddState}
                    onChange={(e) => {
                      setState(e.target.value);
                      handleInputChange(e, "perAddState");
                      setStateChanged(true);
                    }}
                  >
                    <option value="">Select a state</option>
                    {/* {allStateList.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))} */}
                    <option key={state} value="Maharashtra">
                      Maharashtra
                    </option>
                  </select>
                  {state !== "" && (
                    <RxCheck
                      style={{ top: "65%", right: "20px" }}
                      className="checkMark"
                      size="20px"
                    />
                  )}
                  {state === "" && stateChanged && (
                    <p style={{ margin: "5px 0px" }} className="invalid">
                      Please select your State
                    </p>
                  )}
                </div>
                <div className="checkoutNewButtonBox">
                  <button onClick={() => checkBillingClass()}>Save</button>
                </div>
                <p
                  onClick={() => {
                    setMyBillingBox(!myBillingBox),
                      orderUpdated(csData.id, "Account Setting");
                  }}
                  style={{
                    textAlign: "center",
                    margin: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <RxCross2 style={{ marginRight: "10px" }} size={"20px"} />
                  Cancel
                </p>
              </div>
            ) : (
              <div
                // id="myAccountSettingBillingBox"
                className="myAccountSettingsMyDetailsBox"
              >
                <p style={{ margin: "5px 0" }}>{userDetails[0].perAddStreet}</p>
                <p style={{ margin: "5px 0" }}>{userDetails[0].perAddTown}</p>
                <p style={{ margin: "5px 0" }}>{userDetails[0].perAddState}</p>
                <p style={{ margin: "5px 0" }}>
                  {userDetails[0].perAddPinCode}
                </p>
              </div>
            )}
          </div>
          <div className="myAccountSettingsMyDetailsMainBox">
            <div className="myAccountSettingsHeadingBox">
              <h4 style={{ color: "#1a4c2d" }}>Delivery Address</h4>
              <AiOutlineEdit
                className="customerprofileMyAccountEditIcon"
                style={{ cursor: "pointer" }}
                size={"20px"}
                onClick={() => {
                  setMyInfoBox(false);
                  setMyBillingBox(false);
                  setMyDeliveryBox(!myDeliveryBox);
                  scrollToCenter("myAccountSettingDeliveryBox");
                }}
              />
            </div>
            {myDeliveryBox ? (
              <div
                id="myAccountSettingDeliveryBox"
                className="myAccountSettingsMyDetailsEditBox"
              >
                <label style={{ fontWeight: "bold", margin: "20px 0" }}>
                  Enter your delivery address
                </label>
                <label>
                  Address <sup style={{ color: "red" }}>*</sup>
                </label>
                <div className="checkOutInputBox">
                  <input
                    id="currAddInput"
                    className={`checkOutInput ${
                      currAddChanged && currAdd === "" ? "invalid" : ""
                    } ${currAdd !== "" ? "validBorder" : ""}`}
                    type="text"
                    value={userDetails[0].currAddStreet}
                    placeholder={userDetails[0].currAddStreet}
                    onChange={(e) => {
                      setCurrAdd(e.target.value);
                      handleInputChange(e, "currAddStreet");
                      setCurrAddChanged(true);
                    }}
                  />
                  {currAdd !== "" && (
                    <RxCheck
                      style={{ top: "35%" }}
                      className="checkMark"
                      size="20px"
                    />
                  )}
                  {currAdd === "" && currAddChanged && (
                    <p style={{ margin: "5px 0px" }} className="invalid">
                      Please enter an Address
                    </p>
                  )}
                  {!currAddChanged || currAdd !== "" ? (
                    <p style={{ margin: "5px 0px" }}>Street Address</p>
                  ) : null}
                </div>
                <div className="checkOutNameMainBox">
                  <label>
                    Town/City <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <label>
                    Pincode <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <div className="checkOutInputBox">
                    <input
                      id="currTownInput"
                      className={`checkOutInput ${
                        currTownChanged && currTown === "" ? "invalid" : ""
                      } ${currTown !== "" ? "validBorder" : ""}`}
                      type="text"
                      value={userDetails[0].currAddTown}
                      placeholder={userDetails[0].currAddTown}
                      onChange={(e) => {
                        setCurrTown(e.target.value);
                        handleInputChange(e, "currAddTown");
                        setCurrTownChanged(true);
                      }}
                    />
                    {currTown !== "" && (
                      <RxCheck
                        style={{ top: "35%" }}
                        className="checkMark"
                        size="20px"
                      />
                    )}
                    {currTown === "" && currTownChanged && (
                      <p style={{ margin: "5px 0px" }} className="invalid">
                        Enter a town/city
                      </p>
                    )}
                    {!currTownChanged || currTown !== "" ? (
                      <p style={{ margin: "5px 0px" }}>City/Town</p>
                    ) : null}
                  </div>
                  <div className="checkOutInputBox">
                    <input
                      id="currPinCodeInput"
                      className={`checkOutInput ${
                        currPinCode !== "" ? "validBorder" : ""
                      } ${
                        currPinCodeChanged && currPinCode === ""
                          ? "invalid"
                          : ""
                      }`}
                      type="number"
                      placeholder={userDetails[0].currAddPinCode}
                      value={userDetails[0].currAddPinCode}
                      onChange={(e) => {
                        setCurrPinCode(e.target.value);
                        handleInputChange(e, "currAddPinCode");
                        setCurrPinCodeChanged(true);
                      }}
                    />
                    {currPinCode !== "" && (
                      <RxCheck
                        style={{ top: "35%" }}
                        className="checkMark"
                        size="20px"
                      />
                    )}
                    {currPinCode === "" && currPinCodeChanged && (
                      <p style={{ margin: "5px 0px" }} className="invalid">
                        Please enter a pincode (400001)
                      </p>
                    )}
                    {!currPinCodeChanged || currPinCode !== "" ? (
                      <p style={{ margin: "5px 0px" }}>
                        Enter your postcode. E.g. 400070
                      </p>
                    ) : null}
                  </div>
                </div>
                <div style={{ marginTop: "20px" }} className="checkOutInputBox">
                  <label>
                    State <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    id="currStateInput"
                    className={`checkOutInput ${
                      currStateChanged && currState === "" ? "invalid" : ""
                    } ${currState !== "" ? "validBorder" : ""}`}
                    placeholder={userDetails[0].currAddState}
                    value={userDetails[0].currAddState}
                    onChange={(e) => {
                      setCurrState(e.target.value);
                      handleInputChange(e, "currAddState");
                      setCurrStateChanged(true);
                    }}
                  >
                    <option value="">Select a state</option>
                    {/* {allStateList.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))} */}
                    <option key={state} value="Maharashtra">
                      Maharashtra
                    </option>
                  </select>
                  {currState !== "" && (
                    <RxCheck
                      style={{ top: "65%", right: "20px" }}
                      className="checkMark"
                      size="20px"
                    />
                  )}
                  {currState === "" && currStateChanged && (
                    <p style={{ margin: "5px 0px" }} className="invalid">
                      Please select your State
                    </p>
                  )}
                </div>
                <div className="checkoutNewButtonBox">
                  <button onClick={() => checkDeliveryClass()}>Save</button>
                </div>
                <p
                  onClick={() => {
                    setMyDeliveryBox(!myDeliveryBox),
                      orderUpdated(csData.id, "Account Setting");
                  }}
                  style={{
                    textAlign: "center",
                    margin: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <RxCross2 style={{ marginRight: "10px" }} size={"20px"} />
                  Cancel
                </p>
              </div>
            ) : (
              <div
                // id="myAccountSettingDeliveryBox"
                className="myAccountSettingsMyDetailsBox"
              >
                <p style={{ margin: "5px 0" }}>
                  {userDetails[0].currAddStreet}
                </p>
                <p style={{ margin: "5px 0" }}>{userDetails[0].currAddTown}</p>
                <p style={{ margin: "5px 0" }}>{userDetails[0].currAddState}</p>
                <p style={{ margin: "5px 0" }}>
                  {userDetails[0].currAddPinCode}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  const orderUpdated = async (customerId, typeFor) => {
    scrollToCenter("detailsTypeMainBox");
    const formData = {
      Id: customerId,
    };
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);

    try {
      const response = await fetch(a3, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // loggedOut(userDetails);
      // setCustomerData(data.data[0]);
      // navigate(`/customerprofile?customerId=${customerId}`);
      loggedIn([data.data]);
      // console.log(data.data);
      setDetailsType(typeFor);
      // scrollToCenter("detailsTypeMainBox");
      setFirstNameChanged(false);
      setLastNameChanged(false);
      setMobileChanged(false);
      setDayChanged(false);
      setDay("");
      setMonth("");
      setYear("");
      setMonthChanged(false);
      setYearChanged(false);
      setPerAddChanged(false);
      setTownChanged(false);
      setPinCodeChanged(false);
      setStateChanged(false);
      setCurrAddChanged(false);
      setCurrTownChanged(false);
      setCurrPinCodeChanged(false);
      setCurrStateChanged(false);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const orders = () => {
    // console.log("Scrolling to element with ID:", "detailsTypeMainBox");
    scrollToCenter("orderDetailsBox");

    return (
      <div id="orderDetailsBox" className="detailsBox">
        <h1 style={{ color: "#1a4c2d", textAlign: "center" }}>{detailsType}</h1>
        {myOrder.length === 0 ? (
          <div
            style={{
              height: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="myOrdersOrderOuterBox"
          >
            <h4>No order Found !</h4>
            <p style={{ marginTop: "10px" }}>
              When you have bought or returned something in store you´ll find it
              here
            </p>
          </div>
        ) : (
          myOrder.map((x) => {
            const date = new Date(x.createdOn);
            const formattedDate = `${date
              .getDate()
              .toString()
              .padStart(2, "0")}-${(date.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${date.getFullYear().toString().slice(-2)}`;
            const lastUpdatedDate = new Date(x.lastUpdated);
            const differenceInDays = Math.ceil(
              (today - lastUpdatedDate) / (1000 * 60 * 60 * 24)
            );

            const deliverydate = `${lastUpdatedDate
              .getDate()
              .toString()
              .padStart(2, "0")}-${(lastUpdatedDate.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${lastUpdatedDate
              .getFullYear()
              .toString()
              .slice(-2)}`;

            return (
              <div className="myOrdersOrderOuterBox">
                <div className="myOrdersOrderDetailsBox">
                  <h5
                    style={
                      x.orderStatus === "Paid"
                        ? {
                            color: "rgba(0, 128, 0, 0.7)",
                          }
                        : x.orderStatus === "Processing Payment"
                        ? {
                            color: "rgb(219, 153, 30)",
                          }
                        : x.orderStatus === "Payment Failed"
                        ? {
                            color: "rgb(231, 30, 60)",
                          }
                        : x.orderStatus === "Shipped"
                        ? {
                            color: "rgb(77, 155, 228)",
                          }
                        : x.orderStatus === "Delivered"
                        ? {
                            color: "rgb(159, 77, 206)",
                          }
                        : {
                            color: "rgb(180, 180, 46)",
                          }
                    }
                    key={x.id}
                  >
                    <strong>{x.orderStatus}</strong>
                  </h5>
                  <p>{formattedDate}</p>
                  <li>Order No</li>
                  <p>{x.orderNumber}</p>
                  <li>Total</li>
                  <p>₹{x.price}</p>
                  {x.tblProduct && Array.isArray(x.tblProduct) && (
                    <div className="myOrdersOrderProductBox">
                      {/* <h5 style={{ marginTop: "20px" }}>Products:</h5> */}
                      {x.tblProduct.map((product, index) => {
                        {
                          allImages = x.images.split(",");
                        }
                        return (
                          <div className="myOrdersOrderProductMainBox">
                            <div
                              className="myOrdersOrderProductBox"
                              key={index}
                            >
                              <h6>{product.product_Name}</h6>
                              {/* <p>Category: {product.categoryName}</p> */}
                              {/* <p>MRP: ₹{product.mrp}</p> */}
                              <img
                                className="myOrdersOrderProductBox"
                                // src={`${s1}${x.imageList1}`}
                                src={`${s3}/${allImages[0]}`}
                                key={x.id}
                                alt="Products Images"
                              />
                              {/* Display other product details */}
                            </div>
                            {/* {differenceInDays <= 15 && <button>Show Button</button>} */}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {x.tblProduct && typeof x.tblProduct === "object" && (
                    <div className="myOrdersOrderProductMainBox">
                      {/* <h5 style={{ marginTop: "20px" }}>Products:</h5> */}
                      <div className="myOrdersOrderProductBox">
                        <h6>
                          {x.tblProduct.product_Name} x {x.tblProduct.quantity}
                        </h6>
                        {x.tblProduct.images && (
                          <img
                            className="myOrdersOrderProductImage"
                            src={`${s3}/${x.tblProduct.images.split(",")[0]}`} // Displaying the first image
                            alt="Product Images"
                          />
                        )}

                        {/* Display other product details */}
                      </div>
                    </div>
                  )}
                </div>
                {x.orderStatus === "Delivered" &&
                  differenceInDays < 15 &&
                  !x.tblProduct.product_Name.toLowerCase().includes("coin") && (
                    <button
                      className="myOrdersOrderReturnButton"
                      onClick={() => {
                        handleOrderStatusChange(
                          x.orderNumber,
                          "Return Requested"
                        );
                      }}
                    >
                      Return
                    </button>
                  )}
              </div>
            );
          })
        )}
        <div className="createdOnBox">
          <p style={{ marginBottom: "0.5rem" }}>
            Last Updated On : {csData.lastUpdated}
          </p>
          <p style={{ marginBottom: "0.5rem" }}>
            Created On : {csData.createdOn}
          </p>
        </div>
      </div>
    );
  };
  const contactUs = () => {
    navigate("/contactdetails");
  };
  const resetPassword = () => {
    navigate("/changePassword");
  };
  useEffect(() => {
    accountSettings();
  }, [popUp]);
  return (
    <div>
      {/* <h1 style={{ marginTop: "20px", color: "#1a4c2d" }}>{detailsType}</h1> */}
      <div style={{ justifyContent: "center" }} className="profileBox2">
        <div id="detailsTypeMainBox" className="detailsTypeBox">
          <h4
            id={detailsType == "Orders" ? "greenColouredBorder" : null}
            onClick={() => {
              orderUpdated(csData.id, "Orders");
            }}
          >
            <BsBoxSeam size={"1.1rem"} style={{ marginRight: "10px" }} />
            My orders
          </h4>
          <h4
            id={
              detailsType == "Account settings" ? "greenColouredBorder" : null
            }
            onClick={() => setDetailsType("Account settings")}
          >
            <AiOutlineSetting size={"1.2rem"} style={{ marginRight: "10px" }} />
            Account settings
          </h4>
          <h4
            id={detailsType == "Reset Password" ? "greenColouredBorder" : null}
            onClick={() => setDetailsType("Reset Password")}
          >
            <BiUserCircle size={"1.2rem"} style={{ marginRight: "10px" }} />
            Reset Password
          </h4>
          <h4
            id={detailsType == "Contact Us" ? "greenColouredBorder" : null}
            onClick={() => setDetailsType("Contact Us")}
          >
            <BiMessageDetail size={"1.2rem"} style={{ marginRight: "10px" }} />
            Contact Us
          </h4>
          <h4
            id={detailsType == "Logout" ? "greenColouredBorder" : null}
            // onClick={() => {
            //   setDetailsType("Logout"), setLogOut(true);
            // }}
            onClick={() => {
              navigate("/"), loggedIn([]);
            }}
          >
            <BiLogOut size={"1.2rem"} style={{ marginRight: "10px" }} />
            Sign out
          </h4>
        </div>
        <div
          className={
            popUp == true ? "updateAccountDetailsPopupMainBox" : "none"
          }
        >
          <div className="updateAccountDetailsPopupCloseBtn">
            <AiOutlineClose size={"3rem"} onClick={() => setPopup(false)} />
          </div>
          <h1 style={{ color: "rgba(0,0,0,0.5)" }}>Change {parameter} </h1>
          <input type="text" onChange={(e) => setFormValue(e.target.value)} />
          <button onClick={() => updatedetailsBox(parameter)}>Submit</button>
        </div>
        <div
          className={
            logOut == true ? "updateAccountDetailsPopupMainBox" : "none"
          }
        >
          <div className="updateAccountDetailsPopupCloseBtn">
            <AiOutlineClose size={"3rem"} onClick={() => setLogOut(false)} />
          </div>
          <h1 style={{ color: "rgba(0,0,0,0.5)" }}> Are You Sure ?</h1>
          <h1> Log out </h1>
          <button
            onClick={() => {
              navigate("/"), loggedIn([]);
            }}
          >
            Submit
          </button>
        </div>
        {detailsType == "Account settings"
          ? accountSettings()
          : detailsType == "Orders"
          ? orders()
          : detailsType == "Contact Us"
          ? contactUs()
          : detailsType == "Reset Password"
          ? resetPassword()
          : detailsType == "Logout"
          ? logOut()
          : accountSettings()}
      </div>
      <div className={popUp || logOut === true ? "new" : "new2"}></div>
    </div>
  );
}
