import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { ColorRing } from "react-loader-spinner";
import { a16, s3 } from "../../Api/RootApiPath";
import { MdSort } from "react-icons/md";
import { FiFilter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import goldCoinBanner from "../../Images/CategoryPageBanners/specialOccasionGoldCoinBanner.jpg";
import goldCoinBannerMobile from "../../Images/CategoryPageBanners/specialOccasionGoldCoinBannerMobile.jpg";
import limitedEditionBanner from "../../Images/CategoryPageBanners/limitedEditionBanner.jpg";
import limitedEditionBannerMobile from "../../Images/CategoryPageBanners/limitedEditionBannerMobile.jpg";
import limitedEditionFont from "../../Images/CategoryPageBanners/limitedEditionFont.png";
import "../PagesStyles/ShopPage.css";

import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

export default function LimitedEditionPage() {
  const { edition } = useParams();
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllProductsData();
  }, [edition]);

  const fetchAllProductsData = async () => {
    try {
      const data = await fetch(a16);
      const response = await data.json();
      console.log(response.data, "response");
      if (response.status === "No Data available") {
        setAllProducts("NoData");
        setLoading(false);
      } else {
        // Separate different types of coins
        const filterProducts = response.data.filter(
          (product) =>
            product.edition &&
            product.edition.toLowerCase().includes(edition.toLowerCase())
        );
        console.log(filterProducts, "filterProducts");

        setAllProducts(filterProducts);
        setTotalProducts(filterProducts);
        setLoading(false);
        filterInitialProducts(filterProducts);
      }
    } catch (error) {
      console.log("Error fetching products data: ", error);
    }
  };

  const filterInitialProducts = (data) => {
    const filteredProducts = data.filter(
      (product) =>
        product.gender.toLowerCase() == "female" ||
        product.gender.toLowerCase() == "unisex"
    );
    console.log(filteredProducts, "Women's Products");
    // Update state with filtered products
    setAllProducts(filteredProducts);
  };
  const womensFilteredProduct = () => {
    // Filter products for women
    const filteredProducts = totalProducts.filter(
      (product) =>
        product.gender.toLowerCase() == "female" ||
        product.gender.toLowerCase() == "unisex"
    );
    console.log(filteredProducts, "Women's Products");
    document
      .getElementById("limitedButtonMen")
      .classList.remove("limitedButtonActive");
    document
      .getElementById("limitedButtonWomen")
      .classList.add("limitedButtonActive");

    // Update state with filtered products
    setAllProducts(filteredProducts);
  };

  const mensFilteredProduct = () => {
    // Filter products for men
    const filteredProducts = totalProducts.filter(
      (product) =>
        product.gender.toLowerCase() == "male" ||
        product.gender.toLowerCase() == "unisex"
    );
    console.log(filteredProducts, "Men's Products");
    document
      .getElementById("limitedButtonWomen")
      .classList.remove("limitedButtonActive");
    document
      .getElementById("limitedButtonMen")
      .classList.add("limitedButtonActive");

    // Update state with filtered products
    setAllProducts(filteredProducts);
  };

  const filteredProductsData = allProducts;
  console.log(allProducts, "allProducts");

  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    offerPrice,
    purityRate,
    id,
    phuid
  ) => {
    // Parse input values to float and handle NaN cases
    netWt = parseFloat(netWt) || 0;
    making_per_gram = parseFloat(making_per_gram) || 0;
    making_Percentage = parseFloat(making_Percentage) || 0;
    making_Fixed_Amt = parseFloat(making_Fixed_Amt) || 0;
    making_Fixed_Wastage = parseFloat(making_Fixed_Wastage) || 0;
    stoneAmount = parseFloat(stoneAmount) || 0;
    mrp = parseFloat(mrp) || 0;
    offerPrice = parseFloat(offerPrice) || 0;
    purityRate = parseFloat(purityRate) || 0;

    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;

    let grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;

    let finalPrice = 0;

    // if (offerPrice !== 0) {
    //   finalPrice = offerPrice;
    // } else
    if (mrp !== 0) {
      finalPrice = mrp;
    }
    if (
      // offerPrice === 0 &&
      mrp === 0
    ) {
      finalPrice = grossTotalRate; // Set final price to grossTotalRate
    }

    // let GSTAdded = GST * grossTotalRate;
    // finalPrice += GSTAdded;

    return finalPrice.toFixed(0);
  };

  const updatedData0 = filteredProductsData.map((product) => ({
    ...product,
    purityRate: parseFloat(product.tblPurity.todaysRate),
  }));
  const updatedData = updatedData0.map((product) => ({
    ...product,
    sellPrice: calculateFinalPrice(
      parseFloat(product.netWt),
      parseFloat(product.making_per_gram),
      parseFloat(product.making_Percentage),
      parseFloat(product.making_Fixed_Amt),
      parseFloat(product.making_Fixed_Wastage),
      parseFloat(product.stoneAmount),
      parseFloat(product.mrp),
      parseFloat(product.offerPrice),
      parseFloat(product.purityRate),
      product.id,
      product.phuid
    ),
  }));

  // const allProductsAvailable = () => {
  //   return filteredProducts.map((product) => (
  //     <div
  //       key={product.id}
  //       style={{
  //         borderRadius: "10px",
  //         height: "auto",
  //         backgroundColor: "white",
  //       }}
  //       className="container"
  //       onClick={() => {
  //         navigate(`/productpage/${product.id}`);
  //       }}
  //     >
  //       <div className="homePageSpecialOccasionContainerImageBox">
  //         <img
  //           style={{
  //             borderTopLeftRadius: "10px",
  //             borderTopRightRadius: "10px",
  //           }}
  //           className="productsImages"
  //           src={`${s3}/${product.images.split(",")[0]}`}
  //           alt="Products Images"
  //         />
  //       </div>
  //       <div className="shoppageProductDescriptionBox">
  //         <div
  //           className="shoppageProductDescriptionBoxTitle"
  //           // style={{ height: "70px" }}
  //         >
  //           <h3>{product.product_Name}</h3>
  //         </div>
  //         {/* <div className="shoppageProductDescriptionBoxDetails">
  //           <p
  //             style={{ color: "rgba(0,0,0,0.4)" }}
  //           >{`${product.description.substr(0, 75)} ...`}</p>
  //         </div> */}
  //         <div className="shoppageProductDescriptionBoxDetails">
  //           {/* <div className="specialOccasionPageProductLabelOuterBox">
  //             <div className="specialOccasionPageProductLabelInnerBox">
  //               <p>{product.netWt} Gram</p>
  //             </div>
  //           </div> */}
  //           {/* <strong>
  //             <h3 style={{ color: "green", fontWeight: "bold" }}>
  //               ₹{product.sellPrice}
  //             </h3>
  //           </strong> */}
  //           {product.offerPrice &&
  //           product.offerPrice !== 0 &&
  //           product.offerPrice !== "0" ? (
  //             <div style={{ display: "flex" }}>
  //               <strong>
  //                 <h3
  //                   style={{
  //                     color: "red",
  //                     textDecorationLine: "line-through",
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   ₹{product.sellPrice}
  //                 </h3>
  //               </strong>

  //               <strong>
  //                 <h3 style={{ color: "green", fontWeight: "bold" }}>
  //                   ₹{product.offerPrice}
  //                 </h3>
  //               </strong>
  //             </div>
  //           ) : (
  //             <>
  //               <strong>
  //                 <h3 style={{ color: "green", fontWeight: "bold" }}>
  //                   ₹{product.sellPrice}
  //                 </h3>
  //               </strong>
  //             </>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   ));
  // };

  const allProductsAvailable = () => {
    return filteredProducts.map((product) => (
      <div
        key={product.id}
        className="container"
        onClick={() => {
          navigate(`/productpage/${product.id}`);
        }}
      >
        <img
          className="productsImages"
          src={`${s3}/${product.images.split(",")[0]}`}
          alt="Products Images"
        />
        <div className="shoppageProductDescriptionBox">
          <div className="shoppageProductDescriptionBoxTitle">
            <h3>{product.product_Name}</h3>
          </div>
          {/* <div className="shoppageProductDescriptionBoxDetails">
            <p>{`${product.description.substr(0, 75)} ...`}</p>
          </div> */}
          <div className="shoppageProductDescriptionBoxDetails">
            {/* <strong>
              <h3 style={{ color: "green", fontWeight: "bold" }}>
                ₹{product.sellPrice}
              </h3>
            </strong> */}
            {product.offerPrice &&
            product.offerPrice !== 0 &&
            product.offerPrice !== "0" ? (
              <div style={{ display: "flex" }}>
                <strong>
                  <h3
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                      fontWeight: "bold",
                    }}
                  >
                    ₹{product.sellPrice}
                  </h3>
                </strong>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.offerPrice}
                  </h3>
                </strong>
              </div>
            ) : (
              <strong>
                <h3
                  style={{
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  ₹{product.sellPrice}
                </h3>
              </strong>
            )}
          </div>
        </div>
      </div>
    ));
  };

  useEffect(() => {
    const updatedData0 = filteredProductsData.map((product) => ({
      ...product,
      purityRate: parseFloat(product.tblPurity.todaysRate),
    }));

    const updatedData = updatedData0.map((product) => ({
      ...product,
      sellPrice: calculateFinalPrice(
        parseFloat(product.netWt),
        parseFloat(product.making_per_gram),
        parseFloat(product.making_Percentage),
        parseFloat(product.making_Fixed_Amt),
        parseFloat(product.making_Fixed_Wastage),
        parseFloat(product.stoneAmount),
        parseFloat(product.mrp),
        parseFloat(product.offerPrice),
        parseFloat(product.purityRate),
        product.id,
        product.phuid
      ),
    }));
    let tempFilteredProducts = updatedData;

    setFilteredProducts(tempFilteredProducts);
    // window.scrollTo(0, 0);
  }, [allProducts]);

  return (
    <div style={{ backgroundColor: "#f5fffa" }}>
      <div className="headingBox">
        <Heading />
      </div>
      {loading ? (
        // <div style={{ height: "150px", margin: "100px 0" }}>
        //   <Hearts width={200} color="#4fa94d" />
        // </div>
        <div
          style={{
            height: "150px",
            margin: "100px 0",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#fee9d4",
          }}
        >
          <ColorRing
            height="100"
            width="100"
            // color="#4fa94d"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      ) : (
        // DesktopFilters()
        // <h1>Hi</h1>
        <div>
          <div className="desktopAllProductsBannerBox">
            <img src={limitedEditionBanner} />
          </div>
          <div className="desktopAllProductsBannerBoxMobile">
            <img src={limitedEditionBannerMobile} />
          </div>
          <div className="limitedfont">
            <img
              id="limitedEditionFont"
              src={limitedEditionFont}
              // style={{ width: "400px", height: "auto", marginRight: "20px" }}
            />
          </div>
          <div className="limitedButton-container">
            <button
              id="limitedButtonWomen"
              onClick={womensFilteredProduct}
              className="limitedButton limitedButtonActive"
            >
              Women
            </button>
            <button
              id="limitedButtonMen"
              onClick={mensFilteredProduct}
              className="limitedButton"
            >
              Men
            </button>
          </div>
          <div className="shopPage">{allProductsAvailable()}</div>
          {/* <h3 className="specialOccasionPageHeading">GOLD 999 GIFT COINS</h3>
          <div className="shopPage">{allProductsAvailableGiftCoins()}</div> */}
        </div>
      )}

      {/* {!loading || mobileFilterBox ? MobileFilters() : null}
      {!loading || mobileSortByBox ? MobileSortBy() : null}

      <div className="shoppageMobileFiltersMainBox">
        <div
          className="shoppageMobileFiltersLeftBox"
          onClick={() => handleOpenMobileFilterBox()}
        >
          <FiFilter />
          <p>Filter</p>
        </div>
        <div
          className="shoppageMobileFiltersRightBox"
          onClick={() => handleOpenMobileSortByBox()}
        >
          <MdSort />
          <p>Sort</p>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}
