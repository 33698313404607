import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const UpperCards = () => {
  //   const [count, setCount] = useState(0);
  const navigate = useNavigate();
  return (
    <div className="newUpperBox">
      <div>
        <div
          //   onClick={() => setCount(count + 1)}
          className="newUpperCards"
        ></div>
        <h5 className="newUpperCardsTags">UNDER 2000</h5>
        {/* {count} */}
      </div>
      <div>
        <div
          onClick={() => navigate("/shoppage")}
          className="newUpperCards"
        ></div>
        <h5 className="newUpperCardsTags">LIMITED EDITION</h5>
      </div>
      <div>
        <div className="newUpperCards"></div>
        <h5 className="newUpperCardsTags">NEW ARRIVAL</h5>
      </div>
    </div>
  );
};

export default UpperCards;
