import React from "react";

function Arrows({ prevSlide, nextSlide }) {
  // Corrected prop destructuring
  return (
    <div className="arrows">
      <span className="newHomePageSliderPrev" onClick={prevSlide}>
        &#10094;
      </span>
      <span className="newHomePageSliderNext" onClick={nextSlide}>
        &#10095;
      </span>
    </div>
  );
}

export default Arrows;
