import React from "react";
import AdminNavbar from "./AdminNavbar";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { a41 } from "../../../Api/RootApiPath";
import { useState } from "react";
import { InfinitySpin } from "react-loader-spinner";
import jsPDF from "jspdf";
import QRCode from "qrcode";

export default function AdminLabelledList() {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const productsPerPage = 100;

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  const fetchAllProducts = async () => {
    fetch(a41)
      .then((res) => res.json())
      .then((data) => {
        setAllProducts(data.data.reverse()), setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllProducts();
  }, []);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = allProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(allProducts.length / productsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const handleCheckboxChange = (productId) => {
    if (productId === "all") {
      // Select/deselect all checkboxes
      setSelectAll(!selectAll);
      setSelectedProducts(selectAll ? [] : allProducts.map((x) => x.id));
    } else {
      // Check if the product is already selected
      const isSelected = selectedProducts.includes(productId);

      // If the product is already selected, remove it from the list
      if (isSelected) {
        setSelectedProducts((prevSelected) =>
          prevSelected.filter((id) => id !== productId)
        );
      } else {
        // If the product is not selected, add it to the list
        setSelectedProducts((prevSelected) => [...prevSelected, productId]);
      }
    }
  };

  const printAll = () => {
    const selectedProductData = allProducts.filter((x) =>
      selectedProducts.includes(x.id)
    );
    printPDF(selectedProductData);
  };

  const printPDF = async (products) => {
    const doc = new jsPDF({
      // format: [26, 12],
      format: [28, 12],
      orientation: "landscape",
    });

    const fontSize = 6;
    const imageHeight = 7;
    const imageWidth = 7;

    for (let i = 0; i < products.length; i++) {
      const {
        collection,
        grosswt,
        stoneWeight,
        netWt,
        itemCode,
        purity,
        mrp,
        product_No,
        pieces,
      } = products[i];

      if (i > 0) {
        doc.addPage(); // Add a new page for each product after the first one
      }

      doc.setFontSize(fontSize);
      // {
      //   collection.length > 20
      //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
      //     : doc.text(`${collection}`, 1, 3);
      // }

      if (mrp == 0 || mrp === "") {
        doc.text(`G.Wt: ${grosswt.toFixed(3)}`, 11, 5.5);
        doc.text(`S.Wt: ${parseInt(stoneWeight).toFixed(3)}`, 11, 7.5);
        doc.text(`N.Wt: ${netWt.toFixed(3)}`, 11, 9.5);
        doc.text(`Pcs:${pieces}`, 18, 2);
        doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 4, 3);
        doc.text(`${purity}`, 20, 11.5);
      } else {
        doc.text(`G.Wt: ${grosswt.toFixed(3)}`, 11, 5.5);
        doc.text(`FC: ${parseInt(mrp)}`, 11, 7.5);
        doc.text(`Pcs:${pieces}`, 18, 2);
        doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 4, 3);
        doc.text(`${purity}`, 18, 11.5);
      }

      try {
        const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
        doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
      } catch (error) {
        console.error(error);
      }
    }

    const pdfData = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
    );
  };
  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState
            ? "adminContentbox allCustomersTable"
            : "adminContentbox adminContentbox2 allCustomersTable"
        }
      >
        <h2 style={{ margin: "1rem 0" }}>All Products Label</h2>
        <div className={loading == true ? "loading" : "none"}>
          {/* <h1>Loading...</h1> */}
          <InfinitySpin width="200" color="#4fa94d" />
        </div>

        {loading == false ? (
          <table style={{ width: "80%", marginLeft: "1rem" }}>
            <thead>
              <tr>
                <th>Id</th>
                <th>Product Name</th>
                <th>Gross Wt</th>
                <th>Stone WT</th>
                <th>Net Wt</th>
                <th>MRP</th>
                <th>Making Fixed amt</th>
                <th>Item Code</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody style={{ position: "relative" }}>
              {currentProducts.map((x, index) => (
                <tr key={x.id}>
                  {/* <td>{index}</td> */}
                  <td>{x.id}</td>
                  <td>{x.product_Name}</td>
                  <td>{x.grosswt}</td>
                  <td>{x.stoneWeight}</td>
                  <td>{x.netWt}</td>
                  <td>₹ {x.mrp}</td>
                  <td>₹ {x.making_Fixed_Amt}</td>
                  <td>{x.itemCode}</td>
                  <td>
                    <input
                      onChange={() => handleCheckboxChange(x.id)}
                      type="checkbox"
                      checked={selectAll || selectedProducts.includes(x.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            <div className="adminAllLabelledListButtonBox">
              <button onClick={printAll}>Print</button>
              <button
                onClick={() => {
                  setSelectedProducts([]);
                  setSelectAll(false);
                }}
              >
                Reset
              </button>
            </div>
          </table>
        ) : null}

        {/* Pagination controls */}
        <div className="bulkProductAddingTableMain">
          <button onClick={goToPreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <button onClick={goToNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
