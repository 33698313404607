import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import {
  a16,
  a18,
  a20,
  a22,
  a24,
  a28,
  a30,
  a31,
  a33,
  a57,
  a8,
} from "../../Api/RootApiPath";
import { BsImages } from "react-icons/bs";
import MyPDF from "./AdminComponent/AdminProductLabel";
// import AdminOpenLabel from "./AdminComponent/AdminOpenLabel";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import { InfinitySpin } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";

export default function AdminAddBulkStock() {
  const [qr, setQr] = useState("");
  const [productName, setProductName] = useState("");
  const [estDelivery, setEstDelivery] = useState("");
  //   const [categoryId, setCategoryId] = useState("");
  const [pieces, setPieces] = useState(1);
  const [huid, setHuid] = useState("");
  const [netWt, setNetWt] = useState(0);
  const [size, setSize] = useState(0);
  const [grosswt, setGrosswt] = useState(0);
  const [purity, setPurity] = useState("");
  const [collection, setCollection] = useState("");
  const [occasion, setOccasion] = useState("");
  const [gender, setGender] = useState("");
  const [description, setDescription] = useState("");
  // const [productTypeId, setProductTypeId] = useState("");
  const [partyTypeId, setPartyTypeId] = useState("");
  const [boxId, setBoxId] = useState(1);
  const [making_per_gram, setMaking_per_gram] = useState(0);
  const [making_Fixed_Amt, setMaking_Fixed_Amt] = useState(0);
  const [making_Percentage, setMaking_Percentage] = useState(0);
  const [making_Fixed_Wastage, setMaking_Fixed_Wastage] = useState(0);
  const [stoneWeight, setStoneWeight] = useState(0);
  const [stoneAmount, setStoneAmount] = useState(0);
  const [featured, setFeatured] = useState("");
  const [productCode, setProductCode] = useState("");
  const [mrp, setMRP] = useState(0);
  const [edition, setEdition] = useState("");
  const [offerPrice, setOfferPrice] = useState(0);
  const [videos, setVideos] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [itemType, setItemType] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [category, setCategory] = useState("");
  const [productTypeData, setProductTypeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [purityData, setPurityData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [sizesData, setSizesData] = useState([]);
  const [collectionTypeData, setCollectionTypeData] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [productType, setProductType] = useState("");
  const [productInEdit, setProductInEdit] = useState([]);
  const [productInEditImages, setProductInEditImages] = useState();
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [saleFrom, setSaleFrom] = useState("online");

  const [loading, setLoading] = useState(false);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  useEffect(() => {
    fetch(a18)
      .then((res) => res.json())
      .then((data) => setCategoriesData(data.data));
  }, []);
  useEffect(() => {
    fetch(a20)
      .then((x) => x.json())
      .then((y) => setProductTypeData(y.data));
  }, []);
  // console.log(productTypeData.category_id);

  useEffect(() => {
    fetch(a22)
      .then((res) => res.json())
      .then((data) => setPurityData(data.data));
    // console.log(purityData);
  }, []);
  useEffect(() => {
    fetch(a28)
      .then((res) => res.json())
      .then((data) => setPartyData(data.data.result));
  }, []);
  useEffect(() => {
    fetch(a33)
      .then((res) => res.json())
      .then((data) => setCollectionTypeData(data.data));
  }, []);
  // console.log(partyData);
  useEffect(() => {
    fetch(a30)
      .then((res) => res.json())
      .then((data) => setBoxData(data.data));
  }, []);
  useEffect(() => {
    fetch(a57)
      .then((res) => res.json())
      .then((data) => setSizesData(data.data));
  }, []);

  let categoryId = parseInt(category.split(",")[0]);
  let categoryName = category.split(",")[1];
  let productTypeId = parseInt(productType.split(",")[0]);
  let productTypeName = productType.split(",")[1];
  let collectionId = parseInt(collection.split(",")[0]);
  let collectionName = collection.split(",")[1];
  let purityId = parseInt(purity.split(",")[0]);
  let purityName = purity.split(",")[1];
  let partyId = parseInt(partyTypeId.split(",")[0]);
  let partyName = partyTypeId.split(",")[1];

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };

  //   useEffect(() => {
  //     QRCode.toDataURL(itemCode.itemCode).then(setQr);
  //   }, [itemCode]);
  // const openLabelInNew = (
  //   names,
  //   grossWts,
  //   stoneWts,
  //   netWts,
  //   itemCodes,
  //   puritys
  // ) => {
  //   // console.log("hello");
  //   const data = {
  //     format: [26, 12],
  //     orientation: "landscape",
  //     name: names,
  //     grossWt: grossWts,
  //     stoneWt: stoneWts,
  //     netWt: netWts,
  //     itemCode: itemCodes,
  //     purity: puritys,
  //     fontSize: 5, //
  //     imageHeight: 8,
  //     imageWidth: 8,
  //   };
  //   const doc = new jsPDF({
  //     format: data.format,
  //     orientation: data.orientation,
  //   });
  //   doc.setFontSize(data.fontSize);
  //   doc.text(`${data.name.substr(0, 26)}`, 1, 3);
  //   doc.text(`G.Wt: ${data.grossWt}`, 1, 5);
  //   doc.text(`S.Wt: ${data.stoneWt}`, 1, 7);
  //   doc.text(`N.Wt: ${data.netWt}`, 1, 9);
  //   doc.text(`${data.itemCode}`, 12, 6);
  //   doc.text(`${data.purity}`, 12, 8);
  //   doc.addImage(qr, "JPEG", 17, 3, data.imageWidth, data.imageHeight);

  //   const pdfData = doc.output("datauristring");
  //   const newWindow = window.open();
  //   newWindow.document.write(
  //     `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
  //   );
  // };

  const navigate = useNavigate();

  console.log(updatedProducts);
  const openLabelInNew = async (products) => {
    const doc = new jsPDF({
      // format: [26, 12],
      format: [28, 12],
      orientation: "landscape",
    });

    const fontSize = 6;
    const imageHeight = 7;
    const imageWidth = 7;

    for (let i = 0; i < products.length; i++) {
      const {
        collection,
        grosswt,
        stoneWeight,
        netWt,
        itemCode,
        purity,
        mrp,
        product_No,
        pieces,
      } = products[i];

      if (i > 0) {
        doc.addPage(); // Add a new page for each product after the first one
      }

      doc.setFontSize(fontSize);
      // {
      //   collection.length > 20
      //     ? doc.text(`${collection.substr(0, 26)}`, 1, 3)
      //     : doc.text(`${collection}`, 1, 3);
      // }

      if (mrp == 0 || mrp === "") {
        doc.text(`G.Wt: ${grosswt.toFixed(3)}`, 11, 5.5);
        doc.text(`S.Wt: ${parseInt(stoneWeight).toFixed(3)}`, 11, 7.5);
        doc.text(`N.Wt: ${netWt.toFixed(3)}`, 11, 9.5);
        doc.text(`Pcs:${pieces}`, 18, 2);
        doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 4, 3);
        doc.text(`${purity}`, 20, 11.5);
      } else {
        doc.text(`G.Wt: ${grosswt.toFixed(3)}`, 11, 5.5);
        doc.text(`FC: ${parseInt(mrp)}`, 11, 7.5);
        doc.text(`Pcs:${pieces}`, 18, 2);
        doc.text(`${product_No}`, 4, 11.5);
        doc.text(`${itemCode}`, 4, 3);
        doc.text(`${purity}`, 18, 11.5);
      }

      try {
        const qrCodeDataUrl = await QRCode.toDataURL(itemCode);
        doc.addImage(qrCodeDataUrl, "JPEG", 1, 3, imageWidth, imageHeight);
      } catch (error) {
        console.error(error);
      }
    }

    const pdfData = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
    );
  };
  // console.log(updatedProducts);
  //   console.log(productInEdit);
  //   console.log(categoryId);
  //   console.log(categoryName);
  //   console.log(parseInt(productTypeId));
  //   console.log(purity);
  //   console.log(parseInt(boxId));
  //   console.log(parseInt(mrp));
  //   console.log(parseInt(quantity));
  //   console.log(parseInt(grosswt));
  //   console.log(parseInt(stoneWeight));
  //   console.log(parseInt(netWt));
  //   console.log(selectedFiles);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    formData.append("Product_Name", productName);
    formData.append("Category_id", categoryId);
    formData.append("Category_Name", categoryName);
    formData.append("ProductTypeId", parseInt(productTypeId));
    formData.append("CollectionId", parseInt(collectionId));
    formData.append("PartyTypeId", parseInt(partyId));
    // formData.append("Party_Details", partyName);
    formData.append("purity", purityName);
    formData.append("PurityId", purityId);
    formData.append("BoxId", parseInt(boxId));
    formData.append("MRP", parseFloat(mrp));
    formData.append("OfferPrice", parseFloat(offerPrice));
    formData.append("Quantity", parseInt(quantity));
    formData.append("grosswt", parseFloat(grosswt));
    formData.append("StoneWeight", stoneWeight);
    formData.append("NetWt", parseFloat(netWt));
    formData.append("Entryby_Staff_id", parseInt(Entryby_Staff_id));
    formData.append("Product_No", partyName);
    formData.append("Product_Code", productCode);
    formData.append("Pieces", parseInt(pieces));
    formData.append("HUIDCode", huid);
    formData.append("Size", size);
    formData.append("Edition", edition);
    formData.append("videos", videos);
    formData.append("collection", collectionName);
    formData.append("occasion", occasion);
    formData.append("gender", gender);
    formData.append("description", description);
    formData.append("Making_Fixed_Amt", making_Fixed_Amt);
    formData.append("Making_per_gram", making_per_gram);
    formData.append("Making_Percentage", making_Percentage);
    formData.append("Making_Fixed_Wastage", making_Fixed_Wastage);
    formData.append("StoneAmount", stoneAmount);
    formData.append("Phuid", "0");
    formData.append("Featured", featured);
    formData.append("Itemtype", productTypeName);
    formData.append("Product_type", productTypeName);
    formData.append("EstimatedDays", estDelivery);
    formData.append("SaleFrom", saleFrom);
    // formData.append("Images", selectedFiles);
    // formData.append("Images", "");
    selectedFiles.forEach((file) => {
      formData.append("Images", file);
    });
    formData.append("ImageList1", "");
    formData.append("ImageList2", "");
    formData.append("ImageList3", "");
    formData.append("ImageList4", "");
    formData.append("ImageList5", "");
    // console.log(formData);
    try {
      const response = await fetch(a8, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(formData),
        body: formData,
      });
      const data = await response.json();

      if (response.ok) {
        setAddedProducts(data.data);
        setLoading(false);
        // setData(data.data);
        // updateImages();
        // alert("added");
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };

  // const updatedetailsBox = async (productInEdit) => {
  //   const formData = [
  //     {
  //       Id: productInEdit,
  //       Product_Name: productName,
  //       Category_id: categoryId,
  //       Category_Name: categoryName,
  //       ProductTypeId: productTypeId,
  //       PartyTypeId: parseInt(partyTypeId),
  //       CollectionId: parseInt(collectionId),
  //       purity: purity,
  //       BoxId: parseInt(boxId),
  //       MRP: parseInt(mrp),
  //       Quantity: parseInt(quantity),
  //       grosswt: parseInt(grosswt),
  //       StoneWeight: stoneWeight,
  //       NetWt: parseInt(netWt),
  //       Entryby_Staff_id: parseInt(Entryby_Staff_id),
  //       Product_No: "Product_No",
  //       Product_Code: productCode,
  //       Pieces: parseInt(pieces),
  //       HUIDCode: huid,
  //       Size: size,
  //       collection: collectionName,
  //       occasion: occasion,
  //       gender: gender,
  //       description: description,
  //       Makingcharges: makingCharges,
  //       Makingtype: makingType,
  //       StoneAmount: stoneAmount,
  //       Featured: featured,
  //       Itemtype: "ItemType",
  //       ItemCode: itemCode,
  //       ImageList1: "",
  //       ImageList2: "",
  //       ImageList3: "",
  //       ImageList4: "",
  //       ImageList5: "",
  //       images: productInEditImages,
  //       // product_tye: productTypeName,
  //     },
  //   ];
  //   // const formData = new FormData();
  //   // formData.append("Product_id", productId);
  //   // formData.append("FieldName", parameter);
  //   // formData.append("FieldValue", formValue);
  //   // console.log(JSON.stringify(formData));
  //   try {
  //     const response = await fetch(a31, {
  //       //   method: "POST",
  //       //   body: formData,
  //       // });
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });
  //     const rcvdData = await response.json();
  //     // if (data.status == "success") {
  //     console.log(rcvdData);
  //     //   alert(`${parameter} Changed Successfully`);
  //     setItemCode(rcvdData.data[0]);
  //     QRCode.toDataURL(itemCode).then(setQr);
  //     openLabelInNew(
  //       rcvdData.data[0].product_Name,
  //       rcvdData.data[0].grosswt,
  //       rcvdData.data[0].stoneWeight,
  //       rcvdData.data[0].netWt,
  //       rcvdData.data[0].itemCode,
  //       rcvdData.data[0].purity
  //     );
  //     //   setFormValue("");
  //     //   setPopup(false);
  //     // alert(`${parameter} Changed Successfully`);
  //     // } else {
  //     //   console.error(data.error);
  //     // }
  //     // setLoading(false);
  //     // setPopup(false);

  //     //   updateStaffId();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // console.log(updatedProducts);
  const handleInputChange = (e, productId, property) => {
    const updatedProducts = addedProducts.map((product) => {
      if (product.id === productId) {
        // Update the specific property in the product object
        return {
          ...product,
          [property]: e.target.value,
        };
      }
      return product;
    });

    setAddedProducts(updatedProducts);
  };

  const handleEditProducts = async () => {
    setLoading(true);
    try {
      // Send the updated products to the edit API endpoint
      const response = await fetch(a31, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(addedProducts),
      });
      // console.log(addedProducts);
      const rcvdData = await response.json();

      // console.log(rcvdData);
      openLabelInNew(rcvdData.data);
      setUpdatedProducts(rcvdData.data);
      setLoading(false);
      // setAddedProducts(rcvdData);

      // Handle the response as needed
      // ...
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(boxData);
  const filteredProducts = productTypeData.filter(
    (product) => product.category_id == categoryId
  );
  const filteredCollection = collectionTypeData.filter(
    (product) => product.category_id == categoryId
  );
  const filteredPurity = purityData.filter(
    (product) => product.category == categoryName
  );
  const filteredBoxes = boxData.filter(
    (product) => product.productName == productTypeName
  );
  const filteredSizes = sizesData.filter(
    (product) => product.collectionName == collectionName
  );

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        <div className="adminAddCategoryMainbox addProductMain">
          <form onSubmit={handleSubmit}>
            <h3 style={{ marginBottom: "3rem", width: "95%" }}>
              ADD BULK STOCK
            </h3>
            <div style={{ width: "92%" }} className="addProductDetailsBox">
              <label htmlFor="category">
                <strong>SUPPLIER</strong>
              </label>
              <select
                id="category"
                required="required"
                value={partyTypeId}
                onChange={(e) => setPartyTypeId(e.target.value)}
              >
                <option value="">Select Party / Karigar Name</option>
                {partyData.map((x) => {
                  return (
                    <option value={`${parseInt(x.id)},${x.supplier_code}`}>
                      {x.supplier_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="bulkStockAddProductDetailsBox">
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0 }}>Category</label>
                <select
                  id="category"
                  required="required"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Category</option>
                  {categoriesData.map((x) => {
                    return (
                      <option value={`${x.id},${x.name}`}>{x.name}</option>
                    );
                  })}
                </select>
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label htmlFor="productTypeId" style={{ margin: 0 }}>
                  Product
                </label>

                <select
                  id="productTypeId"
                  required="required"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <option value="">Product Type</option>
                  {filteredProducts.map((x) => {
                    return (
                      <option value={`${parseInt(x.id)},${x.productTitle}`}>
                        {x.productTitle}
                      </option>
                    );
                  })}
                  {/* {productTypeData.map((x) => {
                    return (
                      // <option value={parseInt(x.id)}>{x.productTitle}</option>
                      <option value={`${parseInt(x.id)},${x.productTitle}`}>
                        {x.productTitle}
                      </option>
                    );
                  })} */}
                </select>
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0 }}>Collection</label>
                <select
                  id="collection"
                  required="required"
                  value={collection}
                  onChange={(e) => setCollection(e.target.value)}
                >
                  <option value="">Colletion</option>
                  {filteredCollection.map((x) => {
                    return (
                      <option value={`${parseInt(x.id)},${x.collection_Name}`}>
                        {x.collection_Name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0 }}>Purity</label>
                <select
                  id="purity"
                  required="required"
                  value={purity}
                  onChange={(e) => setPurity(e.target.value)}
                >
                  <option value="">Purity</option>
                  {filteredPurity.map((x) => {
                    return (
                      <option value={`${parseInt(x.id)},${x.label}`}>
                        {x.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <div className="bulkStockAddProductDetailsItem">
                <label htmlFor="boxId" style={{ margin: 0 }}>
                  Box
                </label>
                <select
                  id="boxId"
                  // required="required"
                  value={boxId}
                  onChange={(e) => setBoxId(e.target.value)}
                >
                  <option value="">Box</option>
                  {filteredBoxes.map((x) => {
                    return <option value={parseInt(x.id)}>{x.boxName}</option>;
                  })}
                </select>
              </div> */}
              <div className="bulkStockAddProductDetailsItem">
                <label htmlFor="saleFrom" style={{ margin: 0 }}>
                  Sale From
                </label>
                <select
                  id="saleFrom"
                  // required="required"
                  value={saleFrom}
                  onChange={(e) => setSaleFrom(e.target.value)}
                >
                  <option value="online">Online</option>
                  <option value="offline">Offline</option>
                </select>
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0 }}>Size</label>
                <select
                  id="size"
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                >
                  <option value="">Size</option>
                  {filteredSizes.map((x) => {
                    return <option value={`${x.size}`}>{x.size}</option>;
                  })}
                </select>
              </div>
              {/* <div className="bulkStockAddProductDetailsItem">
                  <label style={{ margin: 0 }}>P Name</label>
                  <input
                    type="text"
                    required="required"
                    value={productName}
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                  />
                </div> */}

              <div className="bulkStockAddProductDetailsItem">
                <label htmlFor="grosswt" style={{ margin: 0 }}>
                  G.Wt
                </label>
                {/* <select
                  id="category"
                  required="required"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">G.Wt</option>
                </select> */}
                <input
                  type="number"
                  id="grosswt"
                  value={grosswt}
                  onChange={(e) => setGrosswt(e.target.value)}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label htmlFor="stoneWeight" style={{ margin: 0 }}>
                  St.Wt
                </label>
                {/* <select
                  id="category"
                  required="required"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">St.Wt</option>
                </select> */}
                <input
                  type="number"
                  id="stoneWeight"
                  value={stoneWeight}
                  onChange={(e) => setStoneWeight(e.target.value)}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label htmlFor="netWt" style={{ margin: 0 }}>
                  Net.Wt
                </label>
                {/* <select
                  id="category"
                  required="required"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="">Net.Wt</option>
                </select> */}
                <input
                  type="number"
                  id="netWt"
                  value={netWt}
                  onChange={(e) => setNetWt(e.target.value)}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0 }}>MRP</label>
                <input
                  type="number"
                  value={mrp}
                  onChange={(e) => {
                    setMRP(e.target.value);
                  }}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0 }}>Offer Price</label>
                <input
                  type="number"
                  value={offerPrice}
                  onChange={(e) => {
                    setOfferPrice(e.target.value);
                  }}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0 }}>Quantity</label>
                <input
                  required="required"
                  type="number"
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label htmlFor="name" style={{ margin: 0 }}>
                  P.Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label htmlFor="description" style={{ margin: 0 }}>
                  P.Description
                </label>
                <input
                  style={{ width: "2fr" }}
                  type="text"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0 }}>Gender</label>
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Unisex">Unisex</option>
                  <option value="Kids">Kids</option>
                </select>
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label
                  htmlFor="edition"
                  style={{ margin: 0, whiteSpace: "nowrap" }}
                >
                  Edition
                </label>
                <input
                  style={{ width: "2fr" }}
                  type="text"
                  id="edition"
                  value={edition}
                  onChange={(e) => setEdition(e.target.value)}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label
                  htmlFor="delivery"
                  style={{ margin: 0, whiteSpace: "nowrap" }}
                >
                  Est Delivery
                </label>
                <input
                  style={{ width: "2fr" }}
                  type="text"
                  id="delivery"
                  value={estDelivery}
                  onChange={(e) => setEstDelivery(e.target.value)}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label
                  htmlFor="videos"
                  style={{ margin: 0, whiteSpace: "nowrap" }}
                >
                  Videos
                </label>
                <input
                  style={{ width: "2fr" }}
                  type="text"
                  id="videos"
                  value={videos}
                  onChange={(e) => setVideos(e.target.value)}
                />
              </div>
              <div className="bulkStockAddProductDetailsItem">
                <label style={{ margin: 0, cursor: "pointer" }}>
                  Images {`${selectedFiles.length}`}
                  {/* <div className="bulkStockAddProductDetailsImageBox"> */}
                  <BsImages
                    style={{ margin: "1.2rem", marginInline: "1rem" }}
                    size={"2.5rem"}
                  />
                  <input
                    id="images"
                    style={{ display: "none" }}
                    type="file"
                    multiple
                    onChange={handleFileInputChange}
                  />
                  {/* </div> */}
                </label>
              </div>

              <button
                type="submit"
                style={{ width: "100px", marginInline: "0" }}
              >
                Add
              </button>
            </div>
          </form>
        </div>
        {/* <div>
          <h1>Table Component</h1>
          <table style={{ margin: "3rem" }}>
            <thead>
              <tr>
                <th>ID</th>
                <th>P Name</th>
                <th>Gross Wt</th>
                <th>Stone Wt</th>
                <th>Net Wt</th>
              </tr>
            </thead>
             <tbody>{renderTableRows()}</tbody> 
          </table>
        </div> */}
        <div
          style={{ height: "100px", marginBottom: "1rem" }}
          className={loading == true ? "loading" : "none"}
        >
          <InfinitySpin
            className={loading == true ? "loading" : "none"}
            width="150"
            color="#4fa94d"
          />
        </div>
        <h3 style={{ margin: "0.5rem 0" }}>Added Products</h3>
        {/* <form onSubmit={updatedetailsBox}> */}
        <div
          className="bulkProductAddingTableMain"
          style={{ margin: "1.5rem", overflowX: "auto" }}
        >
          <table>
            <thead>
              <tr>
                <th>Product Type</th>
                <th>Collection</th>
                <th>Purity</th>
                <th>Label</th>
                <th>Product name</th>
                <th>HUID Code</th>
                <th>GrossWt</th>
                <th>StoneWt</th>
                <th>NetWt</th>
                <th>Stone Amount</th>
                <th>Product Huid</th>
                <th>Making Per Gram</th>
                <th>Making Percentage</th>
                <th>Fixed Making</th>
                <th>Fixed Wastage</th>
                <th>Pieces</th>
                <th>Size</th>
                <th>MRP</th>
                <th>Offer Price</th>
                <th>Description</th>
                <th>Occassion</th>
                <th>Edition</th>
                <th>Gender</th>
                <th>Sale From</th>
              </tr>
            </thead>
            <tbody>
              {addedProducts.map((x) => (
                // <tr key={x.Customer_id}>

                <tr key={x.id}>
                  <td>
                    <input
                      type="text"
                      placeholder={x.product_type}
                      // value={x.product_type}
                      // onChange={(e) => handleInputChange(e, x.id, "Product_type")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.collection}
                      value={x.collection}
                      // onChange={(e) => handleInputChange(e, x.id, "collection")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.purity}
                      value={x.purity}
                      // onChange={() => {
                      //   setPurity(x.purity);
                      // }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.itemCode}
                      value={x.itemCode}
                      //   onChange={() => {
                      //     setItemCode(x.itemCode);
                      //   }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.product_Name}
                      // value={x.product_Name}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Product_Name")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      maxLength={6}
                      placeholder={x.huidCode}
                      onChange={(e) => handleInputChange(e, x.id, "huidCode")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.grosswt}
                      onChange={(e) => handleInputChange(e, x.id, "grosswt")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      required="required"
                      placeholder={x.stoneWeight}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "StoneWeight")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.netWt}
                      onChange={(e) => handleInputChange(e, x.id, "NetWt")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.stoneAmount}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "StoneAmount")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.phuid}
                      onChange={(e) => handleInputChange(e, x.id, "phuid")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.making_per_gram}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Making_per_gram")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.making_Percentage}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Making_Percentage")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.making_Fixed_Amt}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Making_Fixed_Amt")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.making_Fixed_Wastage}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "Making_Fixed_Wastage")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.pieces}
                      onChange={(e) => handleInputChange(e, x.id, "pieces")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.size}
                      onChange={(e) => handleInputChange(e, x.id, "Size")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.mrp}
                      onChange={(e) => handleInputChange(e, x.id, "mrp")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder={x.offerPrice}
                      onChange={(e) => handleInputChange(e, x.id, "offerPrice")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.description}
                      onChange={(e) =>
                        handleInputChange(e, x.id, "description")
                      }
                    />
                  </td>

                  <td>
                    <input
                      type="text"
                      placeholder={x.occasion}
                      onChange={(e) => handleInputChange(e, x.id, "occasion")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.edition}
                      onChange={(e) => handleInputChange(e, x.id, "edition")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder={x.gender}
                      onChange={(e) => handleInputChange(e, x.id, "gender")}
                    />
                  </td>
                  {/* <td>
                    <input
                    type="text"
                    placeholder={x.featured}
                    onChange={(e) => handleInputChange(e, x.id, "Featured")}
                    />
                  </td> */}
                  <td>
                    <select
                      style={{ margin: "0px 5px", padding: "7.5px" }}
                      value={x.saleFrom}
                      placeholder={x.saleFrom}
                      onChange={(e) => handleInputChange(e, x.id, "saleFrom")}
                    >
                      <option value={"online"}>Online</option>
                      <option value={"offline"}>Offline</option>
                    </select>
                    {/* <input
                      type="text"
                      placeholder={x.saleFrom}
                      onChange={(e) => handleInputChange(e, x.id, "saleFrom")}
                    /> */}
                  </td>
                  {/* <td>
                    <button
                      type="submit"
                      onClick={() => {
                        setItemCode(x.itemCode);
                        setProductInEditImages(x.images);
                        setProductInEdit(x);
                        {
                          console.log(x.id);
                        }
                        updatedetailsBox(x.id);
                      }}
                    >
                      Update
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <button style={{ cursor: "pointer" }} onClick={handleEditProducts}>
            Save All
          </button>

          <Link to="/admin-labelled-list">
            <button style={{ cursor: "pointer" }}>Labelled List</button>
          </Link>
          <button
            onClick={() => {
              setAddedProducts([]);
              setSelectedFiles([]);
            }}
          >
            New Item
          </button>
          <button
            style={{ backgroundColor: "#f1556c" }}
            className="adminAddBulkToUpdatePageButton"
            onClick={() => navigate("/admin-bulk-update-products")}
          >
            Update Bulk Products
          </button>

          <button>Cancel</button>
        </div>
        {/* </form> */}
      </div>
    </div>
  );
}
