import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { a1 } from "../../Api/RootApiPath";

export default function AdminInvoice() {
  const [allCsData, setAllCsData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  useEffect(() => {
    fetch(a1)
      .then((res) => res.json())
      .then((response) => {
        setAllCsData(response.data);
      });
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      setCustomerName(
        `${selectedCustomer.firstName} ${selectedCustomer.lastName}`
      );
      setCustomerMobile(selectedCustomer.mobile);
    } else {
      setCustomerName("");
      setCustomerMobile("");
    }
  }, [selectedCustomer]);

  const handleCustomerNameChange = (e) => {
    setCustomerName(e.target.value);
  };

  const handleCustomerMobileChange = (e) => {
    setCustomerMobile(e.target.value);
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
  };

  const filteredCustomers = allCsData.filter((customer) => {
    const fullName = `${customer.firstName} ${customer.lastName}`;
    return fullName.toLowerCase().includes(customerName.toLowerCase());
  });

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        <h3>Customer Bill</h3>
        <div className="customerBillMainOuterBox">
          <div className="customerBillMainInnerBox">
            <div className="customerBillCustomersDetailsMainBox">
              <label>Customer Name</label>
              <input
                type="text"
                value={customerName}
                onChange={handleCustomerNameChange}
                list="customerNamesList"
              />
              <datalist id="customerNamesList">
                {filteredCustomers.map((customer) => (
                  <option
                    key={customer.id}
                    value={`${customer.firstName} ${customer.lastName}`}
                    onClick={() => handleCustomerSelect(customer)}
                  />
                ))}
              </datalist>
            </div>
            <div className="customerBillCustomersDetailsMainBox">
              <label>Mobile Number</label>
              <input
                type="text"
                value={customerMobile}
                onChange={handleCustomerMobileChange}
                list="customerMobilesList"
              />
              <datalist id="customerMobilesList">
                {filteredCustomers.map((customer) => (
                  <option
                    key={customer.id}
                    value={customer.mobile}
                    onClick={() => handleCustomerSelect(customer)}
                  />
                ))}
              </datalist>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
