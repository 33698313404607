import React, { useEffect, useState } from "react";
import "../PagesStyles/ProductPage.css";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { IoShuffleSharp } from "react-icons/io5";
import { RiTruckLine, RiHandCoinLine } from "react-icons/ri";
import { BiSupport } from "react-icons/bi";
// import { useParams } from "react-router-dom";
import { a13, a55, a61, s1, s3 } from "../../Api/RootApiPath";
// import uspPoints from "../../Images/whyUs3.png";
import uspPoints from "../../Images/productPageUsp.jpg";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/action/Actions";
import { AiTwotoneCalendar, AiOutlineSafetyCertificate } from "react-icons/ai";
import { BsTruck } from "react-icons/bs";
import { FaExchangeAlt } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import { FaYoutube } from "react-icons/fa";
import { FaPlayCircle } from "react-icons/fa";

export default function ProductDetails({
  // id,
  product_Id,
  product_No,
  product_Name,
  categoryId,
  huid,
  netWt,
  size,
  grosswt,
  purity,
  collection,
  occasion,
  gender,
  description,
  product_Type,
  making_per_gram,
  making_Percentage,
  making_Fixed_Amt,
  making_Fixed_Wastage,
  purityRate,
  stoneWeight,
  stoneAmount,
  phuid,
  featured,
  productCode,
  mrp,
  offerPrice,
  collectionId,
  images,
  videos,
  statusType,
  estimatedDays,
  updateSection,
  onScrollToPriceBreakup,
  onScrollToProductDescription,
  productSizeChanged,
  productSizeUpdated,
}) {
  const { id } = useParams();

  let allImages = "";
  if (images !== null || images !== undefined) {
    allImages = images.split(",");
  }

  let allVideos = "";
  if (videos !== null || videos !== undefined) {
    allVideos = videos.split(",");
  }
  const orderState = useSelector((state) => state);
  const userDetails = orderState.reducer4;
  // console.log(userDetails, "userDetails");
  // console.log(userDetails, "userDetails");
  // const imagesArray = [
  //   imageList1,
  //   imageList2,
  //   // imageList3,
  //   // imageList4,
  //   // imageList5,
  // ];
  const dispatch = useDispatch();
  const handleAddToBag = () => {
    if (userDetails.length === 0) {
      navigate(`/checkoutpagenewlogin?productId=${product_Id}`);
    } else {
      handleAddToCartItem();
    }
  };
  const handleAddToCartItem = async () => {
    const formData = {
      Customer_Id: userDetails[0].id,
      ProductId: product_Id,
      CartCount: `${count}`,
      Status: "Active",
    };
    // console.log(formData, "formData");
    // console.log(formData, "formData");
    try {
      const response = await fetch(a61, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([formData]),
      });
      const data = await response.json();
      // console.log(data, "1st hit data");
      // console.log(data, "1st hit data");
      if (data && data.data && data.data.length > 0) {
        let newMyObject = { ...myObject }; // Clone the original object to a new one
        newMyObject.id = data.data[0].id; // Assuming you want to add the ID from the first item of data array

        // Dispatch the updated object
        dispatch(addToCart(newMyObject));
        fbq("track", "AddToCart");
      } else {
        console.error("No data available to update ID.");
      }
    } catch (error) {
      // if (response.status === 500) {
      // console.log(response.status);
      // console.log(response.statusText);
      // }
      console.error(error);
      // alert(error.message);
    }
  };

  const [loading, setLoading] = useState(false);
  // const [mainImage, setMainImage] = useState(imagesArray[0]);
  const [mainImage, setMainImage] = useState(images.split(",")[0]);
  const [mainVideo, setMainVideo] = useState(videos.split(",")[0]);
  useEffect(() => {
    if (images !== null || images !== undefined) {
      allImages = images.split(",");
      return setMainImage(allImages[0]);
    }
  }, [images]);
  useEffect(() => {
    if (videos !== null || videos !== undefined || videos !== "") {
      allVideos = videos.split(",");
      return setMainVideo(allVideos[0]);
    }
  }, [videos]);

  const [finalProductPrice, setFinalProductPrice] = useState("");
  const [finalProductMaking, setFinalProductMaking] = useState("");
  const [finalGrossRate, setFinalGrossRate] = useState("");

  const [border, setBorder] = useState("border1");
  const [count, setCount] = useState(1);
  const [offerPriceTotal, setOfferPriceTotal] = useState(0);
  const [showVideo, setShowVideo] = useState(false);

  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    offerPrice,
    purityRate,
    id,
    phuid
  ) => {
    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;
    let grossTotalRate = 1;
    let totalMaking =
      makingCharges1 + makingCharges2 + makingCharges3 + makingCharges4;

    if (offerPrice !== "" && offerPrice !== 0) {
      grossTotalRate = offerPrice;
    } else if (mrp !== "" && mrp !== 0) {
      grossTotalRate = mrp;
    }

    grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount +
      phuid;
    // console.log(id, making_Fixed_Wastage);
    let GSTAdded = GST * grossTotalRate;
    let finalPrice = (grossTotalRate + GSTAdded).toFixed(0);

    if (offerPrice !== "" && offerPrice !== 0) {
      GSTAdded = GST * offerPrice;
      finalPrice = (parseFloat(offerPrice) + GSTAdded).toFixed(0);
      setOfferPriceTotal(
        parseInt(parseFloat(mrp) + parseFloat(mrp * 0.03)).toFixed(2)
      );
    } else if (mrp !== "" && mrp !== 0) {
      GSTAdded = GST * mrp;
      finalPrice = (mrp + GSTAdded).toFixed(0);
    }
    if (offerPrice !== "" && offerPrice !== 0 && mrp !== "" && mrp !== 0) {
      setOfferPriceTotal(
        parseInt(parseFloat(mrp) + parseFloat(mrp * 0.03)).toFixed(2)
      );
    } else {
      setOfferPriceTotal(grossTotalRate + GSTAdded);
    }
    return (
      setFinalProductPrice(finalPrice),
      setLoading(true),
      setFinalProductMaking(totalMaking),
      setFinalGrossRate(netGoldRate)
    );
  };
  useEffect(() => {
    calculateFinalPrice(
      netWt,
      making_per_gram,
      making_Percentage,
      making_Fixed_Amt,
      making_Fixed_Wastage,
      stoneAmount,
      mrp,
      offerPrice,
      purityRate,
      id,
      phuid
    );
    setCount(1);
  }, [mainImage, productSizeUpdated]);
  const myObject = {
    Product_id: product_Id,
    Type:
      offerPrice !== "" && offerPrice !== 0
        ? "OfferPrice"
        : mrp !== "" && mrp !== 0
        ? "Mrp"
        : "Weight",
    productNo: product_No,
    title: product_Name,
    image: allImages[0],
    making: Math.max(finalProductMaking),
    finalGrossRate:
      (offerPrice !== "" && offerPrice !== 0) || (mrp !== "" && mrp !== 0)
        ? 0
        : Math.max(finalGrossRate),
    discount: 0,
    coupon: "",
    originalPrice: offerPrice
      ? Math.max(offerPriceTotal)
      : Math.max(finalProductPrice),
    originalMaking: Math.max(finalProductMaking),
    originalFinalGrossRate: Math.max(finalGrossRate),
    // price: finalProductPrice,
    price: Math.max(finalProductPrice),
    count: count,
    size: size,
    // finalPrice: finalProductPrice,
    // finalPrice: offerPrice
    //   ? Math.max(offerPrice * 1.03)
    //   : Math.max(finalProductPrice),
    finalPrice: Math.max(finalProductPrice),
  };
  useEffect(() => {
    if (images !== null || images !== undefined) {
      allImages = images.split(",");
      return setMainImage(allImages[0]);
    }
  }, [id]);

  const openProductDescription = (data) => {
    // const section = "ProductDescription";
    updateSection(data);
  };
  const [availableSizes, setAvailableSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [allCollectionList, setAllCollectionList] = useState([]);

  const searchCollection = async () => {
    const formData = {
      CollectionId: collectionId,
    };
    try {
      const response = await fetch(a55, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setAllCollectionList(data.data);
      setAvailableSizes([...new Set(data.data.map((item) => item.size))]);
      // console.log(data.data, "collectionData");
      // console.log(availableSizes);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  // console.log(availableSizes);
  // console.log(selectedSize);
  useEffect(() => {
    searchCollection();
  }, []);
  const navigate = useNavigate();
  const handleSelectedSize = (e) => {
    const { value } = e.target;
    setSelectedSize(value);
    const newSize = allCollectionList.filter((x) => x.size == value);
    // console.log(newSize, "newSize");
    // console.log(selectedSize, "selectedSize");

    navigate(`/productpage/${newSize[0].id}`);
  };

  useEffect(() => {
    calculateFinalPrice(
      netWt,
      making_per_gram,
      making_Percentage,
      making_Fixed_Amt,
      making_Fixed_Wastage,
      stoneAmount,
      mrp,
      offerPrice,
      purityRate,
      id,
      phuid
    );
    setLoading(true);
  }, [loading]);
  useEffect(() => {
    if (showVideo) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [showVideo]);
  const handleVideoBoxClick = (e) => {
    // Prevent event propagation to inner box
    e.stopPropagation();
    setShowVideo(true);
  };

  const handleOuterVideoBoxClick = () => {
    // Close the coupon box when clicking anywhere outside inner box
    setShowVideo(false);
  };

  const [isRing, setIsRing] = useState(false);
  const pattern = new RegExp(`\\b${"ring"}\\b`, "i");
  useEffect(() => {
    let isRingTrue = pattern.test(product_Name.trim());

    setIsRing(isRingTrue);
  }, [id]);
  const handleDownloadPDF = () => {
    // Assuming your PDF file is located in the public folder
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/Ring Sizer Ghare.pdf`; // Adjust the path as needed
    // link.download = "Ring Sizer Ghare.pdf"; // The file name for download
    link.target = "_blank"; // Open in a new tab
    document.body.appendChild(link); // Append to html
    link.click(); // Simulate click
    document.body.removeChild(link); // Clean up
  };
  return (
    <div className="productDetailsMainBox">
      {mainImage !== null || images !== undefined ? (
        <div className="productImagesBox">
          <div className="smallBoxes">
            {/* {imagesArray.map((currElm, index) => { */}
            {allImages.map((currElm, index) => {
              return (
                <div className="" key={currElm}>
                  <img
                    className="smallBox"
                    // src={`${s1}${currElm}`}
                    src={`${s3}/${currElm}`}
                    alt="Product Images from different angles"
                    key={currElm}
                    onClick={() => setMainImage(currElm)}
                  />
                </div>
              );
            })}
            {allVideos.length > 0 && allVideos[0] !== ""
              ? allVideos.map((x, index) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        handleVideoBoxClick(e), setMainVideo(x);
                      }}
                      className="productDetailsVideoThumbnail"
                    >
                      <img
                        key={index}
                        className="smallBox"
                        // src={`${s1}${currElm}`}
                        src={`https://img.youtube.com/vi/${x}/0.jpg`}
                        alt="Video Thumbnail"

                        // onClick={handleThumbnailClick}
                      />
                      <div className="productDetailsVideoThumbnailPlayIcon">
                        <FaPlayCircle size={30} color="white" />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          {/* {setMainImage(allImages[0])} */}
          <div className="largeBox">
            <ReactImageMagnify
              // className="largeBox"
              enlargedImagePosition="over"
              {...{
                smallImage: {
                  alt: "product Images",
                  isFluidWidth: true,
                  // src: `${s1}${mainImage}`,
                  src: `${s3}/${mainImage}`,
                },
                largeImage: {
                  // src: `${s1}${mainImage}`,
                  src: `${s3}/${mainImage}`,
                  width: 1500,
                  height: 1500,
                },
              }}
            />
          </div>
        </div>
      ) : null}
      <div className="productDescriptionBox">
        <div className="titleAndDetails">
          <h1>{product_Name}</h1>

          <div className="productDescriptionBoxDetails">
            <p>
              {/* {description.substr(0, 400)} */}
              {description}
            </p>

            {/* <span
              style={{ cursor: "pointer", textAlign: "end" }}
              className="productDescriptionLink"
              id="productDescriptionSection"
            >
              <em onClick={onScrollToProductDescription}>Product Details</em>
            </span> */}
            <div
              style={{
                width: "100%",
                textAlign: "right",
              }}
            >
              <span id="productDescriptionSection">
                {/* <em onClick={() => window.scrollTo(0, 1050)}>Price Breakup</em> */}
                <em
                  onClick={() => {
                    openProductDescription("ProductDetails"),
                      onScrollToProductDescription();
                  }}
                >
                  Product Details
                </em>
              </span>
            </div>
          </div>
        </div>
        {loading == false ? (
          <h3 style={{ margin: "2rem" }}>Loading Price...</h3>
        ) : (
          <div className="priceBox" style={{ marginRight: "0" }}>
            {offerPrice !== "" && offerPrice !== 0 ? (
              <>
                <h3
                  style={{
                    textDecoration: "line-through",
                    marginBottom: "10px",
                  }}
                >
                  Price : ₹ {parseFloat(offerPriceTotal)}{" "}
                </h3>
                <h2> Offer Price : ₹ {parseFloat(finalProductPrice)}</h2>
              </>
            ) : (
              <h2>Price : ₹ {parseFloat(finalProductPrice)}</h2>
            )}
            {/* <h2>Price : ₹ {parseFloat(finalProductPrice)}</h2> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>(Incl. of all taxes)</span>
              <span id="priceBreakupOption">
                {/* <em onClick={() => window.scrollTo(0, 1050)}>Price Breakup</em> */}
                <em
                  onClick={() => {
                    openProductDescription("PriceBreakup"),
                      onScrollToPriceBreakup();
                  }}
                >
                  Price Breakup
                </em>
              </span>
            </div>
          </div>
        )}
        <div
          style={{
            marginBottom: "2rem",
            borderBottom: "1px solid rgba(128, 128, 128, 0.3)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginTop: "10px",
              marginInline: "1rem",
              alignItems: "center",
            }}
          >
            <label style={{ margin: "0px", fontSize: "18px" }}>
              Select Size
            </label>
            <select
              style={{
                marginInline: "15px",
                height: "25px",
                paddingInline: "10px",
                cursor: "pointer",
              }}
              value={selectedSize}
              onChange={(e) => {
                productSizeChanged(), handleSelectedSize(e);
              }}
            >
              {availableSizes.map((x) => (
                <option value={x}>{x}</option>
              ))}
            </select>
            {isRing ? (
              <p onClick={() => handleDownloadPDF()} id="priceBreakupOption">
                Size Guide
              </p>
            ) : null}
          </div>
          <div className="addToCartBox">
            <button
              className="countBtn"
              onClick={() => setCount(count > 1 ? count - 1 : count)}
            >
              -
            </button>
            <div className="countInput">{count}</div>
            <button className="countBtn" onClick={() => setCount(count + 1)}>
              +
            </button>
            <button className="addToCartBtn" onClick={handleAddToBag}>
              <AiOutlineShoppingCart
                size={25}
                style={{ marginRight: "1rem" }}
              />
              Add to Cart
            </button>
            <IoShuffleSharp size={25} className="addToCartIcon" />
            <AiOutlineHeart size={25} className="addToCartIcon" />
          </div>
          <p style={{ margin: "1rem" }}>
            <em>Estimated Delivery : {estimatedDays} days</em>
          </p>
        </div>
        <div className="whyUsBox">
          <img
            className="whyUsBox"
            src={uspPoints}
            alt="Benefits of Shopping with Ghare's"
          />
          {/* <div className="whyUsItems">
            <BsTruck
              size={40}
              color={"#1a4c2d"}
              style={{ marginBottom: "0.5rem" }}
            />
            <p>Fast-Free Delivery</p>
          </div>
          <div className="whyUsItems">
            <AiOutlineSafetyCertificate
              size={40}
              color={"#1a4c2d"}
              style={{ marginBottom: "0.5rem" }}
            />
            <p>100% Hall-Marked & Certified Products </p>
          </div>
          <div className="whyUsItems">
            <GiReceiveMoney
              size={40}
              color={"#1a4c2d"}
              style={{ marginBottom: "0.5rem" }}
            />
            <p>15-Day Hassle-free Return</p>
          </div>
          <div className="whyUsItems" style={{ borderRight: "none" }}>
            <FaExchangeAlt
              size={40}
              color={"#1a4c2d"}
              style={{ marginBottom: "0.5rem" }}
            />
            <p>Life-Time Exchange & Buy-Back Policy</p>
          </div> */}
        </div>
      </div>
      <div
        style={
          showVideo === true
            ? {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "5",
              }
            : null
        }
        onClick={() => setShowVideo(false)}
        className={showVideo === true ? "new" : "new2"}
      >
        {showVideo ? (
          <div style={{ width: "100%", maxWidth: "800px", margin: "auto" }}>
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${mainVideo}?autoplay=1`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        ) : null}
      </div>
    </div>
  );
}
