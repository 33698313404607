import React, { useState } from "react";
import { useSelector } from "react-redux";
import { s3 } from "../../Api/RootApiPath";
import { useNavigate } from "react-router-dom";

export default function CheckOutPageNewSummaryCard({ paymentStatus }) {
  const [showCouponBox, setShowCouponBox] = useState(false);

  const allStates = useSelector((state) => state);
  const orderItems = allStates.reducer2;
  const userDetails = allStates.reducer4;
  // console.log(orderItems[0]);
  // console.log(orderItems.length);
  const navigate = useNavigate();
  let totalPrice = 0;
  const foundOrderItem = orderItems.find((x) => x.coupon !== "");

  // Set couponName based on the condition
  const couponName =
    foundOrderItem && foundOrderItem.coupon ? foundOrderItem.coupon : null;
  let totalDiscount = 0;
  const productCard = () => {
    return (
      <div className="checkOutPageNewProductCardOuterBox">
        {orderItems.map((x) => {
          const productPrice = x.count * x.finalPrice; // calculate price for this product
          totalPrice += productPrice;

          totalDiscount += parseFloat(x.discount);
          // add price to total
          return (
            <div className="checkOutPageNewProductCardMain">
              <div className="checkOutPageNewProductCardImageBox">
                <img
                  className="checkOutPageNewProductCardImageBox"
                  src={`${s3}/${x.image}`}
                  alt="Product Image"
                />
              </div>
              <div className="checkOutPageNewProductCardDetailsBox">
                <h5 style={{ marginBottom: "5px" }}>
                  <em>{x.title}</em>
                </h5>
                <p>Product Number: {x.Product_id}</p>
                <p>Size: {x.size}</p>
                <p>Qty: {x.count}</p>
              </div>
              <div className="checkOutPageNewProductCardPriceBox">
                <h5>₹{x.price * x.count}</h5>
              </div>
            </div>
          );
        })}
        <div className="checkOutPageNewProductCardOuterTotalBox">
          <div>
            <p>Order Value</p>
            <p>
              Discount
              <span
                onClick={() => {
                  couponName ? null : navigate("/checkoutMain");
                }}
                className="checkOutPageNewProductCardDiscountButton"
              >
                {couponName ? couponName : "Apply"}
              </span>
            </p>
            <p>Delivery</p>
            <p>Total</p>
          </div>
          <div style={{ textAlign: "end" }}>
            <p>₹{totalPrice}</p>
            {/* <p>₹0</p> */}
            <p
              style={
                totalDiscount !== 0
                  ? { color: "green", fontWeight: "bold" }
                  : null
              }
            >
              ₹{parseFloat(totalDiscount).toFixed(0)}
            </p>
            <p>₹0</p>
            <p>₹{totalPrice}</p>
          </div>
        </div>
        {paymentStatus === "onlinePayu" ? (
          <div className="checkoutNewButtonBox">
            <button
              onClick={() =>
                // navigate(`/paymentpage?customerId=${userDetails[0].id}`)
                navigate(`/payu_paymentpage?customerId=${userDetails[0].id}`)
              }
              style={{ width: "100%" }}
            >
              Pay Now
            </button>
          </div>
        ) : paymentStatus === "onlineRazorPay" ? (
          <div className="checkoutNewButtonBox">
            <button
              onClick={() =>
                navigate(
                  `/razorpay_paymentpage?customerId=${userDetails[0].id}`
                )
              }
              style={{ width: "100%" }}
            >
              Pay Now
            </button>
          </div>
        ) : (
          <div className="checkoutNewButtonBox">
            <button style={{ width: "100%", opacity: 0.5 }}>Almost Done</button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      //   style={{ position: "relative" }}
      className="checkOutPageNewSummaryCardMainBox"
    >
      <h2>Your Order</h2>
      <div className="checkOutPageNewSummaryCardInnerBox">
        <p>ITEM</p>
        <p>TOTAL</p>
      </div>
      {orderItems.length === 0 ? null : productCard()}
      {/* {showCouponBox ? (
        <div className="couponBoxFullPageMain">Hello</div>
      ) : null} */}
    </div>
  );
}
