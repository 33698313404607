import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { ColorRing } from "react-loader-spinner";
import { a16, s3 } from "../../Api/RootApiPath";
import { MdSort } from "react-icons/md";
import { FiFilter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import goldCoinBanner from "../../Images/CategoryPageBanners/specialOccasionGoldCoinBanner.jpg";
import goldCoinBannerMobile from "../../Images/CategoryPageBanners/specialOccasionGoldCoinBannerMobile.jpg";
import limitedEditionBanner from "../../Images/CategoryPageBanners/limitedEditionBanner.jpg";
import limitedEditionBannerMobile from "../../Images/CategoryPageBanners/limitedEditionBannerMobile.jpg";
import limitedEditionFont from "../../Images/CategoryPageBanners/limitedEditionFont.png";
import oxidizedBannerMobile from "../../Images/CategoryPageBanners/oxidisedBannerMobile.jpg";
import oxidizedBanner from "../../Images/CategoryPageBanners/oxidisedBanner.jpg";
import n from "../../Images/CategoryPageBanners/n.png";


import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

export default function OxidizedProductPage() {
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllProductsData();
  }, []);

  const fetchAllProductsData = async () => {
    try {
      const data = await fetch(a16);
      const response = await data.json();
      console.log(response.data, "response");
      if (response.status === "No Data available") {
        setAllProducts("NoData");
        setLoading(false);
      } else {
        const filterProducts = response.data.filter(
          (product) =>
          (product.gender && product.gender.includes("Male")) &&
    (product.tblMaterialCategory && product.tblMaterialCategory.material.includes("Silver")) &&
    (product.product_Name && product.product_Name.includes("Bracelet"))
        );
        console.log(filterProducts, "filterProducts");

        setAllProducts(filterProducts);
        setLoading(false);
        setFilteredProducts(filterProducts);
      }
    } catch (error) {
      console.log("Error fetching products data: ", error);
    }
  };
  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    offerPrice,
    purityRate,
    id,
    phuid
  ) => {
    // Parse input values to float and handle NaN cases
    netWt = parseFloat(netWt) || 0;
    making_per_gram = parseFloat(making_per_gram) || 0;
    making_Percentage = parseFloat(making_Percentage) || 0;
    making_Fixed_Amt = parseFloat(making_Fixed_Amt) || 0;
    making_Fixed_Wastage = parseFloat(making_Fixed_Wastage) || 0;
    stoneAmount = parseFloat(stoneAmount) || 0;
    mrp = parseFloat(mrp) || 0;
    offerPrice = parseFloat(offerPrice) || 0;
    purityRate = parseFloat(purityRate) || 0;

    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;

    let grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;

    let finalPrice = 0;

    // if (offerPrice !== 0) {
    //   finalPrice = offerPrice;
    // } else
    if (mrp !== 0) {
      finalPrice = mrp;
    }
    if (
      // offerPrice === 0 &&
      mrp === 0
    ) {
      finalPrice = grossTotalRate; // Set final price to grossTotalRate
    }

    // let GSTAdded = GST * grossTotalRate;
    // finalPrice += GSTAdded;

    return finalPrice.toFixed(0);
  };

  const updatedData0 = allProducts.map((product) => ({
    ...product,
    purityRate: parseFloat(product.tblPurity.todaysRate),
  }));
  const updatedData = updatedData0.map((product) => ({
    ...product,
    sellPrice: calculateFinalPrice(
      parseFloat(product.netWt),
      parseFloat(product.making_per_gram),
      parseFloat(product.making_Percentage),
      parseFloat(product.making_Fixed_Amt),
      parseFloat(product.making_Fixed_Wastage),
      parseFloat(product.stoneAmount),
      parseFloat(product.mrp),
      parseFloat(product.offerPrice),
      parseFloat(product.purityRate),
      product.id,
      product.phuid
    ),
  }));

  const allProductsAvailable = () => {
    return filteredProducts.map((product) => (
      <div
        key={product.id}
        style={{
          borderRadius: "10px",
          height: "auto",
          backgroundColor: "white",
        }}
        className="container"
        onClick={() => {
          navigate(`/productpage/${product.id}`);
        }}
      >
        {/* <div className="homePageSpecialOccasionContainerImageBox"> */}
        <img
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
          className="productsImages"
          src={`${s3}/${product.images.split(",")[0]}`}
          alt="Products Images"
        />
        {/* </div> */}
        <div className="shoppageProductDescriptionBox">
          <div className="shoppageProductDescriptionBoxTitle">
            <h3>{product.product_Name}</h3>
          </div>
          {/* <div className="shoppageProductDescriptionBoxDetails">
            <p
              style={{ color: "rgba(0,0,0,0.4)" }}
            >{`${product.description.substr(0, 75)} ...`}</p>
          </div> */}
          <div className="shoppageProductDescriptionBoxDetails">
            {/* <div className="specialOccasionPageProductLabelOuterBox">
              <div className="specialOccasionPageProductLabelInnerBox">
                <p>{product.netWt} Gram</p>
              </div>
            </div> */}
            {/* <strong>
              <h3 style={{ color: "green", fontWeight: "bold" }}>
                ₹{product.sellPrice}
              </h3>
            </strong> */}
            {product.offerPrice &&
            product.offerPrice !== 0 &&
            product.offerPrice !== "0" ? (
              <div style={{ display: "flex" }}>
                <strong>
                  <h3
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                      fontWeight: "bold",
                    }}
                  >
                    ₹{product.sellPrice}
                  </h3>
                </strong>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.offerPrice}
                  </h3>
                </strong>
              </div>
            ) : (
              <>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.sellPrice}
                  </h3>
                </strong>
              </>
            )}
          </div>
        </div>
      </div>
    ));
  };

  useEffect(() => {
    const updatedData0 = allProducts.map((product) => ({
      ...product,
      purityRate: parseFloat(product.tblPurity.todaysRate),
    }));

    const updatedData = updatedData0.map((product) => ({
      ...product,
      sellPrice: calculateFinalPrice(
        parseFloat(product.netWt),
        parseFloat(product.making_per_gram),
        parseFloat(product.making_Percentage),
        parseFloat(product.making_Fixed_Amt),
        parseFloat(product.making_Fixed_Wastage),
        parseFloat(product.stoneAmount),
        parseFloat(product.mrp),
        parseFloat(product.offerPrice),
        parseFloat(product.purityRate),
        product.id,
        product.phuid
      ),
    }));
    let tempFilteredProducts = updatedData;

    setFilteredProducts(tempFilteredProducts);
    // window.scrollTo(0, 0);
  }, [allProducts]);

  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      {loading ? (
        <div
          style={{
            height: "150px",
            margin: "100px 0",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#fee9d4",
          }}
        >
          <ColorRing
            height="100"
            width="100"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      ) : (
        <div>
          {/* <div className="desktopAllProductsBannerBox">
            <img src={limitedEditionBanner} />
          </div> */}
          {/* Conditionally load desktop and mobile images */}
        {/* <div className="desktopAllProductsBannerBox">
          <img src={oxidizedBanner} alt="Oxidized Banner for Desktop" />
        </div> */}
        {/* <div className="desktopAllProductsBannerBoxMobile">
          <img src={oxidizedBannerMobile} alt="Oxidized Banner for Mobile" />
        </div> */}
        {/* <img src={n} alt="Oxidized Banner for all" className="imgSmall" /> */}

        

          {/* <h1 style={{ paddingTop: "20px" }}>Oxidized Products</h1> */}
          <div className="shopPage">{allProductsAvailable()}</div>
        </div>
      )} 
      <Footer />
    </div>
  );
}
