import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import Footer from "./Footer";
import "../Components Styles/Footer.css";
export default function ShippingPolicy() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox termsMain">
        <h1>Shipping Policy</h1>
        <div className="terms-items">
          <p>
            1. Shipping and Delivery charges may be applicable according to
            regions. All orders are dispatched as soon as possible after payment
            confirmation, and delivery time may vary depending on selected
            design and location. We are not responsible for any delay in
            delivery caused by the courier company or any other external
            factors.
          </p>
          <p>
            2. Within the order confirmation email will be a tentative delivery
            date/time. As soon as your order is prepared for dispatch, an
            estimation will be given. Alternatively, logged-in users can click
            Track Order to view the status of their order and track it. You can
            always contact us for any more details.
          </p>

          <p>
            3. Please note that our estimated delivery time (domestic) ranges
            from 1 day [minimum] to 15 days [maximum] depending on your
            location, availability of the designs, and chosen shipping option.
          </p>
          <p>
            4. We maintain detailed proof of product contents for each shipment.
            Complaints of non-delivery, when our records show successful
            delivery, will not be considered. We are committed to transparent
            and reliable service.
          </p>
          <p>
            5. <strong>PLEASE NOTE:</strong> Customer needs to produce any of
            the following Government issued photo identity proof for receiving
            the delivery or the OTP on registered phone number:
          </p>
          <ul>
            <li style={{ listStyle: "none" }}>a. Pan card</li>
            <li style={{ listStyle: "none" }}>b. Driving License</li>
            <li style={{ listStyle: "none" }}>c. Passport</li>
            <li style={{ listStyle: "none" }}>d. Voter identification card</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
