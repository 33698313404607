import img1 from "../../Images/Dummy Images/img1.jpg";
import img2 from "../../Images/Dummy Images/img2.jpg";
import img3 from "../../Images/Dummy Images/img3.jpg";
import img4 from "../../Images/Dummy Images/img4.jpg";

export default [
  {
    title: "First Slider Image",
    description: "This is the First Slider Image",
    urls: img1,
    path: "/shoppage",
  },
  {
    title: "Second Slider Image",
    description: "This is the Second Slider Image",
    urls: img2,
    path: "/edition/Limited Edition",
  },
  {
    title: "Third Slider Image",
    description: "This is the Third Slider Image",
    urls: img3,
  },
  {
    title: "Fourth Slider Image",
    description: "This is the Fourth Slider Image",
    urls: img4,
  },
];
