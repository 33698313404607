import React from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { a38, a45, a49, a56, s3 } from "../../Api/RootApiPath";
import { TbTruckDelivery } from "react-icons/tb";

export default function AdminOrderDetails() {
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfLoading2, setPdfLoading2] = useState(false);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  const { id } = useParams();
  console.log(id);

  const navigate = useNavigate();
  const orderId = parseInt(id);
  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    purityRate,
    id,
    phuid
  ) => {
    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;
    let grossTotalRate = 1;

    if (mrp !== "" && mrp !== 0) {
      grossTotalRate = mrp;
    }

    grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount +
      phuid;
    // console.log(id, making_Fixed_Wastage);
    let GSTAdded = GST * grossTotalRate;
    let finalPrice = (grossTotalRate + GSTAdded).toFixed(0);

    if (mrp !== "" && mrp !== 0) {
      GSTAdded = GST * mrp;
      finalPrice = (mrp + GSTAdded).toFixed(0);
    }
    return finalPrice;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrderItems();
  }, []);
  const fetchOrderItems = async () => {
    try {
      const response = await fetch(a49, {
        method: "POST",
        body: JSON.stringify({ OrderId: id }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setAllOrders(data.data);
      setLoading(false);
      console.log(data.data, "Order Items Fetched");
      // orderTracker(data.data[0].tblOrder.orderStatus);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const requiredOrder = allOrders.filter((x) => x.id === orderId);
  useEffect(() => {
    if (!loading) {
      console.log(allOrders[0].tblOrder.orderStatus);
      orderTracker(allOrders[0].tblOrder.orderStatus);
    }
  }, [orderId]);

  let progress = 0;
  const orderTracker = (status) => {
    console.log(status, "status");
    if (status === "Payment Pending") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${10}%`,
                backgroundColor: "rgb(219, 153, 30)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Pending</p>
            <p>Paid</p>
            <p>Shipped</p>
            <p>Delivered</p>
          </div>
        </div>
      );
    } else if (status === "Paid") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${40}%`,
                backgroundColor: "rgba(0, 128, 0, 0.7)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Pending</p>
            <p>Paid</p>
            <p>Shipped</p>
            <p>Delivered</p>
          </div>
        </div>
      );
    } else if (status === "Shipped") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${70}%`,
                backgroundColor: "rgb(77, 155, 228)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Pending</p>
            <p>Paid</p>
            <p>Shipped</p>
            <p>Delivered</p>
          </div>
        </div>
      );
    } else if (status === "Delivered") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${100}%`,
                backgroundColor: "rgb(159, 77, 206)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Pending</p>
            <p>Paid</p>
            <p>Shipped</p>
            <p>Delivered</p>
          </div>
        </div>
      );
    } else if (status === "Payment Failed") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${10}%`,
                backgroundColor: "rgb(231, 30, 60)",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Failed</p>
          </div>
        </div>
      );
    } else if (status === "Payment Processing") {
      return (
        <div className="verticalOrderProgeressBox">
          <div className="verticalOrderProgeressBar">
            <div
              style={{
                height: `${10}%`,
                backgroundColor: "orange",
              }}
              className="verticalOrderProgeressBarProgress"
            ></div>
          </div>
          <div className="verticalOrderProgeressBarStatus">
            <p>Payment Procesing</p>
          </div>
        </div>
      );
    }
  };

  function showPDFWithId(id) {
    fetch(a45, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        setPdfLoading(false);
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  function showDetailedPDFWithId(id) {
    fetch(a56, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        setPdfLoading2(false);
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  return (
    <div className="allCustomersTableMain">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState
            ? "adminContentbox allCustomersTable"
            : "adminContentbox adminContentbox2 allCustomersTable"
        }
      >
        <h2 style={{ marginBottom: "3rem", marginTop: "1rem" }}>
          Order Details
        </h2>
        {!loading && allOrders.length > 0 ? (
          <div>
            <div className="adminOrderDetailsOuterBox1">
              <div className="adminOrderDetailsTrackingBox">
                <h4 style={{ marginBottom: "30px" }}>Track Order</h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "10px 0",
                  }}
                >
                  <h4>S.no</h4>
                  <h4>Order Number</h4>
                  <h4>Tracking Id</h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{allOrders[0].id}</p>
                  <p>{allOrders[0].orderNumber}</p>
                  <p>Tracking Id</p>
                </div>
                {orderTracker(allOrders[0].tblOrder.orderStatus)}
              </div>
              <div className="adminOrderDetailsItemsMainBox">
                <h4 style={{ marginBottom: "10px" }}>Items from Order</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Size</th>
                      <th>ItemCode</th>
                      {/* <th>Price</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {allOrders.map((orderItem, index) => {
                      const phuid =
                        orderItem.phuid === "" || isNaN(orderItem.phuid)
                          ? 0
                          : parseFloat(orderItem.phuid);
                      const netWt =
                        orderItem.netWt === "" || isNaN(orderItem.netWt)
                          ? 0
                          : parseFloat(orderItem.netWt);
                      const makingPerGram =
                        orderItem.making_per_gram === "" ||
                        isNaN(orderItem.making_per_gram)
                          ? 0
                          : parseInt(orderItem.making_per_gram);
                      const makingPercentage =
                        orderItem.making_Percentage === "" ||
                        isNaN(orderItem.making_Percentage)
                          ? 0
                          : parseInt(orderItem.making_Percentage);
                      const makingFixedAmt =
                        orderItem.making_Fixed_Amt === "" ||
                        isNaN(orderItem.making_Fixed_Amt)
                          ? 0
                          : parseInt(orderItem.making_Fixed_Amt);
                      const makingFixedWastage =
                        orderItem.making_Fixed_Wastage === "" ||
                        isNaN(orderItem.making_Fixed_Wastage)
                          ? 0
                          : parseFloat(orderItem.making_Fixed_Wastage);
                      const stoneAmount =
                        orderItem.stoneAmount === "" ||
                        isNaN(orderItem.stoneAmount)
                          ? 0
                          : parseInt(orderItem.stoneAmount);

                      const mrp =
                        orderItem.mrp === "" || isNaN(orderItem.mrp)
                          ? 0
                          : parseInt(orderItem.mrp);
                      const purityRate =
                        orderItem.purityRate === "" || isNaN(orderItem.rate)
                          ? 0
                          : parseInt(orderItem.rate);

                      return (
                        <tr key={index}>
                          <td
                            onClick={() =>
                              navigate(
                                `/admin-productdetails?productId=${orderItem.productId}`
                              )
                            }
                          >
                            <p>{orderItem.productName}</p>
                          </td>
                          <td>
                            <img
                              onClick={() =>
                                navigate(
                                  `/admin-productdetails?productId=${orderItem.productId}`
                                )
                              }
                              className="adminOrderDetailsItemsproductImage"
                              src={`${s3}/${orderItem.images.split(",").pop()}`}
                              alt="Product Image"
                            />
                          </td>
                          <td>
                            <p>{orderItem.quantity}</p>
                          </td>
                          <td>
                            <p>{orderItem.size}</p>
                          </td>
                          <td>
                            <p>{orderItem.itemCode}</p>
                          </td>
                          {/* <td><p>₹{orderItem.rate}</p></td> */}
                          {/* <td>
                            ₹
                            {calculateFinalPrice(
                              netWt,
                              makingPerGram,
                              makingPercentage,
                              makingFixedAmt,
                              makingFixedWastage,
                              stoneAmount,
                              mrp,
                              purityRate,
                              orderItem.id,
                              phuid
                            )}
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="4">
                        <p>Sub Total</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p>₹{allOrders[0].tblOrder.price}</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p>Shipping Charge</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p>₹0</p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p>Total</p>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <p>₹{allOrders[0].tblOrder.price}</p>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="adminOrderDetailsOuterBox2">
              <div className="adminOrderDetailsInfoBox">
                <h4>Shipping Information</h4>
                <h4>{`${allOrders[0].tblCustomerDetails.firstName}  ${allOrders[0].tblCustomerDetails.lastName}`}</h4>
                <p>
                  Delivery Address:{" "}
                  {`${allOrders[0].tblCustomerDetails.currAddStreet}  ${allOrders[0].tblCustomerDetails.currAddTown} ${allOrders[0].tblCustomerDetails.currAddState}  ${allOrders[0].tblCustomerDetails.currAddPinCode}`}
                </p>
                <p>Mobile: {allOrders[0].tblCustomerDetails.mobile}</p>
                <p>Email: {allOrders[0].tblCustomerDetails.email}</p>
              </div>
              <div className="adminOrderDetailsInfoBox">
                <h4>Billing Information</h4>
                <p>Payment type: {allOrders[0].paymentMode}</p>
                <p>
                  Billing Address:{" "}
                  {`${allOrders[0].tblCustomerDetails.perAddStreet}  ${allOrders[0].tblCustomerDetails.perAddTown} ${allOrders[0].tblCustomerDetails.perAddState}  ${allOrders[0].tblCustomerDetails.perAddPinCode}`}
                </p>
                <p>Invoice no: {allOrders[0].invoiceNo}</p>
                <button
                  className={
                    pdfLoading
                      ? "adminOrderDetailsPdfButton2"
                      : "adminOrderDetailsPdfButton"
                  }
                  onClick={() => {
                    showPDFWithId(allOrders[0].orderId), setPdfLoading(true);
                  }}
                >
                  Invoice pdf
                </button>
                <button
                  style={{ marginLeft: "20px" }}
                  className={
                    pdfLoading2
                      ? "adminOrderDetailsPdfButton2"
                      : "adminOrderDetailsPdfButton"
                  }
                  onClick={() => {
                    showDetailedPDFWithId(allOrders[0].orderId),
                      setPdfLoading2(true);
                  }}
                >
                  Invoice Details pdf
                </button>
              </div>
              <div className="adminOrderDetailsInfoBox">
                <h4>Delivery Information</h4>
                <div className="adminOrderDetailsInfoDeliveryBox">
                  <TbTruckDelivery style={{ color: "grey" }} size={"30px"} />
                  <h4>Blue Dart</h4>
                  <p>OrderId: {allOrders[0].orderNumber}</p>
                  <p>Payment Mode: {allOrders[0].paymentMode}</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
