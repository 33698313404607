import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useLocation } from "react-router-dom";
import { a17, a24, s1, a26, s3 } from "../../Api/RootApiPath";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import QRCode from "qrcode";
import MyPDF from "./AdminComponent/AdminProductLabel";

export default function AdminProductDetails() {
  const [data, setData] = useState("");
  const [popUp, setPopup] = useState(false);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [placeHolder, setPlaceHolder] = useState("");
  const [loading, setLoading] = useState(false);
  const [qr, setQr] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  let Entryby_Staff_id = parseInt(adminLoggedIn);
  console.log(Entryby_Staff_id);
  const location = useLocation();
  let allImages = "";
  let params = "";
  params = new URLSearchParams(location.search);
  let productId = "NA";
  productId = params.get("productId");
  const searchProduct = async () => {
    // const formData = new FormData();
    // formData.append("Product_id", productId);
    const formData = {
      Id: productId,
    };
    try {
      const response = await fetch(a17, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // if (data.status == "success") {
      //   setData(data.data);
      // } else {
      //   console.error(data.error);
      // }
      setData(data.data);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
    }
  };
  if (data != "") {
    allImages = data.images.split(",");
  } else allImages = "No Data Found";
  const updatedetails = (entry) => {
    setPopup(true);
    if (entry === "image") {
      setPopup("imageRequested");
    }
  };
  const updatedetailsBox = async (parameter) => {
    const formData = {
      Id: productId,
      [parameter]: formValue,
    };
    // const formData = new FormData();
    // formData.append("Product_id", productId);
    // formData.append("FieldName", parameter);
    // formData.append("FieldValue", formValue);
    try {
      const response = await fetch(a24, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // if (data.status == "success") {
      //   console.log(data);
      //   setPopup(false);
      //   alert(`${parameter} Changed Successfully`);
      // } else {
      //   console.error(data.error);
      // }
      // setLoading(false);
      // setPopup(false);
      // alert(`${parameter} Changed Successfully`);
      updateStaffId();
    } catch (error) {
      console.error(error);
    }
  };
  const updateStaffId = async () => {
    const formData = {
      Id: productId,
      Entryby_Staff_id: Entryby_Staff_id,
    };
    // const formData = new FormData();
    // formData.append("Product_id", productId);
    // formData.append("FieldName", parameter);
    // formData.append("FieldValue", formValue);
    try {
      const response = await fetch(a24, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // if (data.status == "success") {
      //   console.log(data);
      //   setPopup(false);
      //   alert(`${parameter} Changed Successfully`);
      // } else {
      //   console.error(data.error);
      // }
      setLoading(false);
      setPopup(false);
      alert(`${parameter} Changed Successfully`);
    } catch (error) {
      console.error(error);
    }
  };
  const updateImagesBox = async (parameter) => {
    // const formData = {
    //   [parameter]: formValue,
    // };
    const formData = new FormData();
    formData.append("Images", formValue);
    // formData.append("Product_id", productId);
    // formData.append("FieldName", parameter);
    // formData.append("FieldValue", formValue);
    try {
      const response = await fetch(`${a26}/${productId}`, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "PUT",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(formData),
        body: formData,
      });
      const data = await response.json();
      // if (data.status == "success") {
      //   console.log(data);
      //   setPopup(false);
      //   alert(`${parameter} Changed Successfully`);
      // } else {
      //   console.error(data.error);
      // }
      console.log(data);
      setPopup(false);
      alert(`${parameter} Changed Successfully`);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleFileInputChange = (event) => {
  //   const files = event.target.files;
  //   if (files.length > 5) {
  //     alert("You can select up to 5 files.");
  //     event.target.value = null; // Reset the file input
  //     return;
  //   }

  //   // Handle the selected files as desired
  //   else setFormValue(files);
  //   console.log(formValue);
  //   // updateImagesBox(parameter);
  // };
  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };
  const handleFileSubmit = async () => {
    event.preventDefault();

    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });

    try {
      const response = await fetch(`${a26}/${productId}`, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        // Files uploaded successfully
        // setLoading(false);
        // console.log("Files uploaded successfully.");
        // setPopup(false);
        // alert(`${parameter} Changed Successfully`);
        updateStaffId();
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    searchProduct();
  }, [popUp]);

  useEffect(() => {
    QRCode.toDataURL(data.itemCode).then(setQr);
  }, [data]);

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        <h2>Product details</h2>
        {/* <MyPDF
          name={data.product_Name}
          grossWt={data.grosswt}
          stoneWt={data.stoneWeight}
          netWt={data.netWt}
          itemCode={data.itemCode}
          purity={data.purity}
          pieces={data.pieces}
          mrp={data.mrp}
          product_No={data.product_No}
        /> */}
        <MyPDF data={data} />
        <div className="adminProductDetailsMainBox" style={{ height: "70vh" }}>
          <div className="adminProductDetailImageBox">
            <img
              style={{ width: "300px", cursor: "pointer" }}
              // src={`${s1}${data.ImageList1}`}
              // src={`${s1}${data.imageList1}`}
              // src={`${s3}${data.images}`}
              src={`${s3}/${allImages[0]}`}
              alt="images"
              onClick={() => {
                setPlaceHolder("Add Image");
                setParameter("Images");
                updatedetails("image");
              }}
            />
          </div>
          <div className="adminProductDetailDetailsBox">
            <h3>Last Modified By : {data.entryby_Staff_id}</h3>
            <h2
              onClick={() => {
                setPlaceHolder(data.product_Name);
                setParameter("Product_Name");
                updatedetails();
              }}
              style={{ margin: "1rem 0" }}
            >
              {data.product_Name}
            </h2>
            <p
              onClick={() => {
                setPlaceHolder(data.description);
                setParameter("description");
                updatedetails();
              }}
              style={{ lineHeight: "1.5rem", margin: "2rem 0" }}
            >
              {data.description}
            </p>
            <div className="adminProductDetailDetailsBoxItems">
              <p
                onClick={() => {
                  setPlaceHolder(data.size);
                  setParameter("Size");
                  updatedetails();
                }}
              >
                Size: {data.size == "" ? "0" : data.size}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.netWt);
                  setParameter("NetWt");
                  updatedetails();
                }}
              >
                Net Wt: {data.netWt}gm
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.grosswt);
                  setParameter("grosswt");
                  updatedetails();
                }}
              >
                Gross Wt: {data.grosswt}gm
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.purity);
                  setParameter("purity");
                  updatedetails();
                }}
              >
                Purity: {data.purity}
              </p>
            </div>
            <div className="adminProductDetailDetailsBoxItems">
              <p
                onClick={() => {
                  setPlaceHolder(data.stoneWeight);
                  setParameter("StoneWeight");
                  updatedetails();
                }}
              >
                Stone Weight: {data.stoneWeight}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.stoneAmount);
                  setParameter("StoneAmount");
                  updatedetails();
                }}
              >
                Stone Amount: {data.stoneAmount}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.makingtype);
                  setParameter("Makingtype");
                  updatedetails();
                }}
              >
                Making Type: {data.makingtype}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.makingcharges);
                  setParameter("Makingcharges");
                  updatedetails();
                }}
              >
                Making Charges: {data.makingcharges}
              </p>
            </div>
            <div className="adminProductDetailDetailsBoxItems">
              <p
                onClick={() => {
                  setPlaceHolder(data.product_type);
                  setParameter("product_type");
                  updatedetails();
                }}
              >
                Product Type: {data.product_type}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.collection);
                  setParameter("collection");
                  updatedetails();
                }}
              >
                Collection: {data.collection}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.occasion);
                  setParameter("occasion");
                  updatedetails();
                }}
              >
                Occassion: {data.occasion}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.gender);
                  setParameter("gender");
                  updatedetails();
                }}
              >
                Gender: {data.gender}
              </p>
            </div>
            <div className="adminProductDetailDetailsBoxItems">
              <p
                onClick={() => {
                  setPlaceHolder(data.featured);
                  setParameter("Featured");
                  updatedetails();
                }}
              >
                Featured: {data.featured}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.statusType);
                  setParameter("StatusType");
                  updatedetails();
                }}
              >
                Status: {data.statusType}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.pieces);
                  setParameter("Pieces");
                  updatedetails();
                }}
              >
                Pieces: {data.pieces}
              </p>
              <p
                onClick={() => {
                  setPlaceHolder(data.mrp);
                  setParameter("mrp");
                  updatedetails();
                }}
              >
                MRP: {data.mrp}
              </p>
              <p>Item Code: {data.itemCode}</p>
            </div>
            <img style={{ width: "120px" }} src={qr} />
          </div>
          <div
            className={
              popUp == true ? "updateAccountDetailsPopupMainBox" : "none"
            }
          >
            <div className="updateAccountDetailsPopupCloseBtn">
              <AiOutlineClose size={"3rem"} onClick={() => setPopup(false)} />
            </div>
            <h1 style={{ color: "rgba(0,0,0,0.5)" }}>Change {parameter} </h1>
            <input
              placeholder={placeHolder}
              type="text"
              onChange={(e) => setFormValue(e.target.value)}
            />
            <div
              style={{ height: "70px", marginBottom: "1rem" }}
              className={loading == true ? "loading" : "none"}
            >
              <InfinitySpin width="150" color="#4fa94d" />
            </div>
            <button
              onClick={() => {
                updatedetailsBox(parameter), setLoading(true);
              }}
            >
              Submit
            </button>
          </div>

          {/* Image requested */}
          <div
            className={
              popUp == "imageRequested"
                ? "updateAccountDetailsPopupMainBox"
                : "none"
            }
          >
            <div className="updateAccountDetailsPopupCloseBtn">
              <AiOutlineClose size={"3rem"} onClick={() => setPopup(false)} />
            </div>
            <h1 style={{ color: "rgba(0,0,0,0.5)" }}>Change {parameter} </h1>
            <input
              placeholder={placeHolder}
              type="file"
              multiple
              max="5"
              // onChange={(e) => setFormValue(e.target.value)}
              // onChange={(e) => handleFileInputChange(e)}
              onChange={handleFileInputChange}
            />
            <div
              style={{ height: "70px", marginBottom: "1rem" }}
              className={loading == true ? "loading" : "none"}
            >
              <InfinitySpin width="150" color="#4fa94d" />
            </div>
            <p>{selectedFiles.length} out of 5 images selected</p>
            {/* <button onClick={() => updateImagesBox(parameter)}>Submit</button> */}
            <button
              onClick={() => {
                handleFileSubmit(parameter), setLoading(true);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        {/* <div className="newBox2"> */}
        {/* <ProductDetails images={fakeImages} /> */}
        {/* </div> */}
        <div className={popUp === true ? "new" : "new2"}></div>
      </div>
    </div>
  );
}
