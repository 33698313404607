import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { a1, a4 } from "../../Api/RootApiPath";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../AlertMessages/AlertMessage";

export default function AdminAddCustomer() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [emailCheckBox, setEmailCheckBox] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  let password = "User@123";
  const navigate = useNavigate();
  useEffect(() => {
    fetch(a1)
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        setData(response.data);
        setLoading(false);
      });
  }, [loading]);

  const handleName = (e) => {
    setFirstName(e.target.value);
    setEmail(
      `${e.target.value.toLowerCase()}${
        data[data.length - 1].id + 1
      }@example.com`
    );
  };
  const handleEmailChange = (e) => {
    if (emailCheckBox === false) {
      setEmail(e.target.value);
    } else {
      setEmail(`${firstName}${data[data.length - 1].id + 1}@example.com`);
    }
  };

  const setAlertNone = () => {
    setTimeout(() => {
      setAlertType("");
    }, 1500);
  };
  const checkFormSubmit = () => {
    const formData = {
      firstName: firstName,
      lastName: "",
      email: email,
      password: password,
      mobile: mobile,
    };
    console.log(formData);
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      firstName: firstName,
      lastName: "",
      email: email,
      password: password,
      mobile: mobile,
    };
    try {
      const response = await fetch(a4, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.message === "email already exist") {
        setAlertType("exist");
        setAlertNone();
        // setAlertType("Email Already Exists, Try Login or use another email");
      } else {
        setAlertType("success");
        setFirstName("");
        setMobile("");
        setEmail("");
        setState("");
        setEmailCheckBox(false);
        setAlertNone();
      }
    } catch (error) {
      console.error(error);
      setAlertType("fail");
      setAlertNone();
    }
  };
  return (
    <div className="allCustomersTableMain">
      {alertType === "exist" ? (
        <AlertMessage
          message="Email Already Exists, Try Login or use another email!"
          type="error"
        />
      ) : null}
      {alertType === "fail" ? (
        <AlertMessage message="Server Error !" type="error" />
      ) : null}
      {alertType === "success" ? (
        <AlertMessage message="Successfully Added !" type="success" />
      ) : null}
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState
            ? "adminContentbox allCustomersTable"
            : "adminContentbox adminContentbox2 allCustomersTable"
        }
      >
        {/* <h2 style={{ marginBottom: "0rem", marginTop: "1rem" }}>
          Add Customer
        </h2> */}
        <div
          style={{ flexDirection: "column" }}
          className="adminAddCategoryMainbox"
        >
          <button
            style={{
              width: "200px",
              alignSelf: "flex-start",
              margin: "2rem",
              marginBottom: "0",
            }}
            onClick={() => {
              navigate("/admin-allcustomers");
            }}
          >
            Back To Customers List
          </button>
        </div>
        <div className="adminAddCategoryMainbox">
          {!loading ? (
            <form onSubmit={formSubmit}>
              <h3>ADD CUSTOMER</h3>
              <label>Customer Code</label>
              <input
                value={data.length > 0 ? data[data.length - 1].id + 1 : ""}
                readOnly
              />
              <label htmlFor="firstName">
                Name <sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                type="text"
                id="firstName"
                required="required"
                value={firstName}
                onChange={(e) => {
                  handleName(e);
                }}
              />
              <label htmlFor="mobile">
                Contact number <sup style={{ color: "red" }}>*</sup>
              </label>
              <input
                type="number"
                id="mobile"
                required="required"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
              <label htmlFor="state">
                State <sup style={{ color: "red" }}>*</sup>
              </label>
              <select
                id="state"
                required="required"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option value="">Select a state</option>
                <option key={state} value="Maharashtra">
                  Maharashtra
                </option>
              </select>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                }}
              >
                <input
                  id="emailCheckBox"
                  type="checkbox"
                  value={emailCheckBox}
                  onChange={(e) => {
                    setEmailCheckBox(!emailCheckBox), handleEmailChange(e);
                  }}
                  style={{
                    marginLeft: "0px",
                    width: "20px",
                  }}
                />
                <label
                  style={{ marginInline: "0", paddingInline: "0" }}
                  htmlFor="emailCheckBox"
                >
                  Enter Manual Email
                </label>
              </div>
              {emailCheckBox ? (
                <input
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              ) : null}

              <button
                // onClick={() => {
                //   checkFormSubmit();
                // }}
                type="submit"
                style={{ width: "90%", margin: "40px 0" }}
              >
                Add User
              </button>
            </form>
          ) : (
            <p>Loading</p>
          )}
        </div>
      </div>
    </div>
  );
}
