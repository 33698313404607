import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
// import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Autoplay, Keyboard, Pagination, Navigation, EffectFade } from "swiper";
// import img1 from "../Images/Caraousal_Images/Trending1.jpg";
import img2 from "../Images/Caraousal_Images/Usp2.jpg";
import img1 from "../Images/Caraousal_Images/Usp1.jpg";
import "swiper/css";
// import img2 from "../../Images/Caraousal_Images/Trending2.jpg";

// import img5 from "../../Images/Caraousal_Images/Trending5.jpg";
import "swiper/css";
import { useNavigate } from "react-router-dom";

export default function UspPoints() {
  const navigate = useNavigate();

  return (
    <div>
      {/* <UspPoints /> */}
      {/* <img className="whyUsImage" src={whyUsImage} alt="WHY US" /> */}
      <Swiper
        // spaceBetween={30}
        centeredSlides={true}
        effect={"fade"}
        speed={1300}
        // keyboard={{
        //   enabled: true,
        // }}
        autoplay={{
          // delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[EffectFade, Autoplay, Navigation]}
        className="customerComments UspCaraousal"
      >
        <SwiperSlide className="uspPointImages">
          <div>
            <img className="uspPointImages" src={img1} alt={"Category name"} />
          </div>
        </SwiperSlide>
        <SwiperSlide className="uspPointImages">
          <div>
            <img className="uspPointImages" src={img2} alt={"Category name"} />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
