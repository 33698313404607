import React from "react";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import { Dna } from "react-loader-spinner";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { a49 } from "../../Api/RootApiPath";
import { useState } from "react";

export default function ValidatePaymentPage() {
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [custId, setCustId] = useState("");
  useEffect(() => {
    // This code will run once after the initial render
    setTimeout(() => {
      fetchOrderItems();
    }, 3000);
  }, []);

  const { id } = useParams();
  console.log(id);
  const navigate = useNavigate();
  const orderId = parseInt(id);
  const fetchOrderItems = async () => {
    try {
      const response = await fetch(a49, {
        method: "POST",
        body: JSON.stringify({ OrderId: id }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setPaymentStatus(data.data);
      setCustId(data.data[0].customer_Id);
      setLoading(false);
      if (data.data[0].tblOrder.orderStatus === "Payment Failed") {
        navigate(`/paymentfailedpage?custId=${data.data[0].customer_Id}`);
      } else if (data.data[0].tblOrder.orderStatus === "Paid") {
        navigate(`/paymentsuccesspage?custId=${data.data[0].customer_Id}`);
      } else if (data.data[0].tblOrder.orderStatus === "Payment Processing") {
        console.log("It is Pending");
      } else {
        navigate(`/customerprofile?customerId=${data.data[0].customer_Id}`);
      }
      console.log(data.data, "Order Items Fetched");
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="accountDetailsMainBox">
        <div style={{ width: "40vw" }} className="accountDetailsFormBox">
          <h2 style={{ margin: "1rem 0" }}>Please Wait...</h2>

          <p style={{ margin: "2rem 0", alignSelf: "flex-start" }}>
            <strong>Validating Payment</strong>
          </p>
          <p style={{ margin: "2rem 0", alignSelf: "flex-start" }}></p>
          <div
            style={{ height: "100px", marginBottom: "2rem" }}
            className="loading"
          >
            <Dna className="loading" width="150" color="#4fa94d" />
          </div>
          <p>
            <div className="payentPagePayBtnBox"></div>
          </p>
          <div className="clear"></div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
