import React from "react";
import { Link } from "react-router-dom";
import ComingSoonImage from "../../CominSoonImage/ComingSoonImage";
import { useState } from "react";

export default function BestDesign() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="bestDesignMain">
      <Link
        className="bestDesignItems bestDesignItems1"
        to={`/gender/${"male"}/${"chain"}/silver`}
      >
        <div></div>
      </Link>
      <Link
        className="bestDesignItems bestDesignItems2"
        to={`/gender/${"ladies"}/${"chain"}/silver`}
      >
        <div></div>
      </Link>
      {/* <Link
        style={{
          cursor: "default",
          position: "relative",
          display: "inline-block",
        }}
        className="bestDesignItems bestDesignItems3"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "black",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
              fontSize: "14px",
              zIndex: "5",
            }}
          >
            Coming Soon
          </div>
        )}
      </Link> */}
      <Link
        className="bestDesignItems bestDesignItems3"
        to="/metal/silver/ring"
      >
        <div></div>
      </Link>
      <Link
        className="bestDesignItems bestDesignItems4"
        to="/category/bracelet"
      >
        <div></div>
      </Link>
    </div>
  );
}
