import React, { useEffect, useRef } from "react";
import "../PagesStyles/AboutUs.css";
import banner from "../../Images/goldenStoryBanner.png";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import divider from "../../Images/divider.png";
import img1 from "../../Images/familyPic1.jpg";
import img2 from "../../Images/familyPic2.jpg";
import blank from "../../Images/blankProduct.jpg";
import leftCorner from "../../Images/upLeftCorner.png";
import rightCorner from "../../Images/bottomRightCorner.png";
// import { RiDoubleQuotesL, RiTruckLine, RiHandCoinLine } from "react-icons/ri";
// import { BiSupport } from "react-icons/bi";
import { AiTwotoneCalendar, AiOutlineSafetyCertificate } from "react-icons/ai";
import { BsTruck } from "react-icons/bs";
import { FaExchangeAlt } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import indiaBullionLogo from "../../Images/indiaBullionLogo.png";
import macciaLogo from "../../Images/macciaLogo.png";
import gjfLogo from "../../Images/gjfLogo.png";
import { useSelector } from "react-redux";
import goldenStoryLogo from "../../Images/goldenStoryLogo.png";
import goldenStoryRightBorder from "../../Images/goldenBorderRight.png";
import goldenStoryLeftBorder from "../../Images/goldenBorderLeft.png";
import goldenStoryHandLabour from "../../Images/goldenStoryHandLabour.png";
import aboutUsParaBorder from "../../Images/aboutUsParaBorder.png";
import aboutUsParaBorder2 from "../../Images/aboutUsParaBorder2.png";
import AboutUsCarousal from "./AboutUsCarousal";

export default function AboutUs() {
  // const sectionRef = useRef(null);
  // const sectionRef2 = useRef(null);
  const index = useSelector((state) => state);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
    AOS.init({ duration: 1000 });
  }, []);
  // useEffect(() => {
  //   const hash = window.location.hash;
  //   if (hash && sectionRef.current) {
  //     sectionRef.current.scrollIntoView();
  //   }
  // }, []);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, []);
  // console.log(index);
  const para1 =
    "M.K. Ghare is one of the oldest and trusted jeweller in Mumbai. Back in the year 1933, A very talented goldsmith, Mr. Mahadev KrishnaGhare, planted the seeds for what now is known as, M.K. Ghare Jewellers. The hard work done by each generation has made it expand into various retail outlets across Mumbai.";
  const para2 =
    "One of the major reasons behind customers favor Ghare’s is the strong base that was built by the ancestors through maintaining quality, purity and giving the best service to their customers. These values have always been respected throughout every generation.";
  return (
    <div className="outer">
      <div className="headingBox" id="section1">
        <Heading />
      </div>
      <div className="aboutUsMainBox">
        <div className="aboutUSGoldenStoryHeading">
          <img
            className="aboutUsGoldenStoryBorderLeft"
            src={goldenStoryLeftBorder}
            alt="border"
          />
          <img
            className="aboutUsGoldenStoryBorderRight"
            src={goldenStoryRightBorder}
            alt="border"
          />
          <img
            className="aboutUsGoldenStoryLogo"
            src={goldenStoryLogo}
            alt="Golden Story Logo"
          />
        </div>
        {/* <h1>The Golden Story</h1> */}
        <img
          className="bannerBox"
          src={banner}
          alt="AbouUsBanner"
          data-aos="fade-down"
        />
        {/* <div className="bannerBox"></div> */}
        <h3 className="aboutUsFirstPara">
          M.K. Ghare stands as an esteemed and revered jeweller, gracing the
          heart of Mumbai with its timeless presence. Embarking on its journey
          in the year 1933, this esteemed establishment owes its genesis to the
          remarkable artistry of the gifted goldsmith, Mr. Mahadev Krishna
          Ghare. With a vision that transcends time, he sowed the seeds that
          have blossomed into the renowned entity we know today: M.K. Ghare
          Jewellers.
        </h3>

        <div id="section1" className="aboutUsSecondParaBox">
          <div className="aboutUsSecondParaLeft">
            {/* <h3 style={{ marginTop: "3rem" }}> */}
            <h3>
              Since its inception, the relentless dedication of each successive
              generation has propelled its growth, culminating in a tapestry of
              retail outlets strewn across the illustrious streets of Mumbai. A
              resounding testament to their unwavering commitment, the
              collective efforts of these industrious artisans have built an
              empire that exudes splendor and grace.
            </h3>
            <h3 style={{ marginTop: "3rem" }}>
              A paramount factor that engenders unwavering loyalty from
              customers is the robust foundation laid by our forefathers,
              characterized by an unwavering adherence to quality, purity, and
              an unparalleled level of service. These principles have been held
              dear and revered through the annals of time, forging an
              indomitable bond with our cherished patrons.
            </h3>
          </div>
          <div className="aboutUsSecondParaRight">
            <img
              className="aboutUsSecondParaImage"
              src={goldenStoryHandLabour}
              alt="Hand Labour Gold Image"
            />
          </div>
        </div>
        <div id="section2" className="aboutUsBrandStandMainBox">
          <h1>What our brand stands for</h1>
          <h3 style={{ color: "black" }}>
            Quality, Purity and giving the best Service to all.
          </h3>
          <img className="aboutUsParaBorder" src={aboutUsParaBorder2} />
          <div className="aboutUsBrandStandValuesBox">
            <h2 data-aos="fade-down">Quality</h2>
            <h2 data-aos="fade-up">Purity</h2>
            <h2 data-aos="fade-down">Best Service</h2>
          </div>
          <h3>
            Ghare's is the first choice for the customers when it comes to gold
            investment.
          </h3>
        </div>

        <div id="section3" className="ourInspirationOuterBox">
          <div className="ourInspirationMainBox ourInspirationMainBoxTop">
            <h1>Our Inspiration</h1>
            <img className="aboutUsParaBorder" src={aboutUsParaBorder} />
            <h3>
              Our dedicated team endeavors to ignite the spark of joy and
              celebration within our valued customers. Through ou unwavering
              commitment to creativity and innovation, we aspire to adorn the
              world with exquisite and meticulously crafted fine jewelry.
            </h3>
          </div>
        </div>
        <div className="ourInspirationOuterBox">
          <div className="ourInspirationMainBox ourInspirationMainBoxBottom">
            <h1>Our Belief</h1>
            <img className="aboutUsParaBorder" src={aboutUsParaBorder} />
            <h3>
              In the tapestry of life where uncertainty often weaves its
              intricate patterns, there exists one steadfast element that
              remains unwavering: GOLD.
            </h3>
          </div>
        </div>

        <div id="section4" className="aboutUsWhyShopMainBox">
          <h1>Why shop with us?</h1>
          <div className="aboutUsWhyShopIconsMainBox">
            <div className="aboutUsWhyShopIconsBox" data-aos="fade-up">
              <AiTwotoneCalendar className="aboutUsWhyShopIcon" />
              <h4>90 Years of Legacy</h4>
            </div>
            <div className="aboutUsWhyShopIconsBox" data-aos="fade-down">
              <BsTruck className="aboutUsWhyShopIcon" />
              <h4>Fast-Free Delivery</h4>
            </div>
            <div className="aboutUsWhyShopIconsBox" data-aos="fade-up">
              <FaExchangeAlt className="aboutUsWhyShopIcon" />
              <h4>Life-Time Exchange & Buy-Back Policy.</h4>
            </div>
            <div className="aboutUsWhyShopIconsBox" data-aos="fade-down">
              <AiOutlineSafetyCertificate className="aboutUsWhyShopIcon" />
              <h4> 100% Hall-Marked & Certified Products</h4>
            </div>
            <div className="aboutUsWhyShopIconsBox" data-aos="fade-up">
              <GiReceiveMoney className="aboutUsWhyShopIcon" />
              <h4> 15-Day Hassle-free Return</h4>
            </div>
          </div>
        </div>
        <div
          id="section5"
          className="aboutUsAffiliatesMainBox"
          style={{ marginBottom: "100px" }}
        >
          <h1>Affiliates and Associations</h1>
          <img className="aboutUsParaBorder" src={aboutUsParaBorder2} />
          <div className="aboutUsAffiliatesLogoBoxMain">
            <div className="aboutUsAffiliatesLogoBox">
              <img
                className="aboutUsAffiliatesLogo"
                src={indiaBullionLogo}
                alt="indiaBullionLogo"
                data-aos="fade-down"
              />
              <h2>India Bullion And Jewellers Association Ltd</h2>
            </div>
            <div className="aboutUsAffiliatesLogoBox">
              <img
                className="aboutUsAffiliatesLogo"
                src={macciaLogo}
                alt="indiaBillionLogo"
                data-aos="fade-up"
              />
              <h2>Maharashtra Chamber of Commerce, Industry & Agriculture</h2>
            </div>
            <div className="aboutUsAffiliatesLogoBox">
              <img
                className="aboutUsAffiliatesLogo"
                src={gjfLogo}
                alt="indiaBillionLogo"
                data-aos="fade-down"
              />
              <h2>All India Gem & Jewellery Federation</h2>
            </div>
          </div>
        </div>
        {/* <div className="aboutUsOurTeamMemberMainBox">
          <h1>Our Team Member</h1>
          <img className="aboutUsParaBorder" src={aboutUsParaBorder2} />
          <div className="aboutUsTeamMemberCarousalMainBox">
            <div className="aboutUsTeamMemberImagesBox">
              <AboutUsCarousal />
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
