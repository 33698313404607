import React, { useEffect, useState } from "react";
import "../AdminComponentStyles/AdminSideBar.css";
import { Link } from "react-router-dom";
import logo from "../../../Images/gharesNewLogo4.png";
import small from "../../../Images/gharesNewLogoSmall.png";
import { BiChevronRight, BiChevronDown } from "react-icons/bi";
import { all } from "axios";
import Dropdown from "./Dropdown";
import { useSelector } from "react-redux";
export default function AdminSideBar() {
  const categories = [
    {
      id: 1,
      name: "E-Commerce",
      icon: "<AiOutlineShoppingCart/>",
      items: [
        { id: 1, name: "Add Product", linkto: "/adminaddbulkstock" },
        { id: 2, name: "Order", linkto: "/admin-allorders" },
        { id: 3, name: "Customer", linkto: "/admin-allcustomers" },
        { id: 4, name: "Inventory", linkto: "/admin-products" },
        // { id: 2, name: "Add Product", linkto: "/admin-addproduct" },
      ],
    },
    {
      id: 2,
      name: "Master",
      items: [
        { id: 6, name: "Category", linkto: "/admin-addcategory" },
        { id: 7, name: "Product", linkto: "/admin-add_product" },
        { id: 8, name: "Collection", linkto: "/admin-addcollection" },
        { id: 9, name: "Purity", linkto: "/admin-purity" },
        { id: 10, name: "Size", linkto: "/admin-addsize" },
        { id: 11, name: "Coupon", linkto: "/admin-addcoupon" },
        { id: 12, name: "Making", linkto: "/adminhome" },
        { id: 13, name: "Box", linkto: "/adminaddbox" },
        { id: 14, name: "Supplier", linkto: "/adminaddsupplier" },
        { id: 15, name: "Employee", linkto: "/adminhome" },
        { id: 15, name: "Bank", linkto: "/adminhome" },
      ],
    },
    {
      id: 3,
      name: "Trading",
      items: [
        { id: 11, name: "Purchase", linkto: "/adminhome" },
        { id: 12, name: "Stock", linkto: "/adminhome" },
        { id: 13, name: "Invoice", linkto: "/admininvoice" },
        { id: 14, name: "Customize Order", linkto: "/adminhome" },
        { id: 15, name: "Stock Transfer", linkto: "/adminhome" },
        { id: 16, name: "Stock Verification", linkto: "/adminhome" },
      ],
    },
    {
      id: 4,
      name: "Accounts",
      items: [
        { id: 17, name: "Ledger", linkto: "/adminhome" },
        { id: 18, name: "Voucher", linkto: "/adminhome" },
        { id: 19, name: "Statement", linkto: "/adminhome" },
        { id: 20, name: "Day Book", linkto: "/adminhome" },
        { id: 21, name: "Trial Balance", linkto: "/adminhome" },
        { id: 22, name: "Profit & Loss", linkto: "/adminhome" },
        { id: 23, name: "Balance Sheet", linkto: "/adminhome" },
      ],
    },
    {
      id: 5,
      name: "Reports",
      items: [
        { id: 24, name: "Stock", linkto: "/adminhome" },
        { id: 25, name: "Sale", linkto: "/adminhome" },
        { id: 26, name: "Purchase", linkto: "/adminhome" },
        { id: 27, name: "Customer", linkto: "/adminhome" },
        { id: 28, name: "Cash", linkto: "/adminhome" },
        { id: 29, name: "Old Metal", linkto: "/adminhome" },
        { id: 30, name: "GST", linkto: "/adminhome" },
      ],
    },
    {
      id: 6,
      name: "Gold Scheme",
      items: [
        { id: 31, name: "Create", linkto: "/adminhome" },
        { id: 32, name: "Enroll", linkto: "/adminhome" },
        { id: 33, name: "Installment", linkto: "/adminhome" },
        { id: 34, name: "Collection", linkto: "/adminhome" },
        { id: 35, name: "Pending", linkto: "/adminhome" },
        { id: 36, name: "Maturity", linkto: "/adminhome" },
      ],
    },
    {
      id: 7,
      name: "Loan",
      items: [
        { id: 37, name: "Issue", linkto: "/adminhome" },
        { id: 38, name: "Receipt", linkto: "/adminhome" },
        { id: 39, name: "Capital", linkto: "/adminhome" },
      ],
    },
    {
      id: 8,
      name: "CRM",
      items: [
        { id: 40, name: "Dashbord", linkto: "/adminhome" },
        { id: 41, name: "Contacts", linkto: "/adminhome" },
        { id: 42, name: "Opportunities", linkto: "/adminhome" },
        { id: 43, name: "Leads", linkto: "/adminhome" },
        { id: 44, name: "Customers", linkto: "/adminhome" },
      ],
    },
    {
      id: 9,
      name: "Settings",
      items: [
        { id: 45, name: "Rates", linkto: "/admin-dailyrates" },
        { id: 46, name: "Offers", linkto: "/adminhome" },
        { id: 47, name: "Discount", linkto: "/adminhome" },
        { id: 48, name: "Points", linkto: "/adminhome" },
        { id: 49, name: "Company", linkto: "/adminhome" },
        { id: 50, name: "Branch", linkto: "/adminhome" },
        { id: 51, name: "Year", linkto: "/adminhome" },
        { id: 52, name: "User", linkto: "/adminhome" },
      ],
    },
    // Add more categories as needed
  ];
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  return (
    <div className="sideBarMainBox">
      <div className="sideBarLogoBox">
        <Link to="/adminhome">
          {sidebarState ? (
            <img className="adminSideBarLogo" src={logo} />
          ) : (
            <img className="adminSideBarLogoSmall" src={small} />
          )}
        </Link>
      </div>
      {/* <Link to="/adminhome">
        <h3 style={{ marginBottom: "2rem" }}>Dashboard</h3>
      </Link> */}
      {/* <Link to="/adminhome">
        <li>Dashboard</li>
      </Link> */}
      <Dropdown categories={categories} />

      {/* <Link to="/admin-dailyrates">
        <li>Daily Rates</li>
      </Link> */}
    </div>
  );
}
