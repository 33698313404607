import React from "react";
import jsPDF from "jspdf";
import { useState } from "react";
import QRCode from "qrcode";
import { useEffect } from "react";

const MyPDF = ({
  name,
  grossWt,
  stoneWt,
  netWt,
  itemCode,
  purity,
  pieces,
  product_No,
  mrp,
  data,
}) => {
  const [qr, setQr] = useState("");
  const [productData, setProductData] = useState(data);

  // const data = {
  //   format: [26, 12],
  //   orientation: "landscape",
  //   name: name,
  //   grossWt: grossWt,
  //   stoneWt: stoneWt,
  //   netWt: netWt,
  //   itemCode: itemCode,
  //   purity: purity,
  //   fontSize: 5, //
  //   imageHeight: 8,
  //   imageWidth: 8,
  // };
  useEffect(() => {
    QRCode.toDataURL(data.itemCode).then(setQr);
  }, [itemCode]);
  const generatePDF = () => {
    const doc = new jsPDF({
      format: data.format,
      orientation: data.orientation,
    });
    doc.setFontSize(data.fontSize);
    // doc.text(`${data.name.substr(0, 26)}`, 1, 3);
    doc.text(`G.Wt: ${data.grossWt}`, 1, 5);
    doc.text(`S.Wt: ${data.stoneWt}`, 1, 7);
    doc.text(`N.Wt: ${data.netWt}`, 1, 9);
    doc.text(`${data.itemCode}`, 12, 6);
    doc.text(`${data.purity}`, 12, 8);
    doc.addImage(qr, "JPEG", 17, 3, data.imageWidth, data.imageHeight);
    doc.save(`${data.name} Label.pdf`);
  };
  const openPDFInNewTab = () => {
    const doc = new jsPDF({
      format: data.format,
      orientation: data.orientation,
    });
    // doc.setFontSize(data.fontSize);
    // doc.text(`${data.name.substr(0, 26)}`, 1, 3);
    // doc.text(`G.Wt: ${data.grossWt}`, 1, 5);
    // doc.text(`S.Wt: ${data.stoneWt}`, 1, 7);
    // doc.text(`N.Wt: ${data.netWt}`, 1, 9);
    // doc.text(`${data.itemCode}`, 12, 6);
    // doc.text(`${data.purity}`, 12, 8);
    // doc.addImage(qr, "JPEG", 17, 3, data.imageWidth, data.imageHeight);
    doc.setFontSize(fontSize);
    if (mrp == 0 || mrp === "") {
      doc.text(`G.Wt: ${grossWt.toFixed(3)}`, 1, 5.5);
      doc.text(`S.Wt: ${parseInt(stoneWt).toFixed(3)}`, 1, 7.5);
      doc.text(`N.Wt: ${netWt.toFixed(3)}`, 1, 9.5);
      doc.text(`${pieces}`, 14, 6);
      doc.text(`${product_No}`, 14, 8);
      doc.text(`${itemCode}`, 20, 3);
      doc.text(`${purity}`, 20, 11.5);
    } else {
      doc.text(`G.Wt: ${grossWt.toFixed(3)}`, 1, 5.5);
      doc.text(`FC: ${parseInt(mrp)}`, 1, 7.5);
      doc.text(`${pieces}`, 14, 6);
      doc.text(`${product_No}`, 14, 8);
      doc.text(`${itemCode}`, 20, 3);
    }
    const pdfData = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
    );
  };
  const openLabelInNew = async (data) => {
    // {
    //   console.log(data);
    // }
    const doc = new jsPDF({
      // format: [26, 12],
      format: [28, 12],
      orientation: "landscape",
    });

    const fontSize = 6;
    const imageHeight = 7;
    const imageWidth = 7;

    // for (let i = 0; i < data.length; i++) {
    //   const {
    //     collection,
    //     grosswt,
    //     stoneWeight,
    //     netWt,
    //     itemCode,
    //     purity,
    //     mrp,
    //     product_No,
    //     pieces,
    //   } = data[i];

    //   if (i > 0) {
    //     doc.addPage(); // Add a new page for each product after the first one
    //   }

    doc.setFontSize(fontSize);
    // {
    //   data.collection.length > 20
    //     ? doc.text(`${data.collection.substr(0, 26)}`, 1, 3)
    //     : doc.text(`${data.collection}`, 1, 3);
    // }

    if (mrp == 0 || mrp === "") {
      doc.text(`G.Wt: ${data.grosswt.toFixed(3)}`, 11, 5.5);
      doc.text(`S.Wt: ${parseInt(data.stoneWeight).toFixed(3)}`, 11, 7.5);
      doc.text(`N.Wt: ${data.netWt.toFixed(3)}`, 11, 9.5);
      doc.text(`Pcs:${data.pieces}`, 18, 2);
      doc.text(`${data.product_No}`, 4, 11.5);
      doc.text(`${data.itemCode}`, 4, 3);
      doc.text(`${data.purity}`, 20, 11.5);
    } else {
      doc.text(`G.Wt: ${data.grosswt.toFixed(3)}`, 11, 5.5);
      doc.text(`FC: ${parseInt(data.mrp)}`, 11, 7.5);
      doc.text(`Pcs:${data.pieces}`, 18, 2);
      doc.text(`${data.product_No}`, 4, 11.5);
      doc.text(`${data.itemCode}`, 4, 3);
      doc.text(`${data.purity}`, 18, 11.5);
    }

    try {
      const qrCodeDataUrl = await QRCode.toDataURL(data.itemCode);
      doc.addImage(qrCodeDataUrl, "JPEG", 3, 3, imageWidth, imageHeight);
    } catch (error) {
      console.error(error);
    }
    // }

    const pdfData = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(
      `<iframe width='100%' height='100%' src='${pdfData}'></iframe>`
    );
  };
  return (
    <div>
      <h1>{data.title}</h1>
      {/* <button onClick={generatePDF}>Download Label Pdf </button>
      <button style={{ marginInline: "3rem" }} onClick={openPDFInNewTab}>
        Open PDF in New Tab
      </button> */}
      <button
        style={{ marginInline: "3rem" }}
        onClick={() => openLabelInNew(data)}
      >
        Print Label
      </button>
    </div>
  );
};

// import React, { useState } from "react";
// import { Document, Page, pdfjs } from "react-pdf";

// const MyPDF = () => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);
//   const [showPDF, setShowPDF] = useState(false);

//   const togglePDFView = () => {
//     setShowPDF(!showPDF);
//   };

//   const onDocumentLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages);
//   };

//   const handlePageChange = (newPageNumber) => {
//     setPageNumber(newPageNumber);
//   };

//   return (
//     <div>
//       <h1>My PDF</h1>
//       <button onClick={togglePDFView}>
//         {showPDF ? "Hide PDF" : "View PDF"}
//       </button>

//       {showPDF && (
//         <div>
//           <Document
//             file="path_to_your_pdf_file.pdf"
//             onLoadSuccess={onDocumentLoadSuccess}
//           >
//             <Page pageNumber={pageNumber} />
//           </Document>

//           <p>
//             Page {pageNumber} of {numPages}
//           </p>

//           <div>
//             <button
//               disabled={pageNumber <= 1}
//               onClick={() => handlePageChange(pageNumber - 1)}
//             >
//               Previous Page
//             </button>
//             <button
//               disabled={pageNumber >= numPages}
//               onClick={() => handlePageChange(pageNumber + 1)}
//             >
//               Next Page
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

export default MyPDF;
