// import { ADD_TO_CART, REMOVE_FROM_CART } from "../ActionTypes";

// export const reducer2 = (state = [], action) => {
//     switch (action.type) {
//         case ADD_TO_CART:
//             {
//                 return [...state, action.payload]
//             }
//         case REMOVE_FROM_CART:
//             {
//                 const newArray = state.filter((index) => {
//                     return index !== action.payload
//                 })
//                 return newArray
//             }

//         default:
//             return state;
//     }
// }

import { ADD_TO_CART, REMOVE_FROM_CART } from "../ActionTypes";

// ... Other imports and code ...

export const reducer2 = (state = [], action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      // Check if the product already exists in the cart
      const existingProductIndex = state.findIndex(
        (item) => item.Product_id === action.payload.Product_id
      );

      if (existingProductIndex !== -1) {
        // If the product exists, update the quantity
        const updatedState = [...state];
        updatedState[existingProductIndex] = {
          ...updatedState[existingProductIndex],
          count:
            updatedState[existingProductIndex].count + action.payload.count,
        };
        return updatedState;
      } else {
        // If the product doesn't exist, add a new item
        return [...state, action.payload];
      }
    }
    case REMOVE_FROM_CART: {
      const updatedState = state.filter((item) => {
        return item.Product_id !== action.payload.Product_id;
      });
      return updatedState;
    }
    default:
      return state;
  }
};
