import React from "react";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { a3, a39 } from "../../Api/RootApiPath";
import { userLoggedIn } from "../../../redux/action/Actions";
import { AiOutlineCheckCircle } from "react-icons/ai";
import aboutUsParaBorder from "../../Images/aboutUsParaBorder.png";

export default function PaymentSuccessPage() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderId = params.get("orderId");
  const custId = params.get("custId");
  const mode = params.get("mode");
  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const dispatch = useDispatch();
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  useEffect(() => {
    accountDetails();
  }, []);
  const accountDetails = async () => {
    // const formData = new FormData();
    // formData.append("Id", Customer_id);
    const formData = {
      Id: custId,
    };

    try {
      const response = await fetch(a3, {
        method: "POST",
        // body: formData,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        // body: formData,
      });
      const data = await response.json();
      // console.log("Data:", data);
      loggedIn([data.data]);
      fbq("track", "Purchase", { value: 1, currency: "INR" });
      if ((mode && mode == "razorpay") || (mode && mode == "PayUPayment")) {
        console.log("Updating");
        updateOrderStatus(orderId);
      }
      // if (mode && mode == "razorpay") {
      //   updateOrderStatus(orderId);
      // }
      // updateOrderStatus(orderId);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      // navigate("/checkoutpage2");
      alert(error);
    }
  };
  // {
  //   console.log(orderId);
  // }
  const updateOrderStatus = async (orderId) => {
    // const formData = new FormData();
    // formData.append("Id", Customer_id);
    const formData = {
      orderNumber: orderId,
      OrderStatus: "Paid",
    };

    try {
      console.log(formData);
      const response = await fetch(a39, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      // navigate("/checkoutpage2");
      // alert(error);
    }
  };

  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="accountDetailsMainBox" style={{ padding: "40px 0" }}>
        <div
          className="accountDetailsFormBox"
          style={{ backgroundColor: "#1a4c2d", color: "white" }}
        >
          <AiOutlineCheckCircle size={"4rem"} />
          <h1 style={{ color: "white", marginBottom: "40px" }}>SUCCESSFUL!</h1>
          <h5 style={{ fontWeight: "bold", marginBottom: "20px" }}>
            ORDER CONFIRMED!
          </h5>
          <p style={{ marginBottom: "20px" }}>
            Thank you for placing an order with us
          </p>
          <h3 style={{ color: "white" }}>"Each piece tells a story</h3>
          <h3 style={{ color: "white", marginBottom: "10px" }}>
            and now your story continues."
          </h3>
          <img style={{ width: "100%" }} src={aboutUsParaBorder} />
          {/* <div className="paymentSuccessPageDivider"></div> */}
          {/* <h5>Your order has been placed. Order ID: {orderId}</h5> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
