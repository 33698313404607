import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper";
import whyUsImage from "../../Images/whyUs3.png";
import UspPoints from "../../UspPoints/UspPoints";

export default function WhyUs() {
  return (
    // <>
    <div className="whyUsMain">
      <UspPoints />
      {/* <img className="whyUsImage" src={whyUsImage} alt="WHY US" /> */}
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Pagination, Autoplay, Navigation]}
        className="customerComments"
      >
        <SwiperSlide className="commentsSlide">
          <div className="comments">
            <p>
              I purchased a beautiful gold necklace from M.K.Ghare Jewellers and
              I couldn't be happier with my purchase.
            </p>
            <h1>Swapnaja Kamerkar</h1>
          </div>
        </SwiperSlide>

        <SwiperSlide className="commentsSlide">
          <div className="comments">
            <p>
              I have been a loyal customer of M.K.Ghare Jewellers for years and
              their designs are timeless and their attention to detail is
              impressive.
            </p>
            <h1>Atharva Mahajan</h1>
          </div>
        </SwiperSlide>

        <SwiperSlide className="commentsSlide">
          <div className="comments">
            <p>
              I recently purchased a gold bracelet from M.K.Ghare Jewellers and
              it exceeded my expectations. The customer service was excellent
              and the piece arrived beautifully packaged.
            </p>
            <h1>Vaibhav Bhandari</h1>
          </div>
        </SwiperSlide>

        <SwiperSlide className="commentsSlide">
          <div className="comments">
            <p>
              I inherited a gold bracelet from my grandmother and wanted to get
              it repaired. I brought it to M.K.Ghare Jewellers and they not only
              repaired it, but also polished it to look like new. I was
              impressed.
            </p>
            <h1>Rohit Bhosale</h1>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    // </>
  );
}
