export const a1 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/GetAllCustomer";
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/GetAllCustomer";
// "https://jewellerytestingdomain.azurewebsites.net/api/CustomerDetails/GetAllCustomer";
//   Customer List All (GET)

export const a2 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerasperloginid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/CustomerByLoginId";
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/CustomerByLoginId";
//   Customer List As per Customer Login Id (POST)

export const a3 =
  // "https://sunshineteam.in/loyalstring/api_ls_customerasperuserid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/GetCustomerById";
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/GetCustomerById";
//   Customer Details as per Customer id (POST)

export const a4 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/AddCustomer";
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/AddCustomer";
//   Add Customer (POST)

export const a5 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdateCustomer";
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/UpdateCustomer";
//   Update Customer with AnyField except Customer_id and Data (POST)

export const a6 =
  // "https://sunshineteam.in/loyalstring/api_ls_staff_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Staff/AddStaff";
  "https://mkgharejewellers.mkghare.com/api/Staff/AddStaff";
//   Add Staff (POST)

export const a7 =
  // "https://sunshineteam.in/loyalstring/api_ls_category_insert.php  ";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertCategory";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/InsertCategory";
//   Add Category (POST)

export const a8 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertProduct";
  // "https://sunshineteam.in/loyalstring/api_ls_product_insert.php";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/InsertProduct";
//   Add Product (POST)

export const a9 =
  "https://sunshineteam.in/loyalstring/api_ls_wishlist_insert.php";
//   Add Wishlist (POST)

export const a10 =
  // "https://sunshineteam.in/loyalstring/api_ls_order_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/InsertOrders";
  "https://mkgharejewellers.mkghare.com/api/Orders/InsertOrders";
//   Add Order (POST)

export const a11 =
  "https://sunshineteam.in/loyalstring/api_ls_rating_insert.php";

// Add Rating (POST)

export const a12 =
  // "https://sunshineteam.in/loyalstring/api_ls_rates_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/InsertRates";
  "https://mkgharejewellers.mkghare.com/api/Orders/InsertRates";
// Add Rates to Category (POST)

export const a13 =
  // "https://sunshineteam.in/loyalstring/api_ls_ratestoday_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/ratestodaybycategory";
  "https://mkgharejewellers.mkghare.com/api/Orders/ratestodaybycategory";
// "https://mkgharejewellers.mkghare.com/api/Orders/ratestodaybycategory";

// Get Todays Rate as per Category (POST)
export const a14 =
  "https://sunshineteam.in/loyalstring/api_ls_ratesalldays_data.php";
// Get All Rates as per Category (POST)

export const a15 = "https://sunshineteam.in/loyalstring/api_ls_rating_data.php";
// Get Rating as per Product id (POST)

export const a16 =
  // "https://sunshineteam.in/loyalstring/api_ls_productall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetfetchAllProduct";
  // "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetfetchAllProduct";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetuniqiueAllProduct";
// Get All Product Information (GET)

export const a17 =
  // "https://sunshineteam.in/loyalstring/api_ls_productbyproductid_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchProductById";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/fetchProductById";
// Get Product Inforation as per Product Id (POST)

export const a18 =
  // "https://sunshineteam.in/loyalstring/api_ls_categoryall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllCategory";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/fetchAllCategory";
// Get All Categories (GET)

export const a19 =
  // "https://sunshineteam.in/loyalstring/api_ls_ratestodayallcategories_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/Orders/fetchTodayRates";
  "https://mkgharejewellers.mkghare.com/api/Orders/fetchTodayRates";
// Get All Rates of today of all categories (GET)

export const a20 =
  // "https://sunshineteam.in/loyalstring/api_ls_producttypeall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllProductType";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/fetchAllProductType";
// Get All ProductTypes (GET)

export const a21 =
  // "https://sunshineteam.in/loyalstring/api_ls_producttype_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertProductType";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/InsertProductType";
// Insert ProductType (POST)

export const a22 =
  // "https://sunshineteam.in/loyalstring/api_ls_purityall_data.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/fetchAllPurity";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/fetchAllPurity";
// Get All Purity data (GET)

export const a23 =
  // "https://sunshineteam.in/loyalstring/api_ls_purity_insert.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/InsertPurity";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/InsertPurity";
// Insert Purity data (POST)

export const a24 =
  // "https://sunshineteam.in/loyalstring/api_ls_product_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateProduct";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/UpdateProduct";
// Update Product data (POST)

export const a25 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdatePassword";
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/UpdatePassword";
// Update Password

export const a26 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateImage";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/UpdateImage";
// Update Images

export const a27 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddPartyMaster";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/AddPartyMaster";
// Add Supplier Details

export const a28 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllPartyMaster";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetAllPartyMaster";
// Get Supplier Details

export const a29 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddBoxMaster";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/AddBoxMaster";
// Add Box Details

export const a30 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllBoxMaster";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetAllBoxMaster";
// Get Box Details

export const a31 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/BulkUpdateProduct";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/BulkUpdateProduct";
// Update Bulk Products

export const a32 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/AddCollection";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/AddCollection";
// Insert Collection

export const a33 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/GetAllCollection";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetAllCollection";
// Get Collection

export const a34 =
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/ProductMaster/UpdateCollection";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/UpdateCollection";
// Update Collection

export const a35 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/UpdateCategory";
// Update Category

export const a36 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/UpdateProductType";
// Update ProductType

export const a37 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/UpdatePurity";
// Update ProductType

export const a38 =
  "https://mkgharejewellers.mkghare.com/api/Orders/fetchAllOrders";
// Update ProductType

export const a39 =
  "https://mkgharejewellers.mkghare.com/api/Orders/UpdateOrders";
// Update ProductType

export const a40 =
  // "https://sunshineteam.in/loyalstring/api_ls_customer_update.php";
  // "https://jewellerywebapplication20230518130808.azurewebsites.net/api/CustomerDetails/UpdateCustomer";
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/UpdateCustomers";
// Update Customer all fields

export const a41 =
  // "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetfetchAllProduct";
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetfetchAllProduct";
// Get All Products

export const a42 =
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/GetOtp";
// Get OTP

export const a43 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/UpdateProductbyCollection";
// Update all products of any collection

export const a44 =
  "https://mkgharejewellers.mkghare.com/api/Orders/DownloadInvoice";
// Update all products of any collection

export const a45 = "https://mkgharejewellers.mkghare.com/api/Orders/InvoicePDF";
// Update all products of any collection

export const a46 =
  "https://mkgharejewellers.mkghare.com/api/Orders/CreateOrder";
// Insert Order

export const a47 =
  "https://mkgharejewellers.mkghare.com/api/Orders/CreateOrderItem";
// Insert Order Items

export const a48 =
  "https://mkgharejewellers.mkghare.com/api/Orders/PaymentRequest";
// Handle Payment

export const a49 =
  "https://mkgharejewellers.mkghare.com/api/Orders/fetchAllOrderItems";
// Get All Order Items

export const a50 =
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/google";
// Google Login

export const a51 =
  "https://mkgharejewellers.mkghare.com/api/Orders/CreateCoupons";
// Create Coupons

export const a52 =
  "https://mkgharejewellers.mkghare.com/api/Orders/GetAllCoupons";
// Fetch all Coupons

export const a53 =
  "https://mkgharejewellers.mkghare.com/api/Orders/UpdateCoupons";
// Update Coupons

export const a54 =
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/facebook";
// Facebook Login

export const a55 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/fetchProductByCollectionId";
// All Items of an Collection

export const a56 =
  "https://mkgharejewellers.mkghare.com/api/Orders/InternalInvoicePDF";
// Internal Team Invoice

export const a57 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetAllSize";
// Get All Size

export const a58 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/UpdateSize";
// Update Size

export const a59 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/CreateSize";
// Add New Size

export const a60 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/GetCartItemByCustomerId";
// Get Cart Items by Customer Id

export const a61 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/AddCartItem";
// Add Cart Items by Customer Id

export const a62 =
  "https://mkgharejewellers.mkghare.com/api/ProductMaster/DeleteCartItem";
// Delete Cart Items by Customer Id

export const a63 =
  "https://mkgharejewellers.mkghare.com/api/CustomerDetails/MobileOtp";
// Send mobile Otp

export const a64 =
  "https://mkgharejewellers.mkghare.com/api/Orders/PaymentRequesttesting";
// PhonePe Request for hash Code

export const a65 = "https://api.phonepe.com/apis/hermes/pg/v1/pay";
// PhonePe Payment Request Final

export const a66 =
  "https://mkgharejewellers.mkghare.com/api/Orders/PayuRequest";
// Payu payment api

// export const s1 = `http://localhost:3000/demo/Images/`;
// export const s1 = `https://mkgharejewellers.com/demo/Images/`;

export const s1 = `https://mkgharejewellers.com/Images/`;
// export const s1 = `https://goldkarizma.com/demo/Images/`;
//For Local Images
// export const navigates = useNavigate();
// export const s2 = `http://localhost:3000/contactdetails`;

export const s2 = `https://mkgharejewellers.com/contactdetails`;
// export const s2 = `https://mgharejewellers.com/demo/contactdetails`;
// export const s2 = `https://goldkarizma.com/demo/contactdetails`;
// export const s3 = "https://product123.blob.core.windows.net/images";

export const s3 =
  // "https://product123.blob.core.windows.net/images";
  // "https://jewellerywebapplications.blob.core.windows.net/images";
  "https://mkgharejewellers.mkghare.com/images";

export const paymentApi = "http://localhost:5000";
export const razorpayPaymentApi =
  "https://mkgharejewellers.mkghare.com/api/Orders/RazorPaymentRequest";
// export const paymentApi = "https://goldkarizma.com";
// export const paymentApi = "https://goldkarizma.com/nodjs";
