import React, { useState, useEffect } from "react";
import { a41 } from "../../Api/RootApiPath";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import AdminProductCard from "./AdminComponent/AdminProductCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
export default function AdminProducts() {
  const [productData, setProductData] = useState([]);
  const [product_id, setProduct_Id] = useState([]);
  const [product_No, setProduct_No] = useState([]);
  const [title, setTitle] = useState([]);
  const [category_id, setCategory_id] = useState([]);
  const [images, setImages] = useState([]);
  const [Pieces, setPieces] = useState([]);
  const [descriptionPara, setDescriptionPara] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [filterByCategory, setFilterByCategory] = useState("");
  const [filteredProductData, setFilteredProductData] = useState([]); // State variable for filtered product data

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  const navigate = useNavigate();
  useEffect(() => {
    fetchProducData();
  }, []);

  const fetchProducData = async () => {
    try {
      const response = await fetch(a41);
      const json = await response.json();
      setProductData(json.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("Error: " + error.message);
    }
  };
  console.log(productData);

  const imagePath =
    "https://jewellerywebapplication20230518130808.azurewebsites.net";
  useEffect(() => {
    const productIds = productData.map((x) => x.id);
    setProduct_Id(productIds);
    const productNos = productData.map((x) => x.product_No);
    setProduct_No(productNos);
    const titles = productData.map((x) => x.product_Name);
    setTitle(titles);
    const categoryIds = productData.map((x) => x.category_id);
    setCategory_id(categoryIds);
    const descriptions = productData.map((x) => x.description);
    setDescriptionPara(descriptions);
    const imageList = productData.map((x) => x.images);
    setImages(imageList);

    const Pieces = productData.map((x) => x.pieces);
    setPieces(Pieces);
  }, [productData]);

  const handleFilterByCategory = (selectedCategory) => {
    setFilterByCategory(selectedCategory);

    // Filter the productData array based on the selected category
    const filteredProducts = selectedCategory
      ? productData.filter((item) => item.category_Name == selectedCategory)
      : productData;

    // Update the filtered product data based on date filtering
    setFilteredProductData(filteredProducts);
  };
  console.log(filteredProductData);
  const filterByDate = () => {
    // Apply date filtering on the filtered products
    let fromDateFiltered = [];
    fromDateFiltered = filteredProductData.filter(
      (item) => item.lastUpdated.split("T")[0] <= fromDate
    );
    console.log(fromDateFiltered);
    console.log(fromDateFiltered);
  };
  // console.log(productData[0].lastUpdated.split("T")[0]);
  // console.log(filteredProductData);

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        <h1>All Products</h1>
        <div className="adminInventoryFilterMainBox">
          <div className="adminInventoryFilters">
            <select
              className="adminAllProductsFilterBtn"
              onChange={(e) => handleFilterByCategory(e.target.value)}
            >
              Filter By:
              <option value="">Select an option</option>
              <option value="Gold">Gold</option>
              <option value="Silver">Silver</option>
            </select>
          </div>
          {/* <div className="adminInventoryFilters">
            <label htmlFor="fromDate">From Date:</label>
            <input
              className="adminAllProductsFilterBtn"
              type="date"
              id="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="adminInventoryFilters">
            <label htmlFor="toDate">To Date:</label>
            <input
              className="adminAllProductsFilterBtn"
              type="date"
              id="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div> */}
        </div>
        {/* <button onClick={filterByDate}>Apply</button> */}
        <p>{filteredProductData.length}</p>
        <p>
          <span style={{ color: "red", fontWeight: "bolder" }}>NOTE: </span>
          Click on any product to view and edit its details
        </p>
        {isLoading && <p>Loading...</p>}
        <div className={isLoading == true ? "loading" : "none"}>
          {/* <h1>Loading...</h1> */}
          <InfinitySpin width="200" color="#4fa94d" />
        </div>
        {errorMessage && <p>{errorMessage}</p>}
        {productData.length === 0 && <p>No data available</p>}
        <div className="adminProductCardBox">
          {filteredProductData.map((data, index) => (
            <div
              style={{ cursor: "pointer" }}
              className="adminProductCard"
              onClick={() =>
                navigate(`/admin-productdetails?productId=${data.id}`)
              }
            >
              {/* <h1>{data.category_Name}</h1> */}
              <AdminProductCard
                key={data.index}
                // product_id={product_id[index]}
                // product_No={product_No[index]}
                // title={title[index]}
                // category_id={category_id[index]}
                // description={descriptionPara[index]}
                // images={images[index]}
                // Pieces={Pieces[index]}
                product_id={data.id}
                product_No={data.product_No}
                title={data.product_Name}
                category_id={data.category_Id}
                description={data.description}
                images={data.images}
                Pieces={data.pieces}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
