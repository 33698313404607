import React, { useEffect, useState } from "react";
import { a38, a39, a44 } from "../../Api/RootApiPath";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import { BsHandbag } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function AdminAllCustomers() {
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 25;

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;

  const navigate = useNavigate();
  useEffect(() => {
    fetch(a38)
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        setAllOrders(response.data.reverse());
        console.log("allorders", response.data);
        setLoading(false);
        // setOlddata(response);
        console.log(response.data);
      });
  }, []);
  function showPDFWithId(id) {
    // Make the API POST request with the ID
    fetch(a44, {
      method: "POST",
      body: JSON.stringify({ id: id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob()) // Convert response to Blob
      .then((blob) => {
        // Create a URL for the Blob object
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, "_blank");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleOrderStatusChange = (orderNumber, newStatus) => {
    // Make the API request to update the order status
    const formData = {
      orderNumber: orderNumber,
      OrderStatus: newStatus,
    };
    fetch(a39, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // alert("changed");
        // Update the order status in the local state
        setAllOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.orderNumber === orderNumber
              ? { ...order, orderStatus: newStatus }
              : order
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  // const removeUnpaid = (x) => {
  //   x.filter((y) => {
  //     if (y !== "Pending") {
  //       return [...y];
  //     }
  //   });
  //   return x;
  // };

  // const filterOrders = (x) => {
  //   allOrders.filter((y) => y.orderName === x);
  //   return setFilteredOrders(allOrders);
  // };
  const filterOrders = () => {
    let filtered = allOrders.sort(
      (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
    );

    if (orderStatus !== "") {
      filtered = filtered.filter((order) => order.orderStatus === orderStatus);
    }

    if (orderNumber !== "") {
      filtered = filtered.filter(
        (order) => order.orderNumber && order.orderNumber.includes(orderNumber)
      );
    }

    if (fromDate !== "" && toDate !== "") {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.createdOn);
        return orderDate >= new Date(fromDate) && orderDate <= new Date(toDate);
      });
    }
    console.log("filtered", filtered);
    setFilteredOrders(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  useEffect(() => {
    filterOrders();
    window.scrollTo(0, 0);

    console.log(filteredOrders);
  }, [orderStatus, orderNumber, allOrders, fromDate, toDate]);

  useEffect(() => {}, [currentPage]);

  const indexOfLastProduct = currentPage * ordersPerPage;
  const indexOfFirstProduct = indexOfLastProduct - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  console.log("current Orders", currentOrders);
  return (
    <div className="allCustomersTableMain">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState
            ? "adminContentbox allCustomersTable"
            : "adminContentbox adminContentbox2 allCustomersTable"
        }
      >
        <h1 style={{ marginBottom: "3rem" }}>All Orders</h1>
        <div className={loading == true ? "loading" : "none"}>
          {/* <h1>Loading...</h1> */}
          <InfinitySpin width="200" color="#4fa94d" />
        </div>
        <div className="adminAllOrdersFilterBox">
          <div className="adminAllOrderLeftBox">
            <input
              type="text"
              placeholder="Search..."
              value={orderNumber}
              onChange={(e) => {
                setOrderNumber(e.target.value.toUpperCase()), setCurrentPage(1);
              }}
            />
            <p>Status</p>
            <select
              value={orderStatus}
              onChange={(e) => {
                setOrderStatus(e.target.value), setCurrentPage(1);
              }}
            >
              <option value="">Choose...</option>
              <option value="Payment Pending">Payment Pending</option>
              <option value="Payment Failed">Payment Failed</option>
              <option value="Paid">Paid</option>
              <option value="Shipped">Shipped</option>
              <option value="Delivered">Delivered</option>
            </select>
            <input
              type="date"
              placeholder="From Date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
            <input
              type="date"
              placeholder="To Date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="adminAllOrderRightBox">
            <button>
              <BsHandbag style={{ marginRight: "5px" }} />
              Add New Order
            </button>
            <button>Export</button>
          </div>
        </div>
        <div
          className="adminAllOrdersTableMainBox"
          style={{ overflow: "auto" }}
        >
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Order No</th>
                <th>Date</th>
                {/* <th>Customer Id</th> */}
                <th>Customer Name</th>
                <th>Price</th>
                {/* <th>Product Id</th> */}
                {/* <th>Product Name</th> */}
                {/* <th>Item Code</th> */}
                {/* <th>Quantity</th> */}
                <th>Amount</th>
                <th>Order Status</th>
                {/* <th>Payment Mode</th> */}
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((x) => (
                // <tr key={x.Customer_id}>
                <tr key={x.id}>
                  <td>{x.id}</td>
                  <td
                    onClick={() => {
                      navigate(`/admin-orderdetails/${x.id}`);
                    }}
                    className="adminAllOrdersOrderData"
                  >
                    {x.orderNumber}
                  </td>
                  <td>{new Date(x.createdOn).toLocaleDateString("en-GB")}</td>
                  {/* <td>{x.customer_Id}</td> */}
                  <td>
                    {x.tblCustomerDetails.firstName +
                      " " +
                      x.tblCustomerDetails.lastName}
                  </td>
                  <td>{x.price}</td>
                  {/* <td>{x.product_id}</td> */}
                  {/* <td>{x.tblProduct.product_Name}</td> */}
                  {/* <td
                    className="adminAllOrdersOrderData"
                    onClick={() => {
                      navigate(`/admin-orderdetails/${x.id}`);
                    }}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {x.tblProduct.itemCode}
                  </td> */}

                  {/* <td>{x.qty}</td> */}
                  <td>{x.receivedAmt}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <p
                      style={
                        x.orderStatus === "Paid"
                          ? {
                              backgroundColor: "rgba(0, 128, 0, 0.7)",
                              color: "white",
                              borderRadius: "5px",
                              padding: "2px",
                            }
                          : x.orderStatus === "Processing Payment"
                          ? {
                              backgroundColor: "rgb(219, 153, 30)",
                              color: "white",
                              borderRadius: "5px",
                              padding: "2px 10px",
                            }
                          : x.orderStatus === "Payment Failed"
                          ? {
                              backgroundColor: "rgb(231, 30, 60)",
                              color: "white",
                              borderRadius: "4px",
                              padding: "2px 10px",
                            }
                          : x.orderStatus === "Shipped"
                          ? {
                              backgroundColor: "rgb(77, 155, 228)",
                              color: "white",
                              borderRadius: "4px",
                              padding: "2px 10px",
                            }
                          : x.orderStatus === "Delivered"
                          ? {
                              backgroundColor: "rgb(159, 77, 206)",
                              color: "white",
                              borderRadius: "4px",
                              padding: "2px 10px",
                            }
                          : {
                              backgroundColor: "rgb(180, 180, 46)",
                              color: "white",
                              borderRadius: "4px",
                              padding: "2px 10px",
                            }
                      }
                    >
                      {x.orderStatus}
                    </p>
                  </td>
                  {/* <td>{x.orderStatus.filter((y) => y !== "Pending")}</td> */}
                  {/* <td>{x.paymentMode}</td> */}
                  <td>
                    {x.orderStatus === "Paid" ? (
                      <select
                        required="required"
                        value={orderStatus}
                        onChange={(e) =>
                          handleOrderStatusChange(x.orderNumber, e.target.value)
                        }
                      >
                        <option value={x.orderStatus}>Change..</option>
                        {/* <option value="Pending Payment">Pending Payment</option> */}
                        {/* <option value="Payment Failed">Payment Failed</option>
                        <option value="Paid">Paid</option> */}
                        <option value="Shipped">Shipped</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    ) : x.orderStatus === "Return Requested" ? (
                      <select
                        required="required"
                        value={orderStatus}
                        onChange={(e) =>
                          handleOrderStatusChange(x.orderNumber, e.target.value)
                        }
                      >
                        <option value={x.orderStatus}>Change..</option>
                        <option value="Returned">Returned</option>
                      </select>
                    ) : x.orderStatus === "Shipped" ? (
                      <select
                        required="required"
                        value={orderStatus}
                        onChange={(e) =>
                          handleOrderStatusChange(x.orderNumber, e.target.value)
                        }
                      >
                        <option value={x.orderStatus}>Change..</option>
                        <option value="Delivered">Delivered</option>
                      </select>
                    ) : (
                      <p
                        style={
                          x.orderStatus === "Paid"
                            ? {
                                fontWeight: "bold",
                                color: "rgba(0, 128, 0, 0.7)",
                                whiteSpace: "nowrap",
                              }
                            : x.orderStatus === "Processing Payment"
                            ? {
                                fontWeight: "bold",
                                color: "rgb(219, 153, 30)",
                                whiteSpace: "nowrap",
                              }
                            : x.orderStatus === "Payment Failed"
                            ? {
                                fontWeight: "bold",
                                color: "rgb(231, 30, 60)",
                                whiteSpace: "nowrap",
                              }
                            : x.orderStatus === "Shipped"
                            ? {
                                fontWeight: "bold",
                                color: "rgb(77, 155, 228)",
                                whiteSpace: "nowrap",
                              }
                            : x.orderStatus === "Delivered"
                            ? {
                                fontWeight: "bold",
                                color: "rgb(159, 77, 206)",
                                whiteSpace: "nowrap",
                              }
                            : {
                                fontWeight: "bold",
                                color: "rgb(180, 180, 46)",
                                whiteSpace: "nowrap",
                              }
                        }
                      >
                        {x.orderStatus}
                      </p>
                    )}
                  </td>
                  <td>
                    <button
                      style={{ padding: "0px", cursor: "pointer" }}
                      onClick={() => {
                        showPDFWithId(x.id), setLoading(true);
                      }}
                    >
                      show bill
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="bulkProductAddingTableMain">
            <button onClick={goToPreviousPage} disabled={currentPage === 1}>
              Previous
            </button>
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
