import React from "react";
import { useNavigate } from "react-router-dom";

function SliderContent({ activeIndex, imageSlider }) {
  const navigate = useNavigate();
  return (
    <section>
      {imageSlider.map((slide, index) => (
        <div
          key={index}
          className={
            index === activeIndex
              ? "newHomePageSliderSlides newHomePageSliderActive"
              : "newHomePageSliderInactive"
          }
        >
          <img className="newHomePageSliderImage" src={slide.urls} alt="" />
          <div className="newHomePageSliderButton">
            <button
              // className="newHomePageSliderButton"
              onClick={() => navigate(slide.path)}
            >
              Shop Now
            </button>
            <button
              // className="newHomePageSliderButton"
              onClick={() => navigate(slide.path)}
            >
              Discover More
            </button>
          </div>
          {/* <h2 className="newHomePageSliderTitle">{slide.title}</h2> */}
          {/* <h3 className="newHomePageSliderText">{slide.description}</h3> */}
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
