import React, { useState } from "react";
import "../AdminComponentStyles/AdminNavbar.css";
import logo from "../../../Images/MainLogo2.png";
import { AiOutlineSearch, AiOutlineSetting } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { CiUser, CiSettings, CiLock, CiLogout } from "react-icons/ci";
import {
  BsFullscreen,
  BsFullscreenExit,
  BsFillChatLeftTextFill,
} from "react-icons/bs";
import AdminSideBar from "./AdminSideBar";
import { useNavigate } from "react-router-dom";
import { TfiMenuAlt } from "react-icons/tfi";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import {
  adminLoggedOut,
  adminSidebarClosed,
  adminSidebarOpened,
} from "../../../../redux/action/Actions";
export default function AdminNavbar() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  const dispatch = useDispatch();
  const loggedOut = (user) => {
    dispatch(adminLoggedOut(user));
  };

  const handleChange = () => {
    setIsDarkMode(!isDarkMode);
    // Call a function to switch the entire app's theme to light/dark mode here
  };

  const [settingsPanelOpen, setSettingsPanelOpen] = useState(false);
  const [sidebarPanelOpen, setSidebarPanelOpen] = useState(true);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [drawerSection, setDrawerSection] = useState("Settings");

  // console.log(sidebarPanelOpen);
  const handleFullScreen = () => {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    loggedOut(adminLoggedIn[0]);
    navigate("/adminpanellogin");
  };
  const [showProfile, setShowProfile] = useState(false);
  const toggleDrawer = () => {
    setSettingsPanelOpen(!settingsPanelOpen);
  };
  const toggleSidebar = () => {
    setSidebarPanelOpen(!sidebarPanelOpen);
    if (sidebarPanelOpen) {
      dispatch(adminSidebarClosed());
      setSidebarPanelOpen(false);
    } else {
      dispatch(adminSidebarOpened());
      setSidebarPanelOpen(true);
    }
  };
  return (
    <div>
      <div className="navbarMainBox">
        {/* <div className="logoBox">
        </div> */}

        <div className="searchBox">
          <input type="text" />

          <div className="searchIcon">
            <AiOutlineSearch className="adminNavbarIcons" size="1.5rem" />
          </div>
        </div>
        {isFullScreen ? (
          <BsFullscreenExit
            size="20px"
            className="adminNavbarIcons"
            style={{ marginLeft: "2rem" }}
            onClick={handleFullScreen}
          />
        ) : (
          <BsFullscreen
            size="20px"
            className="adminNavbarIcons"
            style={{ marginLeft: "2rem" }}
            onClick={handleFullScreen}
          />
        )}
        <IoMdNotificationsOutline
          className="adminNavbarIcons"
          size="23px"
          style={{ marginLeft: "2rem" }}
        />

        <div
          className="signOutBox"
          onClick={() => setShowProfile(!showProfile)}
        >
          <div className="profilePic">
            <CgProfile className="adminNavbarIcons" size="20px" />
          </div>
          <div className="profileDetailsBox">
            <p>Admin</p>
          </div>
          <div
            className={
              showProfile ? "profileDropdownBox" : "profileDropdownBox none"
            }
          >
            <h6 style={{ textAlign: "left", margin: "1rem" }}>WELCOME !!</h6>
            <h5>
              <CiUser strokeWidth={"1px"} style={{ marginRight: "0.5rem" }} />
              My Account
            </h5>
            <h5>
              <CiSettings
                strokeWidth={"0.5px"}
                style={{ marginRight: "0.5rem" }}
              />
              Settings
            </h5>
            <h5>
              <CiLock strokeWidth={"1px"} style={{ marginRight: "0.5rem" }} />
              Lock screen
            </h5>
            <hr></hr>
            <h5
              style={{ marginBottom: "0.5rem" }}
              onClick={() => handleLogout()}
            >
              <CiLogout strokeWidth={"1px"} style={{ marginRight: "0.5rem" }} />
              Log Out
            </h5>
          </div>
        </div>
        <div className="settings">
          <AiOutlineSetting
            className="adminNavbarIcons"
            size="20px"
            style={{
              marginLeft: "0rem",
              marginRight: "5rem",
            }}
            onClick={toggleDrawer}
          />
        </div>
      </div>

      {settingsPanelOpen && (
        <div
          className="adminDrawerOverlay"
          onClick={() => setSettingsPanelOpen(false)}
        />
      )}

      <div
        className={`adminSettingsDrawer ${
          settingsPanelOpen ? "adminDrawerOpen" : ""
        }`}
      >
        {/* Drawer content here */}
        <div className="adminDrawerMainBox">
          <div className="adminDrawerMainIconsBox">
            <BsFillChatLeftTextFill
              className="adminDrawerIcons"
              size={"1.25rem"}
              onClick={() => setDrawerSection("Chats")}
            />
            <TfiMenuAlt
              onClick={() => setDrawerSection("Tasks")}
              className="adminDrawerIcons"
              size={"1.25rem"}
            />
            <AiOutlineSetting
              onClick={() => setDrawerSection("Settings")}
              className="adminDrawerIcons"
              size={"1.25rem"}
            />
          </div>
          {drawerSection === "Chats" ? (
            <div className="adminDrawerChatsMainBox">
              <div className="adminDrawerChatsInput">
                <AiOutlineSearch color="grey" size={"1.25rem"} />
                <input type="text" placeholder="Search..." />
              </div>
              <div className="adminDrawerAllChatsBox">
                <p className="adminDrawerAllChatsBoxHeading">GROUP CHATS</p>
                <li className="adminDrawerAllChatsBoxContent">
                  App Development
                </li>
                <li className="adminDrawerAllChatsBoxContent">
                  App Development
                </li>
                <li className="adminDrawerAllChatsBoxContent">
                  App Development
                </li>
                <p
                  style={{ marginTop: "2rem" }}
                  className="adminDrawerAllChatsBoxHeading"
                >
                  FAVOURITES
                </p>
                <p className="adminDrawerAllChatsBoxContent">Customer 1</p>
                <p className="adminDrawerAllChatsBoxContent">Customer 2</p>
                <p className="adminDrawerAllChatsBoxContent">Customer 3</p>
                <p className="adminDrawerAllChatsBoxContent">Customer 4</p>
                <p className="adminDrawerAllChatsBoxContent">Customer 5</p>
                <p
                  style={{ marginTop: "2rem" }}
                  className="adminDrawerAllChatsBoxHeading"
                >
                  OTHER CHATS
                </p>
                <p className="adminDrawerAllChatsBoxContent">Customer 1</p>
                <p className="adminDrawerAllChatsBoxContent">Customer 2</p>
                <p className="adminDrawerAllChatsBoxContent">Customer 3</p>
                <p className="adminDrawerAllChatsBoxContent">Customer 4</p>
                <p className="adminDrawerAllChatsBoxContent">Customer 5</p>
              </div>
              <button>Load More</button>
            </div>
          ) : drawerSection === "Tasks" ? (
            <div className="adminDrawerTasksMainBox">
              <p
                style={{ marginTop: "0rem", marginBottom: "2rem" }}
                className="adminDrawerAllChatsBoxHeading"
              >
                WORKING TASKS
              </p>
              <p
                style={{ margin: "1rem 0" }}
                className="adminDrawerAllChatsBoxContent"
              >
                App Development
              </p>

              <p
                style={{ margin: "1rem 0" }}
                className="adminDrawerAllChatsBoxContent"
              >
                Database Repair
              </p>
              <p
                style={{ margin: "1rem 0" }}
                className="adminDrawerAllChatsBoxContent"
              >
                Backup Create
              </p>
              <p
                style={{ marginTop: "2rem", marginBottom: "2rem" }}
                className="adminDrawerAllChatsBoxHeading"
              >
                UPCOMING TASKS
              </p>
              <p
                style={{ margin: "1rem 0" }}
                className="adminDrawerAllChatsBoxContent"
              >
                Sales Reporting
              </p>

              <p
                style={{ margin: "1rem 0" }}
                className="adminDrawerAllChatsBoxContent"
              >
                Redesign Website
              </p>
              <p
                style={{ margin: "1rem 0" }}
                className="adminDrawerAllChatsBoxContent"
              >
                New Admin Design
              </p>
              <div className="adminDrawerTasksButtonBox">
                <button>Create Task</button>
              </div>
            </div>
          ) : (
            <div className="adminDrawerMainBox">
              <p
                style={{
                  padding: "1rem",
                  fontSize: "12px",
                  fontWeight: "bolder",
                  backgroundColor: "rgb(186, 245, 236)",
                }}
              >
                THEME SETTINGS
              </p>
              <div className="adminDrawerNoteBox">
                <p>
                  <strong>Customize </strong>the overall color scheme, sidebar
                  menu, etc.
                </p>
              </div>
              <div className="adminDrawerAllChatsBox">
                <p className="adminDrawerAllChatsBoxHeading">Topbar Color</p>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Dark</label>
                </li>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Light</label>
                </li>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Brand</label>
                </li>

                <p
                  style={{ marginTop: "2rem" }}
                  className="adminDrawerAllChatsBoxHeading"
                >
                  Menu Color
                </p>

                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Dark</label>
                </li>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Light</label>
                </li>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Brand</label>
                </li>

                <p
                  style={{ marginTop: "2rem" }}
                  className="adminDrawerAllChatsBoxHeading"
                >
                  Sidebar Size
                </p>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Default</label>
                </li>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Icon View</label>
                </li>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Full Layout</label>
                </li>
                <p
                  style={{ marginTop: "2rem" }}
                  className="adminDrawerAllChatsBoxHeading"
                >
                  Sidebar User Info
                </p>
                <li className="adminDrawerAllChatsBoxContent radioBtns">
                  <div
                    className={`switch ${isDarkMode ? "dark" : "light"}`}
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isDarkMode}
                      onChange={handleChange}
                    />
                    <div className="slider" />
                  </div>
                  <label style={{ marginRight: "0.5rem" }}>Enable</label>
                </li>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* {sidebarPanelOpen && (
        <div
          className="adminDrawerOverlay2"
          onClick={() => setSidebarPanelOpen(false)}
        />
      )} */}

      <div
        className={`adminSettingsDrawer2 ${
          sidebarPanelOpen
            ? "adminDrawerOpen2"
            : "adminDrawerOpen2 adminDrawerIconsOpen"
        }`}
      >
        {sidebarPanelOpen ? <AdminSideBar /> : <AdminSideBar />}
        <GiHamburgerMenu
          onClick={toggleSidebar}
          className="adminSidebarOpenIcon"
          size="20px"
        />
        {/* {sidebarPanelOpen ? sidebarOpen() : sidebarClose()} */}
      </div>
    </div>
  );
}
