import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { a52, s1, s3 } from "../../Api/RootApiPath";
import tempImg from "../../Images/blankProduct.jpg";
import Footer from "../../Footer/Footer";
import { BsBagX } from "react-icons/bs";
import Heading from "../../Heading/Heading";
import { AiFillLock } from "react-icons/ai";
import { removeFromCart } from "../../../redux/action/Actions";
import { useNavigate } from "react-router-dom";
import { TbDiscount2 } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineArrowRight } from "react-icons/ai";

export default function CheckOutMain() {
  const [productCount, setProductCount] = useState(1);
  const [couponBox, setCouponBox] = useState(false);
  const [allCoupons, setAllCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [visibleCoupons, setVisibleCoupons] = useState([]);
  const [hiddenCoupons, setHiddenCoupons] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState([]);
  useEffect(() => window.scrollTo(0, 0), []);
  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const userDetails = orderState.reducer4;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRemoveFromBag = (index) => {
    dispatch(removeFromCart(index));
    removeCoupon();
  };

  let totalPrice = 0;
  let totalPriceBefore = 0;
  let saved = 0;
  let discount = 0;
  let deliveryCharge = 0;
  const fetchAllCoupons = async () => {
    try {
      const response = await fetch(a52);
      const data = await response.json();

      // setAllCoupons(data.data);
      setAllCoupons(data.data);
      setVisibleCoupons(data.data.filter((x) => x.visibility === "visible"));
      setHiddenCoupons(data.data.filter((x) => x.visibility !== "visible"));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllCoupons();
  }, []);
  // console.log(allCoupons, "allCoupons");
  console.log(orderItems, "orderItems");
  const handleCouponBoxClick = (e) => {
    // Prevent event propagation to inner box
    e.stopPropagation();
  };

  const handleOuterBoxClick = () => {
    // Close the coupon box when clicking anywhere outside inner box
    setCouponBox(false);
  };
  useEffect(() => {
    if (couponBox) {
      // Add 'no-scroll' class to body when couponBox is open
      document.body.classList.add("no-scroll");
    } else {
      // Remove 'no-scroll' class from body when couponBox is closed
      document.body.classList.remove("no-scroll");
    }

    // Cleanup the effect by removing the class when the component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [couponBox]);

  // const checkCoupon = (coupon) => {
  //   const mrpProducts = orderItems.filter((item) => item.finalGrossRate === 0);
  //   const nonMrpProducts = orderItems.filter(
  //     (item) => item.finalGrossRate !== 0
  //   );
  //   console.log(nonMrpProducts, "nonMrpProducts");
  //   let totalMrpPrice = mrpProducts.reduce(
  //     (acc, item) => acc + item.finalPrice * item.count,
  //     0
  //   );
  //   let totalNonMrpMaking = nonMrpProducts.reduce(
  //     (acc, item) => acc + item.making * item.count,
  //     0
  //   );
  //   let totalNonMrpPrice = nonMrpProducts.reduce(
  //     (acc, item) => acc + item.finalPrice * item.count,
  //     0
  //   );

  //   // Apply coupon logic (assuming couponfor can be "mrp" or "non-mrp")
  //   if (coupon.couponfor === "mrp" && totalMrpPrice > coupon.minimum_amt) {
  //     if (coupon.coupon_type === "amount") {
  //       totalMrpPrice -= Math.min(coupon.coupon_amt, totalMrpPrice);
  //       setAppliedCoupon([coupon]);
  //       setCouponBox(!couponBox);
  //     } else {
  //       let totalPriceDeduction = (totalMrpPrice * coupon.coupon_amt) / 100;
  //       totalMrpPrice -= Math.min(totalPriceDeduction, totalMrpPrice);
  //       setAppliedCoupon([coupon]);
  //       setCouponBox(!couponBox);
  //     }
  //   } else if (
  //     coupon.couponfor === "weight" &&
  //     totalNonMrpMaking > coupon.minimum_amt
  //   ) {
  //     if (coupon.coupon_type === "amount") {
  //       totalNonMrpMaking -= Math.min(coupon.coupon_amt, totalNonMrpMaking);
  //       setAppliedCoupon([coupon]);
  //       setCouponBox(!couponBox);
  //     } else {
  //       let totalPriceDeduction = (totalNonMrpMaking * coupon.coupon_amt) / 100;
  //       totalNonMrpMaking -= Math.min(totalPriceDeduction, totalNonMrpMaking);
  //       setAppliedCoupon([coupon]);
  //       setCouponBox(!couponBox);
  //     }
  //   } else {
  //     alert(
  //       `Coupon is not applicable. Minimum ${
  //         coupon.couponfor === "mrp" ? "Mrp amount" : "making"
  //       } should be ${coupon.minimum_amt}`
  //     );
  //     setAppliedCoupon([]);
  //   }

  //   // Update product prices in your orderItems array
  //   // mrpProducts.forEach((item) => {
  //   //   item.finalPrice =
  //   //     parseInt(totalMrpPrice / item.count) / mrpProducts.length;
  //   //   item.discount =
  //   //     parseInt(item.finalPrice) - parseInt(totalMrpPrice / item.count);
  //   //   item.coupon = coupon.coupon_name;
  //   // });

  //   // Sort mrpProducts in descending order based on finalPrice
  //   // Sort mrpProducts in descending order based on finalPrice
  //   // Sort mrpProducts in descending order based on finalPrice
  //   // Sort mrpProducts in descending order based on finalPrice
  //   mrpProducts.sort((a, b) => b.finalPrice - a.finalPrice);

  //   // Initialize remaining discount to apply
  //   let remainingDiscount = totalMrpPrice;

  //   // Calculate total final price of mrpProducts
  //   const totalFinalPrice = mrpProducts.reduce(
  //     (acc, item) => acc + item.finalPrice * item.count,
  //     0
  //   );

  //   // Loop through mrpProducts without applying the coupon
  //   mrpProducts.forEach((item) => {
  //     // Calculate the discounted price for the current item based on its proportion of the total final price
  //     const discountedPrice =
  //       (item.finalPrice / totalFinalPrice) * totalMrpPrice;

  //     // Check if finalPrice after deduction is greater than the discounted price
  //     if (item.finalPrice > discountedPrice) {
  //       // Update the product prices
  //       item.finalPrice = parseFloat(discountedPrice).toFixed(2);
  //       item.price = parseFloat(discountedPrice).toFixed(2);
  //       // item.discount =
  //       //   parseFloat(item.finalPrice / item.count) -
  //       //   parseFloat(discountedPrice);
  //       item.discount = (
  //         parseFloat(item.originalPrice) - parseFloat(discountedPrice)
  //       ).toFixed(2);
  //       item.coupon = coupon.coupon_name;

  //       // Deduct the applied discount from remainingDiscount
  //       remainingDiscount -= discountedPrice;

  //       // Break the loop if the entire discount is applied
  //       if (remainingDiscount <= 0) {
  //         return;
  //       }
  //     } else if (coupon.couponfor == "mrp") {
  //       // Update the product prices based on the individual discount for each item
  //       const individualDiscount =
  //         item.finalPrice - discountedPrice / item.count;
  //       item.finalPrice = parseFloat(discountedPrice / item.count).toFixed(2);
  //       item.price = parseFloat(discountedPrice / item.count).toFixed(2);
  //       // item.discount = individualDiscount;
  //       item.discount = (
  //         parseFloat(item.originalPrice) - parseFloat(individualDiscount)
  //       ).toFixed(2);
  //       item.coupon = coupon.coupon_name;

  //       // Deduct the applied discount from remainingDiscount
  //       remainingDiscount -= discountedPrice;

  //       // Break the loop if the entire discount is applied
  //       if (remainingDiscount <= 0) {
  //         return;
  //       }
  //     }
  //   });

  //   // nonMrpProducts.forEach((item) => {
  //   //   item.making = parseInt(totalNonMrpMaking / item.count);
  //   //   item.finalPrice = parseInt(
  //   //     (totalNonMrpMaking / item.count + item.finalGrossRate) * 1.03
  //   //   );
  //   //   item.discount =
  //   //     parseInt(item.finalPrice) -
  //   //     parseInt((totalNonMrpMaking / item.count + item.finalGrossRate) * 1.03);
  //   //   // item.coupon = coupon.coupon_name;
  //   // });
  //   if (coupon.couponfor !== "mrp") {
  //     nonMrpProducts.sort((a, b) => b.making - a.making);

  //     // Initialize remaining discount to apply for non-MRP products
  //     let remainingNonMrpDiscount = totalNonMrpMaking;

  //     // Calculate total final price of non-MRP products
  //     const totalNonMrpFinalPrice = nonMrpProducts.reduce(
  //       (acc, item) => acc + item.making * item.count,
  //       0
  //     );

  //     // Loop through non-MRP products without applying the coupon
  //     nonMrpProducts.forEach((item) => {
  //       // Calculate the discounted price for the current item based on its proportion of the total final price
  //       const discountedNonMrpPrice =
  //         (item.making / totalNonMrpFinalPrice) * totalNonMrpMaking;

  //       // Check if finalPrice after deduction is greater than the discounted price
  //       if (item.making > discountedNonMrpPrice) {
  //         // Update the product prices for non-MRP products
  //         item.coupon = coupon.coupon_name;
  //         item.making = parseFloat(discountedNonMrpPrice / item.count).toFixed(
  //           2
  //         );
  //         item.finalPrice = (
  //           (parseFloat(discountedNonMrpPrice) +
  //             parseFloat(item.finalGrossRate)) *
  //           1.03
  //         ).toFixed(2);
  //         item.price = (
  //           (parseFloat(discountedNonMrpPrice) +
  //             parseFloat(item.finalGrossRate)) *
  //           1.03
  //         ).toFixed(2);
  //         // item.finalPrice =
  //         //   (item.making + parseFloat(item.finalGrossRate)) * 1.03;
  //         item.discount = (
  //           parseFloat(item.finalPrice / item.count) -
  //           parseFloat(item.finalPrice)
  //         ).toFixed(2);
  //         // item.finalGrossRate =
  //         //   parseFloat(x.originalPrice) -
  //         //   parseFloat(discountedNonMrpPrice / item.count);

  //         // Deduct the applied discount from remainingNonMrpDiscount
  //         remainingNonMrpDiscount -= discountedNonMrpPrice;

  //         // Break the loop if the entire discount is applied
  //         if (remainingNonMrpDiscount <= 0) {
  //           return;
  //         }
  //       }
  //     });
  //   }

  //   const finalTotal = totalMrpPrice + totalNonMrpMaking;
  //   console.log("Final Total after applying coupon: ", finalTotal);
  //   // Update your state or UI with the final total and updated orderItems array
  // };
  // console.log(appliedCoupon, "appliedCoupon");
  // console.log(appliedCoupon.length, "appliedCoupon");
  const checkCoupon = (coupon) => {
    const mrpProducts = orderItems.filter(
      (item) =>
        item.Type === "Mrp" && !item.title.toLowerCase().includes("silver coin")
    );
    const nonMrpProducts = orderItems.filter(
      (item) =>
        item.Type === "Weight" &&
        !item.title.toLowerCase().includes("coin") &&
        !item.title.toLowerCase().includes("silver")
    );

    console.log(nonMrpProducts, "nonMrpProducts");
    let totalMrpPrice = mrpProducts.reduce(
      (acc, item) => acc + item.finalPrice * item.count,
      0
    );
    let totalNonMrpMaking = nonMrpProducts.reduce(
      (acc, item) => acc + item.making * item.count,
      0
    );
    let totalNonMrpPrice = nonMrpProducts.reduce(
      (acc, item) => acc + item.finalPrice * item.count,
      0
    );

    // Apply coupon logic (assuming couponfor can be "mrp" or "non-mrp")
    if (coupon.couponfor === "mrp" && totalMrpPrice > coupon.minimum_amt) {
      if (coupon.coupon_type === "amount") {
        totalMrpPrice -= Math.min(coupon.coupon_amt, totalMrpPrice);
        setAppliedCoupon([coupon]);
        setCouponBox(!couponBox);
      } else {
        let totalPriceDeduction = (totalMrpPrice * coupon.coupon_amt) / 100;
        totalMrpPrice -= Math.min(totalPriceDeduction, totalMrpPrice);
        setAppliedCoupon([coupon]);
        setCouponBox(!couponBox);
      }
    } else if (
      coupon.couponfor === "weight" &&
      totalNonMrpMaking > coupon.minimum_amt
    ) {
      if (coupon.coupon_type === "amount") {
        totalNonMrpMaking -= Math.min(coupon.coupon_amt, totalNonMrpMaking);
        setAppliedCoupon([coupon]);
        setCouponBox(!couponBox);
      } else {
        let totalPriceDeduction = (totalNonMrpMaking * coupon.coupon_amt) / 100;
        totalNonMrpMaking -= Math.min(totalPriceDeduction, totalNonMrpMaking);
        setAppliedCoupon([coupon]);
        setCouponBox(!couponBox);
      }
    } else if (mrpProducts.length == 0 && nonMrpProducts.length == 0) {
      alert(`Coupon is not applicable on Discounted Products`);
      setAppliedCoupon([]);
    } else {
      alert(
        `Coupon is not applicable. Minimum ${
          coupon.couponfor === "mrp" ? "Mrp amount" : "making"
        } should be ${coupon.minimum_amt}`
      );
      setAppliedCoupon([]);
    }

    // Update product prices in your orderItems array
    // mrpProducts.forEach((item) => {
    //   item.finalPrice =
    //     parseInt(totalMrpPrice / item.count) / mrpProducts.length;
    //   item.discount =
    //     parseInt(item.finalPrice) - parseInt(totalMrpPrice / item.count);
    //   item.coupon = coupon.coupon_name;
    // });

    // Sort mrpProducts in descending order based on finalPrice
    // Sort mrpProducts in descending order based on finalPrice
    // Sort mrpProducts in descending order based on finalPrice
    // Sort mrpProducts in descending order based on finalPrice
    mrpProducts.sort((a, b) => b.finalPrice - a.finalPrice);

    // Initialize remaining discount to apply
    let remainingDiscount = totalMrpPrice;

    // Calculate total final price of mrpProducts
    const totalFinalPrice = mrpProducts.reduce(
      (acc, item) => acc + item.finalPrice * item.count,
      0
    );

    // Loop through mrpProducts without applying the coupon
    mrpProducts.forEach((item) => {
      // Calculate the discounted price for the current item based on its proportion of the total final price
      const discountedPrice =
        (item.finalPrice / totalFinalPrice) * totalMrpPrice;

      // Check if finalPrice after deduction is greater than the discounted price
      if (item.finalPrice > discountedPrice) {
        // Update the product prices
        item.finalPrice = parseFloat(discountedPrice).toFixed(2);
        item.price = parseFloat(discountedPrice).toFixed(2);
        // item.discount =
        //   parseFloat(item.finalPrice / item.count) -
        //   parseFloat(discountedPrice);
        item.discount = (
          parseFloat(item.originalPrice) - parseFloat(discountedPrice)
        ).toFixed(2);
        item.coupon = coupon.coupon_name;

        // Deduct the applied discount from remainingDiscount
        remainingDiscount -= discountedPrice;

        // Break the loop if the entire discount is applied
        if (remainingDiscount <= 0) {
          return;
        }
      } else if (coupon.couponfor == "mrp") {
        // Update the product prices based on the individual discount for each item
        const individualDiscount =
          item.finalPrice - discountedPrice / item.count;
        item.finalPrice = parseFloat(discountedPrice / item.count).toFixed(2);
        item.price = parseFloat(discountedPrice / item.count).toFixed(2);
        // item.discount = individualDiscount;
        item.discount = (
          parseFloat(item.originalPrice) - parseFloat(individualDiscount)
        ).toFixed(2);
        item.coupon = coupon.coupon_name;

        // Deduct the applied discount from remainingDiscount
        remainingDiscount -= discountedPrice;

        // Break the loop if the entire discount is applied
        if (remainingDiscount <= 0) {
          return;
        }
      }
    });

    // nonMrpProducts.forEach((item) => {
    //   item.making = parseInt(totalNonMrpMaking / item.count);
    //   item.finalPrice = parseInt(
    //     (totalNonMrpMaking / item.count + item.finalGrossRate) * 1.03
    //   );
    //   item.discount =
    //     parseInt(item.finalPrice) -
    //     parseInt((totalNonMrpMaking / item.count + item.finalGrossRate) * 1.03);
    //   // item.coupon = coupon.coupon_name;
    // });
    if (coupon.couponfor !== "mrp") {
      nonMrpProducts.sort((a, b) => b.making - a.making);

      // Initialize remaining discount to apply for non-MRP products
      let remainingNonMrpDiscount = totalNonMrpMaking;

      // Calculate total final price of non-MRP products
      const totalNonMrpFinalPrice = nonMrpProducts.reduce(
        (acc, item) => acc + item.making * item.count,
        0
      );

      // Loop through non-MRP products without applying the coupon
      nonMrpProducts.forEach((item) => {
        // Calculate the discounted price for the current item based on its proportion of the total final price
        const discountedNonMrpPrice =
          (item.making / totalNonMrpFinalPrice) * totalNonMrpMaking;

        // Check if finalPrice after deduction is greater than the discounted price
        if (item.making > discountedNonMrpPrice) {
          // Update the product prices for non-MRP products
          item.coupon = coupon.coupon_name;
          item.making = parseFloat(discountedNonMrpPrice / item.count).toFixed(
            2
          );
          item.finalPrice = (
            (parseFloat(discountedNonMrpPrice) +
              parseFloat(item.finalGrossRate)) *
            1.03
          ).toFixed(2);
          item.price = (
            (parseFloat(discountedNonMrpPrice) +
              parseFloat(item.finalGrossRate)) *
            1.03
          ).toFixed(2);
          // item.finalPrice =
          //   (item.making + parseFloat(item.finalGrossRate)) * 1.03;
          item.discount = (
            parseFloat(item.finalPrice / item.count) -
            parseFloat(item.finalPrice)
          ).toFixed(2);
          // item.finalGrossRate =
          //   parseFloat(x.originalPrice) -
          //   parseFloat(discountedNonMrpPrice / item.count);

          // Deduct the applied discount from remainingNonMrpDiscount
          remainingNonMrpDiscount -= discountedNonMrpPrice;

          // Break the loop if the entire discount is applied
          if (remainingNonMrpDiscount <= 0) {
            return;
          }
        }
      });
    }

    const finalTotal = totalMrpPrice + totalNonMrpMaking;
    console.log("Final Total after applying coupon: ", finalTotal);
    // Update your state or UI with the final total and updated orderItems array
  };
  const handleCouponSearch = (e) => {
    let { value } = e.target;

    // value = value.replace(/\s+/g, "");
    value = value.trim();
    setCouponCode(value);

    if (value.trim() === "") {
      // If the input is empty, show all visible coupons
      setVisibleCoupons(allCoupons.filter((x) => x.visibility === "visible"));
    } else {
      // If there is a value in the input, filter coupons by coupon_name
      const filteredCoupons = allCoupons.filter(
        (x) =>
          (x.coupon_name.toLowerCase() === value.toLowerCase() &&
            x.visibility === "hidden") ||
          (x.coupon_name.toLowerCase() === value.toLowerCase() &&
            x.visibility === "visible")
      );

      setVisibleCoupons(filteredCoupons);
    }
  };
  const removeCoupon = () => {
    orderItems.map((x) => {
      x.price = x.originalPrice;
      x.finalPrice = x.originalPrice;
      x.making = x.originalMaking;
      x.discount = 0;
      x.coupon = "";
      x.finalGrossRate = x.originalFinalGrossRate;
    });
    setAppliedCoupon([]);
  };
  const widthThreshold = 600; // Set your desired width threshold

  const isWidthLessThanThreshold = window.innerWidth < widthThreshold;
  return (
    <div className="checkOutMainBox">
      <div className="headingBox">
        <Heading />
      </div>
      <>
        {couponBox ? (
          <div
            className="orderSummaryCheckoutMainCouponBox"
            onClick={handleOuterBoxClick}
          >
            <div
              className="orderSummaryCheckoutMainCouponInnerBox"
              onClick={handleCouponBoxClick}
            >
              <h4>Apply Offer / Voucher </h4>

              <RxCross2
                onClick={() => setCouponBox(false)}
                size={"18px"}
                className="orderSummaryCheckoutMainCouponInnerBoxCrossIcon"
              />
              <div className="orderSummaryCheckoutMainCouponInnerBoxUnderline"></div>
              <div className="orderSummaryCheckoutMainCouponInnerSearchBox">
                <input
                  value={couponCode.toUpperCase()}
                  onChange={(e) => handleCouponSearch(e)}
                  placeholder="Enter Offer / Voucher"
                  type="text"
                />
                <button className="orderSummaryCheckoutMainCouponInnerSearchBoxButton">
                  APPLY
                </button>
              </div>
              <div className="orderSummaryCheckoutMainCouponInnerOrLineBox">
                <div className="orderSummaryCheckoutMainCouponInnerOrLine"></div>
                <h5>Or</h5>
                <div className="orderSummaryCheckoutMainCouponInnerOrLine"></div>
              </div>
              <h6>Apply Coupon from the list below</h6>
              <div className="orderSummaryCheckoutMainCouponListMainBox">
                {visibleCoupons.map((x) => {
                  return (
                    <div className="orderSummaryCheckoutMainCouponListItemBox">
                      <div className="orderSummaryCheckoutMainCouponListItemLeftBox">
                        <h5>{x.coupon_name}</h5>
                        <p>
                          {" "}
                          {`${x.coupon_amt}${
                            x.coupon_type === "percentage" ? "%" : ""
                          } OFF on ${
                            x.couponfor === "weight"
                              ? "Total Making"
                              : "Mrp Products"
                          }`}
                        </p>
                      </div>
                      <div className="orderSummaryCheckoutMainCouponListItemRightBox">
                        <button onClick={() => checkCoupon(x)}>APPLY</button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
        <div className="checkoutMainContainer">
          <div className="allProductOrderMainCheckoutBox">
            {/* <h4 style={{ margin: "1rem" }}>
              ** Delivery ONLY available within MUMBAI & Suburbs for Now **
            </h4> */}
            <h3 style={{ margin: "1rem" }}>My Bag</h3>
            {orderItems.length === 0 ? (
              <h1 style={{ color: "rgba(0,0,0,0.5" }}>
                Please Add Something in Bag...
              </h1>
            ) : null}
            {orderItems.map((x) => {
              totalPrice += x.finalPrice * x.count;
              totalPriceBefore += x.originalPrice * x.count;
              return (
                <div
                  style={{ padding: "0" }}
                  key={x.id}
                  className="orderSummaryCheckoutMain"
                >
                  <div className="orderSummaryCheckoutImageBox">
                    {/* <> */}
                    {x.Type === "OfferPrice" ? (
                      <div className="orderSummaryCheckoutImageBoxOfferTag">
                        Offer
                      </div>
                    ) : null}
                    <img
                      className="orderSummaryCheckoutImage"
                      src={`${s3}/${x.image}`}
                      // src={tempImg}
                      alt={"ProductImage"}
                    />
                    <div className="orderSummaryCheckoutProductDetailsBox">
                      <p>{x.title}</p>
                      {/* <p>Product title</p> */}
                      {/* <p>Product Id</p> */}
                      {/* <p>{x.Product_id}</p> */}
                      <span
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "12px",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            margin: "0.5rem",
                            // marginLeft: "1rem",
                          }}
                        >
                          <strong>Size: </strong>
                          <select>
                            {/* <option>{x.size}</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option> */}
                            <option>{x.size ? x.size : "Regular"}</option>
                          </select>

                          <strong>Quantity: </strong>
                          <select>
                            <option>{x.count}</option>
                            {/* <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option> */}
                          </select>
                        </p>
                      </span>
                      {(appliedCoupon.length > 0 && x.coupon !== "") ||
                      (orderItems.filter((x) => x.coupon).length >= 1 &&
                        x.coupon !== "") ? (
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              color: "red",
                              textDecorationLine: "line-through",
                            }}
                          >
                            <strong>
                              ₹ {(x.originalPrice * x.count).toFixed(2)}
                            </strong>
                          </p>
                          <p style={{ color: "green" }}>
                            <strong>
                              ₹ {(x.finalPrice * x.count).toFixed(2)}
                            </strong>
                          </p>
                        </div>
                      ) : x.Type == "OfferPrice" ? (
                        <div style={{ display: "flex" }}>
                          <p
                            style={{
                              color: "red",
                              textDecorationLine: "line-through",
                            }}
                          >
                            <strong>
                              ₹ {(x.originalPrice * x.count).toFixed(2)}
                            </strong>
                          </p>
                          <p style={{ color: "green" }}>
                            <strong>
                              ₹ {(x.finalPrice * x.count).toFixed(2)}
                            </strong>
                          </p>
                        </div>
                      ) : (
                        <p style={{ color: "green" }}>
                          <strong>
                            ₹ {(x.finalPrice * x.count).toFixed(2)}
                          </strong>
                        </p>
                      )}
                    </div>
                  </div>

                  {/* {orderItems.map((x) => {
                    return (
                      <div className="orderSummaryCheckoutImageBox">
                        <img
                          className="orderSummaryCheckoutImage"
                          //   src={`${s1}${x.image1}`}
                          src={tempImg}
                          alt={"ProductImage"}
                        />
                        <div className="orderSummaryCheckoutProductDetailsBox">
                          <p>{x.title}</p>
                          <p>{x.Product_id}</p>
                          <span
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p style={{ color: "black", margin: "1rem" }}>
                              <strong>Size: {x.Size}</strong>
                              <select>
                                <option>First</option>
                                <option>Second</option>
                                <option>Third</option>
                                <option>Fourth</option>
                              </select>
                            </p>
                          </span>
                        </div>
                      </div>
                    );
                  })} */}
                  <div
                    style={{ justifyContent: "center" }}
                    className="orderSummaryCheckoutProductDetailsButtons"
                  >
                    <div className="btnBox1">
                      <button
                        id="redRemoveBtn"
                        onClick={() => handleRemoveFromBag(x)}
                      >
                        Remove
                      </button>
                    </div>
                    <div className="btnBox1">
                      <button>Move to Wishlist</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="orderSummaryCheckoutRightContainer">
            <div className="applyCouponBox"></div>
            <h3 style={{ marginLeft: "1rem" }}>Order Summary</h3>
            {/* ... previous code ... */}
            {orderItems.filter((x) => x.coupon).length === 0 &&
            orderItems.length > 0 ? (
              <div className="orderSummaryCheckoutMainBoxDiscountBox">
                <TbDiscount2
                  size={"22px"}
                  style={{ marginRight: "5px", color: "#1a4c2d" }}
                />{" "}
                <p>Apply Coupon</p>
                <button
                  className="orderSummaryCheckoutMainBoxDiscountRightArrowBox"
                  onClick={() => {
                    setCouponBox(!couponBox);
                  }}
                >
                  <AiOutlineArrowRight size={"18px"} />
                </button>
              </div>
            ) : appliedCoupon.length > 0 ||
              (orderItems.filter((x) => x.coupon).length >= 1 &&
                orderItems.length > 0) ? (
              <div
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
                className="orderSummaryCheckoutMainBoxDiscountBox"
              >
                <TbDiscount2
                  size={"22px"}
                  style={{ marginRight: "5px", color: "#1a4c2d" }}
                />{" "}
                {appliedCoupon.length > 0 ? (
                  <p>{`${appliedCoupon[0].coupon_name} applied`}</p>
                ) : (
                  <p>{`${
                    orderItems.filter((x) => x.coupon !== "")[0].coupon
                  } applied`}</p>
                )}
                <button
                  className="orderSummaryCheckoutMainBoxDiscountRemove"
                  onClick={() => {
                    removeCoupon();
                  }}
                >
                  Remove
                </button>
              </div>
            ) : orderItems.length > 0 ? (
              <div className="orderSummaryCheckoutMainBoxDiscountBox">
                <TbDiscount2
                  size={"22px"}
                  style={{ marginRight: "5px", color: "#1a4c2d" }}
                />{" "}
                <p>Apply Coupon</p>
                <button
                  className="orderSummaryCheckoutMainBoxDiscountRightArrowBox"
                  onClick={() => {
                    setCouponBox(!couponBox);
                  }}
                >
                  <AiOutlineArrowRight size={"18px"} />
                </button>
              </div>
            ) : null}
            {/* ... remaining code ... */}

            <div
              style={{
                paddingBottom: "0px",
                marginTop: "10px",
                paddingBottom: "15px",
                border: "none",
              }}
              className="orderSummaryCheckoutMain orderSummaryCheckoutMain2"
            >
              <div className="orderSummaryCheckoutMainBox">
                <div className="box1">
                  <p>Subtotal</p>
                  {/* <p>You Saved</p> */}
                  {appliedCoupon.length > 0 ? (
                    <p style={{ whiteSpace: "nowrap" }}>Applied Coupon </p>
                  ) : null}
                  {appliedCoupon.length === 0 ? (
                    <p style={{ whiteSpace: "nowrap" }}>Apply Coupon </p>
                  ) : null}
                  <p>Coupon Discount</p>
                  <p>Delivery Charge</p>
                  <p>TOTAL COST</p>
                </div>

                <div className="box2">
                  {appliedCoupon.length > 0 ? (
                    <p>₹ {totalPriceBefore.toFixed(2)}</p>
                  ) : (
                    <p>₹ {totalPrice.toFixed(2)}</p>
                  )}

                  {/* <p>₹ {saved}</p> */}
                  {appliedCoupon.length > 0 ||
                  (orderItems.filter((x) => x.coupon).length >= 1 &&
                    orderItems.length > 0) ? (
                    <p style={{ color: "green", fontWeight: "bold" }}>
                      {`${orderItems.filter((x) => x.coupon !== "")[0].coupon}`}
                    </p>
                  ) : null}
                  {appliedCoupon.length == 0 &&
                  orderItems.length > 0 &&
                  orderItems.filter((x) => x.coupon).length <= 0 ? (
                    <p
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCouponBox(!couponBox);
                      }}
                    >
                      Apply
                    </p>
                  ) : (
                    <p style={{ color: "green", fontWeight: "bold" }}>
                      ₹{" "}
                      {orderItems
                        .reduce(
                          (acc, item) =>
                            acc +
                            (item.originalPrice - item.finalPrice) * item.count,
                          0
                        )
                        .toFixed(2)}
                    </p>
                  )}
                  {appliedCoupon.length <= 0 &&
                  orderItems.length > 0 &&
                  orderItems.filter((x) => x.coupon).length <= 0 ? (
                    <p style={{ color: "green", fontWeight: "bold" }}>₹ 0</p>
                  ) : null}
                  {/* <p>₹ {totalPrice.toFixed(2)}</p> */}
                  {/* <p>₹ {saved}</p> */}
                  {/* <p>₹ {discount}</p> */}
                  <p>₹ {deliveryCharge}</p>
                  <p>
                    ₹{" "}
                    {(totalPrice - saved - discount - deliveryCharge).toFixed(
                      2
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                paddingBottom: "0px",
                paddingTop: "0px",
                marginTop: "10px",
                paddingInline: isWidthLessThanThreshold ? null : "2px",
                border: "none",
              }}
              className="orderSummaryCheckoutMain orderSummaryCheckoutMain2"
            >
              <button
                style={{ margin: "5px" }}
                // className="checkoutSecurelyBtn"
                className="checkoutNewLoginFormBoxButton"
                onClick={() => {
                  if (orderItems.length === 0) {
                    alert("Please Add Something in My Bag");
                  } else if (userDetails.length === 0) {
                    // Redirect to the login/checkout page
                    navigate("/checkoutpagenewlogin");
                  } else {
                    // Redirect to the checkout page
                    navigate("/checkoutpagenew/o2");

                    // Track 'InitiateCheckout' event
                    fbq("track", "InitiateCheckout");
                  }
                }}
              >
                <AiFillLock id="lockIcon" style={{ marginRight: "0.5rem" }} />
                Checkout Securely
              </button>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
}
