import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  a3,
  a5,
  a10,
  paymentApi,
  a46,
  a47,
  a48,
  razorpayPaymentApi,
  a39,
  a66,
} from "../../Api/RootApiPath";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import { useSelector } from "react-redux";
import RazorpayPayment from "../../Api/RazorpayPayment";
// import GooglePayButton from "@google-pay/button-react";
import { SHA256 } from "crypto-js";
import GooglepayButton from "../../Api/ApiImages/GooglepayButton";
import { Dna } from "react-loader-spinner";

export default function PayuPaymentPage() {
  const [csData, setCsData] = useState("");
  // const [orderID, setOrderID] = useState("");
  const [amountPaid, setAmountPaid] = useState(0);
  const [qrData, setQRData] = useState("");
  const [url, setUrl] = useState("");
  const [payNow, setPayNow] = useState(true);

  const orderState = useSelector((state) => state);
  const orderItems = orderState.reducer2;
  const userDetails = orderState.reducer4[0];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  let userOrderCount = 0;
  let userOrderValue = 0;
  let userOrderId = " ";
  let userOrderStatus = " ";
  userOrderCount = orderState.reducer4[0].orderCount;
  userOrderValue = orderState.reducer4[0].orderValue;
  userOrderId = orderState.reducer4[0].orderId;
  userOrderStatus = orderState.reducer4[0].orderStatus;

  const totalPrice = orderItems.reduce((acc, x) => acc + x.price * x.count, 0);
  const navigate = useNavigate();
  let products = "";
  products = orderItems.map(
    (x) => ` ${x.title} : Qty-${x.count} : Price-₹${x.price} `
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [customerId]);
  let updatedOrderCount = `${userOrderCount} ${products} //  `;
  if (userOrderCount == 0) {
    updatedOrderCount = `${products}`;
  } else {
    updatedOrderCount = `${userOrderCount} // ${products} `;
  }
  let updatedOrderValue = `${userOrderValue}, ₹${totalPrice}`;
  if (userOrderValue == 0) {
    updatedOrderValue = `₹${totalPrice}`;
  } else {
    updatedOrderValue = `${userOrderValue} // ₹${totalPrice} `;
  }
  let updatedOrderStatus = "Paid";
  if (userOrderStatus == " ") {
    updatedOrderStatus = "Paid";
  } else {
    updatedOrderStatus = `${userOrderStatus} ${updatedOrderStatus} //`;
  }

  // let couponName = "";

  // // Find the first orderItem with a non-empty coupon property
  // const foundCouponName = orderItems.find((x) => x.coupon !== "");

  // // If a matching orderItem is found, set couponName
  // if (foundCouponName) {
  //   couponName = foundCouponName.coupon;
  // }

  // console.log(couponName, "couponName");

  let quantity = "";
  quantity = orderItems.map((x) => `${x.count},`);
  let itemPrice = "";
  itemPrice = orderItems.map((x) => `${x.price},`);
  const updateOrder = (orderId, amount) => {
    setAmountPaid(amount);
    updateOrderCount(orderId);
  };
  const updateOrderCount = async (orderId) => {
    // let newCount = Number(userOrderCount) + 1;
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "OrderCount");
    // formData.append("FieldValue", updatedOrderCount);
    const formData = {
      Id: customerId,
      OrderCount: updatedOrderCount,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        updateOrderValue(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateOrderValue = async (orderId) => {
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "OrderValue");
    // formData.append("FieldValue", updatedOrderValue);
    const formData = {
      Id: customerId,
      OrderValue: updatedOrderValue,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        updateOrderStatus(orderId);
        // navigate(`/paymentsuccesspage?orderId=${orderId}`);
        // updateOrderDetails(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateOrderStatus = async (orderId) => {
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "OrderStatus");
    // formData.append("FieldValue", updatedOrderStatus);
    const formData = {
      Id: customerId,
      OrderStatus: updatedOrderStatus,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        updateOrderId(orderId);
        // navigate(`/paymentsuccesspage?orderId=${orderId}`);
        // updateOrderDetails(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateOrderId = async (orderId) => {
    let updatedOrderId = " ";
    if (userOrderId == " " || userOrderId == null) {
      updatedOrderId = `${orderId} `;
    } else {
      updatedOrderId = `${userOrderId} ${orderId} //`;
    }
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "OrderId");
    // formData.append("FieldValue", updatedOrderId);
    const formData = {
      Id: customerId,
      OrderId: updatedOrderId,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        // navigate(`/paymentsuccesspage?orderId=${orderId}`);
        // updateOrderDetails(orderId);
        // navigate(`/customerprofile?orderId=${orderId}`);
        updateOrderTime(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateOrderTime = async (orderId) => {
    const currDate = new Date();
    let currDateAndTime = `// ${currDate.toLocaleDateString()} ${currDate.toLocaleTimeString()} `;
    // const formData = new FormData();
    // formData.append("Customer_id", customerId);
    // formData.append("FieldName", "DOB");
    // formData.append("FieldValue", currDateAndTime);
    const formData = {
      Id: customerId,
      Dob: currDateAndTime,
    };
    try {
      const response = await fetch(a5, {
        //   method: "POST",
        //   body: formData,
        // });
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.status == "success") {
        // navigate(`/paymentsuccesspage?orderId=${orderId}`);
        // updateOrderDetails(orderId);
        // updateOrderTime()
        navigate(`/customerprofile?orderId=${orderId}`);
        // orderConfirmationEmail(orderId);
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const orderConfirmationEmail = async (orderId) => {
    // event.preventDefault();
    const config = {
      // Username: "saajanmore03@gmail.com",
      // Password: "AF21BD5AA5EFB6F45CBC233AFDB3CD68337A",
      // Host: "smtp.elasticemail.com",
      // Port: 2525,
      SecureToken: "eb0e35b5-f261-49e7-9a15-70711e96841c",
      To: "saajanmore1995@gmail.com",
      From: "saajanmore03@gmail.com",
      Subject: "This is the subject",
      Body: "And this is the body",
    };
    if (window.Email) {
      window.Email.send(config).then(() =>
        navigate(`/customerprofile?orderId=${orderId}`)
      );
    }
  };
  const productsList = orderItems.map((x) => x.Product_id);
  const productsCount = orderItems.map((x) => x.count);
  const productsPrice = orderItems.map((x) => x.finalPrice);

  console.log("ID", `${productsList}`);
  console.log("count", productsCount);
  console.log("Price", productsPrice);
  // const addOrder = async () => {
  //   const formData = {
  //     Customer_Id: customerId,
  //     // Product_id:
  //   };
  //   try {
  //     const response = await fetch(a5, {
  //       //   method: "POST",
  //       //   body: formData,
  //       // });
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });
  //     const data = await response.json();
  //     if (data.status == "success") {
  //       // navigate(`/paymentsuccesspage?orderId=${orderId}`);
  //       // updateOrderDetails(orderId);
  //       // updateOrderTime()
  //       navigate(`/customerprofile?orderId=${orderId}`);
  //       // orderConfirmationEmail(orderId);
  //     } else {
  //       console.error(data.error);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // useEffect(async () => {
  //   await fetch(a10);
  // }, []);
  // RazorPay Working Code Below
  //   console.log("Razorpay:", window.Razorpay);
  //   console.log("Razorpay:", window.Razorpay);
  //   const handlePayment = async (amount, mobile, id, orderId) => {
  //     // const data = await fetch(`${paymentApi}/razorpay`, {

  //     const formData = {
  //       orderId: orderId,
  //       amount: parseFloat(amount),
  //     };
  //     console.log(formData, "formData for Razorpay");
  //     const data = await fetch(`${razorpayPaymentApi}`, {
  //       method: "POST",
  //       body: JSON.stringify(formData),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((response) => response.json()) // Parse JSON data from the response
  //       .then((data) => {
  //         console.log(data, "Response data"); // Log the data after JSON parsing
  //         return data; // Continue with the data further if needed
  //       })
  //       .catch((error) => {
  //         console.error("Error during fetch:", error); // Log errors that occur during the fetch
  //       });
  //     const options = {
  //       // key: "rzp_live_jn0S7ZelnBE85s",
  //       key: "rzp_test_cGvSgkLaWmkLNu",
  //       currency: data.currency,
  //       amount: data.amount,
  //       description: "Wallet Transaction",
  //       //   image: `${razorpayPaymentApi}/gharesNewLogo.png`,
  //       order_id: data.id,
  //       handler: function (response) {
  //         const paymentId = response.razorpay_payment_id;
  //         const orderId = response.razorpay_order_id;
  //         // setOrderID(orderId);
  //         alert("Payment Successful! Order ID: " + orderId);
  //         updateOrder(orderId, amount);
  //         window.location.href = `/paymentsuccesspage?orderId=${orderId}&custId=${customerId}`;
  //       },
  //       prefill: {
  //         // name: `${FirstName} ${LastName}`,
  //         // email: `${Email}`,
  //         contact: `${mobile}`,
  //       },
  //     };
  //     const paymentObject = new window.Razorpay(options);
  //     paymentObject.open();
  //   };

  // GPT Razorpay
  const loadRazorpay = (callback) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      console.log("Razorpay SDK loaded.");
      callback(); // Call the callback function once the script is loaded
    };
    script.onerror = () => {
      console.error("Error loading Razorpay SDK");
    };
    document.body.appendChild(script);
  };

  const handlePayment = async (amount, mobile, id, orderId) => {
    startPaymentProcess(amount, mobile, id, orderId);
  };

  const updateOrderSuccess = async (orderId) => {
    // const formData = new FormData();
    // formData.append("Id", Customer_id);
    const formData = {
      orderNumber: orderId,
      OrderStatus: "Paid",
    };

    try {
      console.log(formData);
      const response = await fetch(a39, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data) {
        window.location.href = `/paymentsuccesspage?orderId=${orderId}&custId=${customerId}&mode=razorpay`;
      }

      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      // navigate("/checkoutpage2");
      // alert(error);
    }
  };
  const updateOrderFailed = async (orderId) => {
    // const formData = new FormData();
    // formData.append("Id", Customer_id);
    const formData = {
      orderNumber: orderId,
      OrderStatus: "Payment Failed",
    };

    try {
      console.log(formData);
      const response = await fetch(a39, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      window.location.href = `/paymentfailedpage?orderId=${orderId}&custId=${customerId}&mode=PayU`;

      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      // navigate("/checkoutpage2");
      // alert(error);
    }
  };

  const startPaymentProcess = async (amount, mobile, id, orderId) => {
    // Your existing code for processing the payment
    const formData = {
      amount: amount,
      mobile: mobile,
      id: id,
      orderNumber: orderId,
    };

    try {
      console.log(formData);
      const response = await fetch(a66, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      console.log(data, "data");
      console.log(data, "data");
      console.log(data, "data");
      console.log(data, "data");
      if (data) {
        window.location.href = `${data.requestMessage.requestUri}`;
      }

      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      // navigate("/checkoutpage2");
      // alert(error);
    }
  };

  // Call handlePayment with appropriate parameters
  //   handlePayment(100, "9999999999", "123", "order123");

  // GPT Razorpay

  // PHONEPAY CODE BELOW

  //   const handlePayment = async (amount, mobile, id, orderId) => {
  //     // alert("An error Occured,");
  //     const requestOptions = {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         /* your payload data */

  //         amount: amount.toString(),
  //         mobile,
  //         id,
  //         orderId,
  //       }),
  //     };
  //     // console.log(requestOptions, "requestOptions");
  //     fetch(a48, requestOptions)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log(data);

  //         setUrl(data.data.instrumentResponse.redirectInfo.url);

  //         window.location.href = data.data.instrumentResponse.redirectInfo.url;
  //       })
  //       .catch((error) => {
  //         // Handle any errors
  //         console.error(error);
  //       });
  //   };
  // console.log(productsList);
  let totalDiscount = 0;
  const insertOrder = async (totalPrice, mobile, csId) => {
    // e.preventDefault();
    const foundOrderItem = orderItems.find((x) => x.coupon !== "");
    orderItems.forEach((x) => {
      // Check if the current item has a coupon
      if (x.coupon !== "") {
        // Add the discount of the current item to the totalDiscount
        totalDiscount += parseFloat(x.discount);
      }
    });
    // Set couponName based on the condition
    const couponName =
      foundOrderItem && foundOrderItem.coupon ? foundOrderItem.coupon : null;
    setPayNow(false);
    const formData = {
      Customer_id: customerId,
      CategoryName: "Gold",
      // Product_id: `${productsList}`,
      Product_id: parseInt(orderItems[0].Product_id),
      Qty: orderItems[0].count,
      Price: totalPrice,
      // PaymentMode: "RazorPay",
      PaymentMode: "PayU",
      Offer: "Offer",
      GovtTax: "GovtTax",
      ReceivedAmt: totalPrice,
      OrderStatus: "Payment Pending",
      couponName: couponName,
      coupondiscount: `${totalDiscount}`,
    };

    try {
      console.log("FORMDATA", formData);
      const response = await fetch(a46, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // const data = await response.json();
      // console.log(data.data);
      // console.log(orderIdCreated);
      // handlePayment(totalPrice, mobile, csId, orderIdCreated);
      const rcvdData = await response.json();
      const orderNumberCreated = rcvdData.data.orderNumber;
      const orderData = rcvdData.data;
      // setOrderId(orderData.id);
      console.log(rcvdData.data.id, "1st hit");
      createOrderItems(
        rcvdData.data.id,
        totalPrice,
        mobile,
        csId,
        orderNumberCreated
      );

      // handlePayment(totalPrice, mobile, customerId, orderIdCreated);
      // alert("Order Created");
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(customerId);
  const createOrderItems = async (
    rcvdId,
    totalPrice,
    mobile,
    csId,
    orderNumberCreated
  ) => {
    // e.preventDefault();
    try {
      const orderItemsList = orderItems.map((product) => {
        return {
          OrderId: parseInt(rcvdId),
          ProductId: parseInt(product.Product_id),
          // MRP: `${product.mrp}`,
          // ProductName: product.collection,
          Quantity: `${product.count}`,
          // StoneWeight: product.stoneWeight,
          // HSNCode: `${product.hsn_code}` || "",
          // ItemCode: product.itemCode,
          // grosswt: product.grosswt,
          // netWt: product.netWt,
          // StoneWeight: product.stoneWeight,
          // Purity: product.purity,
          makingchrg: "",
          // Rate: `${product.tblPurity.todaysRate}`,
          // HUIDCode: product.huidCode,
          // Size: product.size,

          // price: `${product.price}`,
        };
      });
      console.log("orderItemsList", orderItemsList);
      const response = await fetch(a47, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderItemsList),
      });
      const rcvdData = await response.json();
      const orderData = rcvdData.data;
      // setOrderItemsData(orderData);
      console.log(orderData, "second Hit");
      // console.log(orderData[0].orderId, "second Hit");
      if (rcvdData.status === "error") {
        // setLoading(false);
        alert(rcvdData.message);
      } else {
        handlePayment(
          totalPrice,
          userDetails.mobile,
          customerId,
          orderNumberCreated
        );
        console.log(rcvdData, "rcvdDta 2nd");
        // console.log(rcvdData.data[0].orderId, "rcvdDta 2nd");
        // showPDFWithId(rcvdData.data[0].orderId);
      }
    } catch (error) {
      alert(error);
      console.error(error);
      // setLoading(false);
    }
  };
  const checkPyt = (totalPrice, mobile, csId, rcvdId) => {
    console.log("checkPyt", totalPrice, mobile, csId, rcvdId);
  };
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="accountDetailsMainBox">
        <div style={{ width: "40vw" }} className="accountDetailsFormBox">
          <h2 style={{ margin: "1rem 0" }}>Pay For Order</h2>

          <p style={{ margin: "2rem 0", alignSelf: "flex-start" }}>
            <strong>Products : </strong>
            {/* {products} */}
            {orderItems.map((x) => (
              <p
                style={{ marginTop: "1rem" }}
              >{`${x.title}, Quantity:${x.count}, Price:${x.price}`}</p>
            ))}
          </p>
          <p style={{ margin: "2rem 0", alignSelf: "flex-start" }}>
            <strong>Total Amount : ₹</strong>
            {totalPrice.toFixed(2)}
          </p>
          <div
            style={{ height: "100px", marginBottom: "2rem" }}
            className={!payNow ? "loading" : "none"}
          >
            <Dna
              className={!payNow ? "loading" : "none"}
              width="150"
              color="#4fa94d"
            />
          </div>
          <p>
            <div className="payentPagePayBtnBox">
              {payNow ? (
                <button
                  className="addToCartBtn payBtn"
                  //   onClick={() =>
                  //     handlePayment(
                  //       totalPrice,
                  //       csData.FirstName,
                  //       csData.LastName,
                  //       csData.Email,
                  //       csData.mobile,
                  //       csData.id
                  //     )
                  //   }
                  onClick={() => {
                    insertOrder(totalPrice, csData.mobile, csData.id);
                  }}
                >
                  Pay Now
                </button>
              ) : null}
              <button
                className="addToCartBtn payBtn"
                onClick={() => navigate("/shoppage")}
              >
                Cancel
              </button>
              {/* <GooglepayButton /> */}
            </div>
          </p>
          <div className="clear"></div>
        </div>
      </div>

      {/* {qrData ? (
          <img src={`data:image/png;base64,${qrData}`} alt="QR Code" />
        ) : (
          <p>Loading QR code...</p>
        )} */}
      <Footer />
    </div>
  );
}
