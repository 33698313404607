import React, { useEffect, useState } from "react";
import Heading from "../Heading/Heading";
import Footer from "./Footer";
import "../Components Styles/Footer.css";

export default function PrivacyPolicy() {
  useEffect(() => window.scrollTo(0, 0), []);

  const topics = [
    {
      title: "Collection of Personal Information",
      content:
        "When you visit our website, we may collect certain information about your device, including your IP address, browser type, and operating system. We may also collect information about your browsing behavior on our website, such as the pages you visit and the links you click. If you make a purchase from us, we will collect personal information such as your name, address, email address, phone number, and payment information. SPLIT_POINT You have access to the personal data that M.K.Ghare Jewellers - Ghare Jewellers Private Limited has gathered about you and the option to remedy any inaccuracies. Please contact us if you would like instructions on how to access and/or modify your personal information.",
    },
    {
      title: "Use of Personal Information",
      content:
        'We use personal information that we collect to provide our services, process orders, and communicate with our customers. We may also use personal information to improve our website and marketing efforts. SPLIT_POINT We may use your email address to send you promotional emails and updates about our products and services. You can opt-out of these communications at any time by clicking the "unsubscribe" link in the email.',
    },
    {
      title: "Disclosure of Personal Information",
      content:
        "We may share personal information with third-party service providers that help us operate our business. For example, we may use a shipping company to deliver your order, or a payment processing company to process your payment. SPLIT_POINT We may also disclose personal information if required by law, to protect our rights or the rights of others, or in the event of a merger or acquisition.",
    },
    {
      title: "Data Security",
      content:
        "We take reasonable measures to protect personal information from unauthorized access, use, and disclosure. However, no security measures are perfect, and we cannot guarantee the security of your personal information.",
    },
    {
      title: "Cookies",
      content:
        "We use cookies and similar technologies to collect information about your browsing behavior on our website. Cookies are small files that are stored on your device when you visit a website. We use cookies to personalize your experience, analyze website usage, and to offer you tailored advertising. SPLIT_POINT You can choose to disable cookies in your browser settings, but this may impact your ability to use certain features of our website.",
    },
    {
      title: "Children's Privacy",
      content:
        "Our website is not intended for children under the age of 18. We do not knowingly collect personal information from children under the age of 18. If we become aware that we have collected personal information from a child under the age of 18, we will take steps to delete the information as soon as possible.",
    },
    {
      title: "Updates to this Privacy Policy",
      content:
        'We may update this Privacy Policy from time to time. We will post the updated Privacy Policy on our website and update the "Effective Date" at the top of the policy. We encourage you to review the Privacy Policy periodically to stay informed about our privacy practices. Any significant changes in the way we handle your information will be communicated to you by a clear announcement on our website or application.',
    },
    {
      title: "Contact Us",
      content:
        "If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at info@mkgharejewellers.com.",
    },
  ];

  const [openTopicIndex, setOpenTopicIndex] = useState(-1);

  const toggleTopic = (index) => {
    if (openTopicIndex === index) {
      setOpenTopicIndex(-1); // Close the topic if it's already open
    } else {
      setOpenTopicIndex(index); // Open the selected topic
    }
  };

  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox termsMain">
        <h1 style={{ marginBottom: "50px" }}>Privacy Policy</h1>
        <div className="terms-items">
          <p>
            At Mkgharejewellers.com/ ghare.com ("M. K. Ghare Jewellers" or
            "website" or "we" or "our") which is in association with M.K.Ghare
            Jewellers and owned by Ghare Jewellers Private Limited, we value
            your privacy and the importance of safeguarding your data. This
            Privacy Policy ("Privacy Policy") describes our privacy practices
            for the activities set out below. As per your rights, we inform you
            how we collect, store, access, and otherwise process information
            relating to individuals. We are committed to protecting your privacy
            in accordance with the highest level of privacy regulation. At
            M.K.Ghare Jewellers - Ghare Jewellers Private Limited, we value the
            trust and confidence our customers place in us.
          </p>
        </div>
        <div className="terms-items">
          {topics.map((topic, index) => (
            <Topic
              key={index}
              title={topic.title}
              content={topic.content}
              isOpen={index === openTopicIndex}
              toggleTopic={() => toggleTopic(index)}
            />
          ))}
          <p>
            <strong>
              <em>
                Thank you for choosing M.K.Ghare Jewellers - Ghare Jewellers
                Private Limited. Happy shopping!
              </em>
            </strong>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const Topic = ({ title, content, isOpen, toggleTopic }) => {
  const splitContent = content.split("SPLIT_POINT");
  const firstParagraph = splitContent[0];
  const secondParagraph = splitContent.length > 1 ? splitContent[1] : null;

  return (
    <div className="topic">
      <h3 className="policiesTopicTitle" onClick={toggleTopic}>
        <span>{title}</span>
        <span>{isOpen ? "▲" : "▼"}</span>
      </h3>
      {isOpen && (
        <div>
          <p className="topic-content">{firstParagraph}</p>
          {secondParagraph && (
            <p className="topic-content">{secondParagraph}</p>
          )}
        </div>
      )}
    </div>
  );
};
