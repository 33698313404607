import React from "react";
import "../Components Styles/Heading.css";
import { useNavigate } from "react-router-dom";

export default function UpperTagLine() {
  const navigate = useNavigate();
  return (
    <div className="subscribeNow">
      {/* <span>
        Monthly Installment Scheme (Pay 10 installments, get 100% off on the
        11th installments <span /> */}
      <span style={{backgroundColor: '#1a4c2d'}}>
        ⭐BLACK FRIDAY SALE IS HERE! USE CODE : BLACKFRIDAY{" "}
        <span />
        <span
        style={{backgroundColor: '#1a4c2d'}}
          onClick={() => {
            navigate("/black_friday_sale");
          }}
          // className="coming-soon"
          id="subscribeNowLink"
        >
          (Discover it today!) ⭐
        </span>
      </span>
    </div>
  );
}
