import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import { CiMobile3 } from "react-icons/ci";
import { HiOutlineMailOpen } from "react-icons/hi";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { s2 } from "../../Api/RootApiPath";

export default function StoreLocater() {
  useEffect(() => window.scrollTo(0, 0), []);
  const email = "info@mkgharejewellers.com";
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox">
        <div className="sectionContactHeader">
          <h1>Store Locator</h1>

          <div className="OurContactMainContainer">
            <div className="ourContactItems">
              <a href="tel:+917878780606">
                <CiMobile3 size={"3rem"} />
                <h4>Phone Number:</h4>
                <p>+91-78678-69030 (hotline)</p>
                <p>+91-78787-80606</p>
              </a>
            </div>
            <div className="ourContactItems">
              <CiLocationOn size={"3rem"} color="grey" />
              <h4>Head Office:</h4>
              <p>
                Room 4/5, Sandesh Bhuvan, Bajaj Cross Road, Kandivali West,
                Mumbai, Maharashtra
              </p>
              <p>400067, India</p>
            </div>
            <div className="ourContactItems">
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CiMail size={"2.5rem"} />
                <h4>Email Support:</h4>
                <p>info@mkgharejewellers.com</p>
              </a>
            </div>
            <div className="ourContactItems">
              <h4> Kandivali:</h4>
              <p>
                Shop No 1, Sandesh Bhuvan, Bajaj Cross Road, Kandivali West,
              </p>
              <p>Mumbai 400067, India</p>
              <p>+91 22 2861 0519 </p>
              <p>info@mkgharejewellers.com</p>
            </div>
            <div className="ourContactItems">
              <h4>Parel:</h4>
              <p>3, Anil Bhuvan, Jerbai Vadiya road, </p>
              <p>Parel, Mumbai 12</p>
              <p>+91 22 2412 4749</p>
              <p>info@mkgharejewellers.com</p>
            </div>
            <div className="ourContactItems">
              <h4>Bhandup:</h4>
              <p>
                Shop No. 3/4, Ground Floor, Sachdev Complex, Jangle-Mangle Road,
              </p>
              <p>Bhandup (W), Mumbai 78</p>
              <p>+91 9137028881</p>
              <p>info@mkgharejewellers.com</p>
            </div>
            <div className="ourContactItems">
              <h4>Borivali:</h4>
              <p>
                Shop No. 03, Om Malaygiri Apartment, Sant. Nyaneshwar Marg, Near
                Shri Krushan Nagar, Shantivan,
              </p>
              <p> Borivali (E)</p>
              <p>+91 8454911603</p>
              <p>info@mkgharejewellers.com</p>
            </div>
            <div className="ourContactItems">
              <h4>Virar:</h4>
              <p>
                Shop No 3/4, Shreepal Shopping Center, Aagashi Road Petrolpump,
                Virar (West),
              </p>
              <p>Palghar 401303</p>
              <p>+91 8408002111</p>
              <p>info@mkgharejewellers.com</p>
            </div>
            <div className="ourContactItems">
              <h4>Mira Road:</h4>
              <p>
                Shop No. 7/6, Building A7, Sector 6, Shanti Nagar, Opp. Jain
                Temple,
              </p>
              <p> Mira Road,</p>
              <p>Thane-07</p>
              <p> +91 7400210501 </p>
              <p>info@mkgharejewellers.com</p>
            </div>
            <div className="ourContactItems">
              <h4>Nallasopara:</h4>
              <p>
                Shop No. 7/8, Sai Kiran A/B Co-Op.Hsg. Society Ltd., Tulinj Road
              </p>
              <p>Nallasopara (E)</p>
              <p>+91 7373078078</p>
              <p>+91 0250 2437419 | 20</p>
              <p>info@mkgharejewellers.com</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
