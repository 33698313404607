import { combineReducers, createStore } from "redux";
import { reducer1 } from "../reducer/reducer1";
import { reducer2 } from "../reducer/reducer2";
import { reducer3 } from "../reducer/reducer3";
import { reducer4 } from "../reducer/reducer4";
import { reducer5 } from "../reducer/reducer5";
import { reducer6 } from "../reducer/reducer6";
import { reducer7 } from "../reducer/reducer7";

const combo = combineReducers({
  reducer1,
  reducer2,
  reducer3,
  reducer4,
  reducer5,
  reducer6,
  reducer7,
});

export const myStore = createStore(combo);
