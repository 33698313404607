import React, { useRef, useEffect } from "react";
import { IoLogoWhatsapp } from "react-icons/io5";
import { CiMobile3 } from "react-icons/ci";
import { HiOutlineMailOpen } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import { s2 } from "../../Api/RootApiPath";
import { Link } from "react-router-dom";

export default function ContactUs() {
  const email = "info@mkgharejewellers.com";

  // <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
  //   Contact Us
  // </a>;

  return (
    <div className="contactUsMain">
      <div className="contactUsItems">
        <div className="contactUsIcon">
          <a
            id="whatsappLogo"
            aria-label="Chat on WhatsApp"
            href="https://wa.me/917867869030?text=I'm%20interested%20in%20your%20Products"
            target="_blank"
          >
            <IoLogoWhatsapp size={"2.5rem"} />
          </a>
        </div>
        <p>WHATSAPP</p>
      </div>
      <div className="contactUsItems">
        <div className="contactUsIcon">
          <a href="tel:+917867869030">
            <CiMobile3 size={"3rem"} />
          </a>
        </div>
        <p>CALL US</p>
      </div>
      <div className="contactUsItems">
        <div className="contactUsIcon">
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            <HiOutlineMailOpen size={"2.5rem"} />
          </a>
        </div>
        <p>EMAIL ADDRESS</p>
      </div>
      <div className="contactUsItems">
        <div className="contactUsIcon">
          {/* <Link to={`${s2}#section-2`}> */}
          <Link to={`${s2}#section-2`}>
            <CgProfile size={"2.5rem"} />
          </Link>
        </div>
        <p>BOOK AN APPOINTMENT</p>
      </div>
    </div>
  );
}
