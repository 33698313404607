import React, { useEffect, useRef } from "react";
import Footer from "../../Footer/Footer";
import Heading from "../../Heading/Heading";
import "./ContactUsStyles/OurContact.css";
import { CiMobile3 } from "react-icons/ci";
import { HiOutlineMailOpen } from "react-icons/hi";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { s2 } from "../../Api/RootApiPath";
console.log(s2);
export default function OurContactDetails() {
  const sectionRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash && sectionRef.current) {
      sectionRef.current.scrollIntoView();
    }
  }, []);
  const email = "info@mkgharejewellers.com";
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox">
        <div className="sectionContactHeader">
          <h1>Contact Us</h1>
          <p>
            Together we Grow, Together we Prosper, Our Customers Trust M. K.
            Ghare Since 1933
          </p>
          <div class="OurContactMainContainer">
            <div class="ourContactItems">
              <a href="tel:+917878780606">
                <CiMobile3 size={"3rem"} />
                <h4>Phone Number:</h4>
                <p>+91-78678-69030 (hotline)</p>
                <p>+91-78787-80606</p>
              </a>
            </div>
            <div class="ourContactItems">
              <CiLocationOn size={"3rem"} color="grey" />
              <h4>Store Locations:</h4>
              <p>
                Sandesh Bhuvan, Shop No 1, Bajaj Cross Road, Kandivali West,
                Mumbai, Maharashtra 400067, India
              </p>
            </div>
            <div class="ourContactItems">
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CiMail size={"2.5rem"} />
                <h4>Email Support:</h4>
                <p>info@mkgharejewellers.com</p>
              </a>
            </div>
            <div class="ourContactItems">
              <h4> Kandivali:</h4>
              <p>
                Shop No 1, Sandesh Bhuvan, Bajaj Cross Road, Kandivali West,
                Mumbai 400067, India PhoneNo: +91 22 2861 0519
                info@mkgharejewellers.com
              </p>
            </div>
            <div class="ourContactItems">
              <h4>Parel:</h4>
              <p>
                3, Anil Bhuvan, Jerbai Vadiya road, Parel, Mumbai 400012,
                PhoneNo: +91 22 2412 3173 info@mkgharejewellers.com
              </p>
            </div>
            <div class="ourContactItems">
              <h4>Bhandup:</h4>
              <p>
                Shop No. 3/4, Ground Floor, Sachdev Complex, Jangle-Mangle Road,
                Bhandup (W), Mumbai 78, PhoneNo: +91 22 2594 5666
                info@mkgharejewellers.com
              </p>
            </div>
            <div class="ourContactItems">
              <h4>Borivali:</h4>
              <p>
                Shop No. 03, Om Malaygiri Apartment, Sant. Nyaneshwar Marg, Near
                Shri Krushan Nagar, Shantivan, Borivali (E), PhoneNo: +91 22
                2861 0519 info@mkgharejewellers.com
              </p>
            </div>
            <div class="ourContactItems">
              <h4>Virar:</h4>
              <p>
                Shop No 3/4, Shreepal Shopping Center, Aagashi Road Petrolpump,
                Virar (West), Palghar 401303, PhoneNo: - 0250 250 4688
                info@mkgharejewellers.com
              </p>
            </div>
            <div class="ourContactItems">
              <h4>Mira Road:</h4>
              <p>
                Shop No. 7/6, Building A7, Sector 6, Shanti Nagar, Opp. Jain
                Temple, Mira Road, Thane-07, PhoneNo: +91 22 2810 6101
                info@mkgharejewellers.com
              </p>
            </div>
            <div class="ourContactItems" id="section-2" ref={sectionRef}>
              <h4>Nallasopara:</h4>
              <p>
                Shop No. 7/8, Sai Kiran A/B Co-Op.Hsg. Society Ltd., Narayan
                Nagar, Near Utsav Hotel, Nallasopara (E), PhoneNo: +91 0250
                2437419 | 20 info@mkgharejewellers.com
              </p>
            </div>
          </div>
          <div>
            <h1>Get in touch</h1>
            <p>Your email address will no be published.</p>
            <div className="form-container">
              <div className="form-item">
                {/* <label htmlFor="name">Name:</label> */}
                <input
                  placeholder="Name..."
                  type="text"
                  id="name"
                  name="name"
                />
              </div>
              <div className="form-item">
                {/* <label htmlFor="email">Email:</label> */}
                <input
                  placeholder="Email..."
                  type="email"
                  id="email"
                  name="email"
                />
              </div>
              <div className="form-item">
                {/* <label htmlFor="phone">Phone:</label> */}
                <input
                  placeholder="Phone Number..."
                  type="tel"
                  id="phone"
                  name="phone"
                />
              </div>
              <div className="form-item form-item--full">
                {/* <label htmlFor="message">Message:</label> */}
                <textarea
                  placeholder="Your review..."
                  id="message"
                  name="message"
                  rows="5"
                />
              </div>
            </div>
            <button
              style={{ marginLeft: "0" }}
              className="addToCartBtn ourContactBtn"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
