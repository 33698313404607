import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { useState, useEffect } from "react";
import { a16, s3 } from "../../Api/RootApiPath";
import { ColorRing } from "react-loader-spinner";
import { MdSort } from "react-icons/md";
import { FiFilter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

export default function CategoryPage() {
  const { category } = useParams();
  const { itemtype } = useParams();
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedPriceRanges, setSelectedPriceRanges] = useState(["ALL"]);
  const [selectedMetalRanges, setSelectedMetalRanges] = useState(["ALL"]);
  const [selectedCategoryRanges, setSelectedCategoryRanges] = useState(["ALL"]);
  const [selectedPurityRanges, setSelectedPurityRanges] = useState(["ALL"]);
  const [selectedGenderRanges, setSelectedGenderRanges] = useState(["ALL"]);
  const [selectedSortOption, setSelectedSortOption] = useState("Recommended");
  const [isDropdownOpen, setIsDropdownOpen] = useState("");
  const [listItems, setListItems] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllProductsData();
  }, []);


  

  console.log(allProducts);

  let allImages = "";

  const fetchAllProductsData = async () => {
    try {
      const response = await fetch(a16);
      const data = await response.json();
      setAllProducts(data.data.reverse());
      setLoading(false);
    } catch (error) {
      console.log("Error fetching products data: ", error);
    }
  };
  const newArray = allProducts.filter((item, index, array) => {
    // Trim leading and trailing spaces from the product_Name
    const trimmedName = item.product_Name.trim();

    // Check if the trimmedName matches the corresponding filterName
    // const isFiltered = filterType.every(
    //   (type, i) => trimmedName === filterName[i]
    // );

    // Find the index of the first occurrence of the current product_Name
    const firstIndex = array.findIndex(
      (elem) => elem.product_Name.trim() === trimmedName
    );

    // Determine if the current object is the first occurrence
    const isFirstOccurrence = index === firstIndex;

    // return isFiltered && isFirstOccurrence;
    return isFirstOccurrence;
  });

  let filteredProductsCategory = newArray.filter((product) => {
    const categoryName = product.product_Name.split(" ").pop().toLowerCase();
    console.log("Category Name:", categoryName);
    console.log("Matching:", categoryName === category.toLowerCase());

    if (category.toLowerCase() === "gift") {
      return categoryName === "gift" || categoryName === "coin";
    }

    return categoryName.toLowerCase() === category.toLowerCase();
  });

  if (category.toLowerCase() === "coin" || category.toLowerCase() === "gift") {
    // Prioritize "24KT Pure Gold Coins" by moving them to the beginning of the array
    let coins = filteredProductsCategory.filter(
      (product) => product.itemType === "24KT Pure Gold Coins"
    );
    let rest = filteredProductsCategory.filter(
      (product) => product.itemType !== "24KT Pure Gold Coins"
    );
    filteredProductsCategory = [...coins, ...rest];
  }

  console.log(filteredProductsCategory, "filteredProductsCategory");

  const calculateFinalPrice = (
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    offerPrice,
    purityRate,
    id,
    phuid
  ) => {
    // Parse input values to float and handle NaN cases
    netWt = parseFloat(netWt) || 0;
    making_per_gram = parseFloat(making_per_gram) || 0;
    making_Percentage = parseFloat(making_Percentage) || 0;
    making_Fixed_Amt = parseFloat(making_Fixed_Amt) || 0;
    making_Fixed_Wastage = parseFloat(making_Fixed_Wastage) || 0;
    stoneAmount = parseFloat(stoneAmount) || 0;
    mrp = parseFloat(mrp) || 0;
    offerPrice = parseFloat(offerPrice) || 0;
    purityRate = parseFloat(purityRate) || 0;

    let netGoldRate = (netWt * purityRate) / 10;
    let makingCharges1 = netWt * making_per_gram;
    let makingCharges2 = (netGoldRate * making_Percentage) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 = (purityRate * making_Fixed_Wastage) / 10;
    let GST = 0.03;

    let grossTotalRate =
      netGoldRate +
      makingCharges1 +
      makingCharges2 +
      makingCharges3 +
      makingCharges4 +
      stoneAmount;

    let finalPrice = 0;

    // if (offerPrice !== 0) {
    //   finalPrice = offerPrice;
    // } else
    if (mrp !== 0) {
      finalPrice = mrp;
    }
    if (
      // offerPrice === 0 &&
      mrp === 0
    ) {
      finalPrice = grossTotalRate; // Set final price to grossTotalRate
    }

    // let GSTAdded = GST * grossTotalRate;
    // finalPrice += GSTAdded;

    return finalPrice.toFixed(0);
  };

  const updatedData0 = filteredProductsCategory.map((product) => ({
    ...product,
    purityRate: parseFloat(product.tblPurity.todaysRate),
  }));

  const updatedData = updatedData0.map((product) => ({
    ...product,
    sellPrice: calculateFinalPrice(
      parseFloat(product.netWt),
      parseFloat(product.making_per_gram),
      parseFloat(product.making_Percentage),
      parseFloat(product.making_Fixed_Amt),
      parseFloat(product.making_Fixed_Wastage),
      parseFloat(product.stoneAmount),
      parseFloat(product.mrp),
      parseFloat(product.offerPrice),
      parseFloat(product.purityRate),
      product.id,
      product.phuid
    ),
  }));

  let allPrices = [
    "ALL",
    "Under 1000",
    "1000-2000",
    "2000-5000",
    "5000-20000",
    "20000-50000",
    "Above 50000",
  ];
  let metals = ["ALL", "Gold", "Silver"];
  let categories = [
    "ALL",
    "Coin",
    "Mangalsutra",
    "Fancy Chain",
    "Anklet",
    "Bracelet",
    "Chain",
    "Kada",
    "Earrings",
    "Studs",
    "Rings",
    "Ganpati Ornaments",
    "Bikhbali",
  ];
  // let purities = ["ALL", "Gold", "Silver"];
  let purities = ["ALL", "92.5%", "24CT", "85%", "22CT", "80%"];
  let purities2 = ["ALL", "24CT", "22CT", "92.5%", "85%", "80%"];
  let sortBy = ["Recommended", "Popularity", "Lowest Price", "Highest Price"];
  let genders = ["ALL", "Male", "Female", "Unisex", "Kids"];

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  useEffect(() => {
    const updatedData0 = filteredProductsCategory.map((product) => ({
      ...product,
      purityRate: parseFloat(product.tblPurity.todaysRate),
    }));

    const updatedData = updatedData0.map((product) => ({
      ...product,
      sellPrice: calculateFinalPrice(
        parseFloat(product.netWt),
        parseFloat(product.making_per_gram),
        parseFloat(product.making_Percentage),
        parseFloat(product.making_Fixed_Amt),
        parseFloat(product.making_Fixed_Wastage),
        parseFloat(product.stoneAmount),
        parseFloat(product.mrp),
        parseFloat(product.offerPrice),
        parseFloat(product.purityRate),
        product.id,
        product.phuid
      ),
    }));

    let tempFilteredProducts = updatedData;
    if (!selectedPriceRanges.includes("ALL")) {
      tempFilteredProducts = tempFilteredProducts.filter((product) => {
        const price = parseFloat(product.sellPrice);
        return selectedPriceRanges.some((range) => {
          if (range === "Under 1000" && price <= 1000) {
            return true;
          } else if (range === "1000-2000" && price > 1000 && price <= 2000) {
            return true;
          } else if (range === "2000-5000" && price > 2000 && price <= 5000) {
            return true;
          } else if (range === "5000-20000" && price > 5000 && price <= 20000) {
            return true;
          } else if (
            range === "20000-50000" &&
            price > 20000 &&
            price <= 50000
          ) {
            return true;
          } else if (range === "Above 50000" && price > 50000) {
            return true;
          } else {
            return false;
          }
        });
      });
    }

    if (!selectedMetalRanges.includes("ALL")) {
      tempFilteredProducts = tempFilteredProducts.filter((product) =>
        selectedMetalRanges.includes(product.category_Name)
      );
    }
    if (selectedCategoryRanges.includes("Ganpati Ornaments")) {
      tempFilteredProducts = tempFilteredProducts.filter((product) =>
        selectedCategoryRanges.some((category) =>
          product.itemType.toLowerCase().includes("ganpati")
        )
      );
    } else if (selectedCategoryRanges.includes("Studs")) {
      tempFilteredProducts = tempFilteredProducts.filter(
        (product) =>
          selectedCategoryRanges.some((category) =>
            product.product_Name.toLowerCase().includes(category.toLowerCase())
          ) || product.product_Name.toLowerCase().includes("tops")
      );
    } else if (selectedCategoryRanges.includes("Rings")) {
      const pattern = new RegExp(`\\b${"ring"}\\b`, "i");
      tempFilteredProducts = tempFilteredProducts.filter((product) =>
        pattern.test(product.product_Name)
      );
    } else if (!selectedCategoryRanges.includes("ALL")) {
      tempFilteredProducts = tempFilteredProducts.filter((product) =>
        selectedCategoryRanges.some(
          (category) =>
            product.product_Name
              .toLowerCase()
              .includes(category.toLowerCase()) ||
            product.itemType.toLowerCase().includes(category.toLowerCase())
        )
      );
    }
    if (!selectedPurityRanges.includes("ALL")) {
      tempFilteredProducts = tempFilteredProducts.filter((product) =>
        selectedPurityRanges.includes(product.purity)
      );
    }
    if (!selectedGenderRanges.includes("ALL")) {
      tempFilteredProducts = tempFilteredProducts.filter((product) =>
        selectedGenderRanges.includes(product.gender)
      );
    }
    const sortProducts = (products, sortOption) => {
      switch (sortOption) {
        case "Popularity":
          // Implement popularity sorting logic (if applicable)
          // Example: products.sort((a, b) => b.popularity - a.popularity);
          return shuffleArray(products);
          break;
        case "Recommended":
          // return shuffleArray(products);
          // Implement recommended sorting logic (if applicable)
          // Example: products.sort((a, b) => a.recommendationScore - b.recommendationScore);
          break;
        case "Lowest Price":
          products.sort(
            (a, b) => parseFloat(a.sellPrice) - parseFloat(b.sellPrice)
          );
          break;
        case "Highest Price":
          products.sort(
            (a, b) => parseFloat(b.sellPrice) - parseFloat(a.sellPrice)
          );
          break;
        default:
          break;
      }
      return products;
    };

    // Sort filtered products based on selected sort option
    const sortedProducts = sortProducts(
      tempFilteredProducts,
      selectedSortOption
    );
    setFilteredProducts(tempFilteredProducts);
    setFilteredProducts(sortedProducts);
    window.scrollTo(0, 0);
  }, [
    allProducts,
    selectedPriceRanges,
    selectedMetalRanges,
    selectedCategoryRanges,
    selectedPurityRanges,
    selectedGenderRanges,
    selectedSortOption,
  ]);
  const handlePriceRangeChange = (range) => {
    if (range === "ALL") {
      setSelectedPriceRanges(["ALL"]);
    } else {
      const updatedRanges = selectedPriceRanges.includes("ALL")
        ? [range]
        : selectedPriceRanges.includes(range)
        ? selectedPriceRanges.filter((item) => item !== range)
        : [...selectedPriceRanges, range];
      setSelectedPriceRanges(updatedRanges);
    }
  };

  const handleMetalRangeChange = (metal) => {
    if (metal === "ALL") {
      setSelectedMetalRanges(["ALL"]);
    } else {
      const updatedRanges = selectedMetalRanges.includes("ALL")
        ? [metal]
        : selectedMetalRanges.includes(metal)
        ? selectedMetalRanges.filter((item) => item !== metal)
        : [...selectedMetalRanges, metal];
      setSelectedMetalRanges(updatedRanges);
    }
  };
  const handleCategoryRangeChange = (category) => {
    if (category === "ALL") {
      setSelectedCategoryRanges(["ALL"]);
    } else {
      const updatedRanges = selectedCategoryRanges.includes("ALL")
        ? [category]
        : selectedCategoryRanges.includes(category)
        ? selectedCategoryRanges.filter((item) => item !== category)
        : [...selectedCategoryRanges, category];
      setSelectedCategoryRanges(updatedRanges);
    }
  };
  const handlePurityRangeChange = (purity) => {
    if (purity === "ALL") {
      setSelectedPurityRanges(["ALL"]);
    } else {
      const updatedRanges = selectedPurityRanges.includes("ALL")
        ? [purity]
        : selectedPurityRanges.includes(purity)
        ? selectedPurityRanges.filter((item) => item !== purity)
        : [...selectedPurityRanges, purity];
      setSelectedPurityRanges(updatedRanges);
    }
  };
  const handleGenderRangeChange = (gender) => {
    if (gender === "ALL") {
      setSelectedGenderRanges(["ALL"]);
    } else {
      const updatedRanges = selectedGenderRanges.includes("ALL")
        ? [gender]
        : selectedGenderRanges.includes(gender)
        ? selectedGenderRanges.filter((item) => item !== gender)
        : [...selectedGenderRanges, gender];
      setSelectedGenderRanges(updatedRanges);
    }
  };
  const allProductsAvailable = () => {
    return filteredProducts.map((product) => (
      <div
        key={product.id}
        className="container"
        onClick={() => {
          navigate(`/productpage/${product.id}`);
        }}
      >
        <img
          className="productsImages"
          src={`${s3}/${product.images.split(",")[0]}`}
          alt="Products Images"
        />
        <div className="shoppageProductDescriptionBox">
          <div className="shoppageProductDescriptionBoxTitle">
            <h3>{product.product_Name}</h3>
          </div>
          {/* <div className="shoppageProductDescriptionBoxDetails">
            <p>{`${product.description.substr(0, 75)} ...`}</p>
          </div> */}
          <div className="shoppageProductDescriptionBoxDetails">
            {/* <strong>
              <h3 style={{ color: "green", fontWeight: "bold" }}>
                ₹{product.sellPrice}
              </h3>
            </strong> */}
            {product.offerPrice &&
            product.offerPrice !== 0 &&
            product.offerPrice !== "0" ? (
              <div style={{ display: "flex" }}>
                <strong>
                  <h3
                    style={{
                      color: "red",
                      textDecorationLine: "line-through",
                      fontWeight: "bold",
                    }}
                  >
                    ₹{product.sellPrice}
                  </h3>
                </strong>
                <strong>
                  <h3 style={{ color: "green", fontWeight: "bold" }}>
                    ₹{product.offerPrice}
                  </h3>
                </strong>
              </div>
            ) : (
              <strong>
                <h3 style={{ color: "green", fontWeight: "bold" }}>
                  ₹{product.sellPrice}
                </h3>
              </strong>
            )}{" "}
          </div>
        </div>
      </div>
    ));
  };
  const filterIsOpen = (x) => {
    if (x === "price") {
      document
        .getElementById("filtersMainDropdownOuterLeftPriceOption")
        .classList.add("showFilterOptionOnTop");
      setListItems(true);
      setIsDropdownOpen("price");
    } else if (x === "metals") {
      document
        .getElementById("filtersMainDropdownOuterLeftMetalsOption")
        .classList.add("showFilterOptionOnTop");
      setListItems(true);
      setIsDropdownOpen("metals");
    } else if (x === "category") {
      document
        .getElementById("filtersMainDropdownOuterLeftCategoryOption")
        .classList.add("showFilterOptionOnTop");
      setListItems(true);
      setIsDropdownOpen("category");
    } else if (x === "purity") {
      document
        .getElementById("filtersMainDropdownOuterLeftPurityOption")
        .classList.add("showFilterOptionOnTop");
      setListItems(true);
      setIsDropdownOpen("purity");
    } else if (x === "gender") {
      document
        .getElementById("filtersMainDropdownOuterLeftGenderOption")
        .classList.add("showFilterOptionOnTop");
      setListItems(true);
      setIsDropdownOpen("gender");
    } else if (x === "sortBy") {
      document
        .getElementById("filtersMainDropdownOuterRightSortByOption")
        .classList.add("showFilterOptionOnTop");
      setListItems(true);
      setIsDropdownOpen("");
    }
  };
  const filterIsClose = (x) => {
    if (x === "price") {
      document
        .getElementById("filtersMainDropdownOuterLeftPriceOption")
        .classList.remove("showFilterOptionOnTop");
      setListItems(false);
      setIsDropdownOpen("");
    } else if (x === "metals") {
      document
        .getElementById("filtersMainDropdownOuterLeftMetalsOption")
        .classList.remove("showFilterOptionOnTop");
      setListItems(false);
      setIsDropdownOpen("");
      setIsDropdownOpen("metals");
    } else if (x === "category") {
      document
        .getElementById("filtersMainDropdownOuterLeftCategoryOption")
        .classList.remove("showFilterOptionOnTop");
      setListItems(false);
      setIsDropdownOpen("");
    } else if (x === "purity") {
      document
        .getElementById("filtersMainDropdownOuterLeftPurityOption")
        .classList.remove("showFilterOptionOnTop");
      setListItems(false);
      setIsDropdownOpen("");
    } else if (x === "gender") {
      document
        .getElementById("filtersMainDropdownOuterLeftGenderOption")
        .classList.remove("showFilterOptionOnTop");
      setListItems(false);
      setIsDropdownOpen("");
    } else if (x === "sortBy") {
      document
        .getElementById("filtersMainDropdownOuterRightSortByOption")
        .classList.remove("showFilterOptionOnTop");
      setListItems(false);
      setIsDropdownOpen("");
    }
  };
  const [mobileFilterBox, setMobileFilterBox] = useState(false);
  const [mobileSortByBox, setMobileSortByBox] = useState(false);
  const [mobileSortOption, setMobileSortOption] = useState("");
  const handleOpenMobileFilterBox = () => {
    setMobileFilterBox(!mobileFilterBox);
    if (mobileFilterBox && !loading) {
      document
        .getElementById("shoppageMobileFiltersBoxMain")
        .classList.remove("closeMobileFilterBox");
      document
        .getElementById("shoppageMobileFiltersBoxMain")
        .classList.add("openMobileFilterBox");

      document
        .getElementById("shoppageMobileFiltersBoxMobileMain")
        .classList.add("shoppageMobileFiltersBoxMobileMainActive");
    } else {
      document
        .getElementById("shoppageMobileFiltersBoxMain")
        .classList.remove("openMobileFilterBox");
      document
        .getElementById("shoppageMobileFiltersBoxMain")
        .classList.add("closeMobileFilterBox");
    }
  };
  const handleOpenMobileSortByBox = () => {
    setMobileSortByBox(!mobileSortByBox);

    if (mobileSortByBox && !loading) {
      document
        .getElementById("shoppageMobileSortByBoxMain")
        .classList.remove("closeMobileSortByBox");
      document
        .getElementById("shoppageMobileSortByBoxMain")
        .classList.add("openMobileSortByBox");

      document
        .getElementById("shoppageMobileSortByBoxMain")
        .classList.add("shoppageMobileSortByBoxMobileMainActive");
      document
        .getElementById("shoppageMobileSortByInnerBox")
        .classList.add("shoppageMobileSortByInnerBoxActive");
    } else {
      document
        .getElementById("shoppageMobileSortByBoxMain")
        .classList.remove("openMobileSortByBox");
      document
        .getElementById("shoppageMobileSortByBoxMain")
        .classList.add("closeMobileSortByBox");
    }
  };
  useEffect(() => {
    if (mobileSortOption !== "") {
      setSelectedSortOption(mobileSortOption);
    } else {
      null;
    }
  }, [mobileSortOption]);
  const resetAllFilters = () => {
    setSelectedPriceRanges(["ALL"]);
    setSelectedMetalRanges(["ALL"]);
    setSelectedCategoryRanges(["ALL"]);
    setSelectedPurityRanges(["ALL"]);
    setSelectedGenderRanges(["ALL"]);
    // handleOpenMobileFilterBox();
  };
  const resetSorting = () => {
    setSelectedSortOption("Recommended");
    handleOpenMobileSortByBox();
  };
  const DesktopFilters = () => {
    return (
      <>
        <div
          // style={{ whiteSpace: "nowrap" }}
          className="filtersMainDropdownOuterBox"
        >
          <div className="filtersMainDropdownOuterLeftBox">
            <div
              id="filtersMainDropdownOuterLeftPriceOption"
              onMouseEnter={() => filterIsOpen("price")}
              onMouseLeave={() => filterIsClose("price")}
              className="filtersMainDropdownOuterLeftPriceBox"
            >
              <p className="filtersMainDropdownOuterLeftPriceOption">
                Price
                {isDropdownOpen === "price" ? (
                  <AiOutlineDown style={{ marginLeft: "10px" }} />
                ) : (
                  <AiOutlineUp style={{ marginLeft: "10px" }} />
                )}
                {!selectedPriceRanges.includes("ALL") ? (
                  <div className="filterEditedIcon"></div>
                ) : null}
              </p>
              <div className="filtersMainDropdownOuterLeftHoverBox">
                {allPrices.map((x) => (
                  <div className="filtersMainDropdownOuterLeftInnerBox" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedPriceRanges.includes(x)}
                      onChange={() => handlePriceRangeChange(x)}
                    />
                    <label htmlFor={x}>{x}</label>
                  </div>
                ))}
              </div>
            </div>
            <div
              id="filtersMainDropdownOuterLeftMetalsOption"
              onMouseEnter={() => filterIsOpen("metals")}
              onMouseLeave={() => filterIsClose("metals")}
              className="filtersMainDropdownOuterLeftPriceBox"
            >
              <p>
                Metals{" "}
                {isDropdownOpen === "metals" ? (
                  <AiOutlineDown style={{ marginLeft: "10px" }} />
                ) : (
                  <AiOutlineUp style={{ marginLeft: "10px" }} />
                )}
                {!selectedMetalRanges.includes("ALL") ? (
                  <div className="filterEditedIcon"></div>
                ) : null}
              </p>
              <div className="filtersMainDropdownOuterLeftHoverBox">
                {metals.map((x) => (
                  <div className="filtersMainDropdownOuterLeftInnerBox" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedMetalRanges.includes(x)}
                      onChange={() => handleMetalRangeChange(x)}
                    />
                    <label htmlFor={x}>{x}</label>
                  </div>
                ))}
              </div>
            </div>
            <div
              id="filtersMainDropdownOuterLeftCategoryOption"
              onMouseEnter={() => filterIsOpen("category")}
              onMouseLeave={() => filterIsClose("category")}
              className="filtersMainDropdownOuterLeftPriceBox"
            >
              <p>
                Category{" "}
                {isDropdownOpen === "category" ? (
                  <AiOutlineDown style={{ marginLeft: "10px" }} />
                ) : (
                  <AiOutlineUp style={{ marginLeft: "10px" }} />
                )}
                {!selectedCategoryRanges.includes("ALL") ? (
                  <div className="filterEditedIcon"></div>
                ) : null}
              </p>
              <div className="filtersMainDropdownOuterLeftHoverBox">
                {categories.map((x) => (
                  <div className="filtersMainDropdownOuterLeftInnerBox" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedCategoryRanges.includes(x)}
                      onChange={() => handleCategoryRangeChange(x)}
                    />
                    <label htmlFor={x}>{x}</label>
                  </div>
                ))}
              </div>
            </div>
            <div
              id="filtersMainDropdownOuterLeftPurityOption"
              onMouseEnter={() => filterIsOpen("purity")}
              onMouseLeave={() => filterIsClose("purity")}
              className="filtersMainDropdownOuterLeftPriceBox"
            >
              <p>
                Purity{" "}
                {isDropdownOpen === "purity" ? (
                  <AiOutlineDown style={{ marginLeft: "10px" }} />
                ) : (
                  <AiOutlineUp style={{ marginLeft: "10px" }} />
                )}{" "}
                {!selectedPurityRanges.includes("ALL") ? (
                  <div className="filterEditedIcon"></div>
                ) : null}
              </p>
              <div className="filtersMainDropdownOuterLeftHoverBox">
                {purities.map((x) => (
                  <div className="filtersMainDropdownOuterLeftInnerBox" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedPurityRanges.includes(x)}
                      onChange={() => handlePurityRangeChange(x)}
                    />
                    <label htmlFor={x}>
                      {x}

                      {x !== "24CT" && x !== "22CT" && x !== "ALL" ? (
                        <em style={{ fontSize: "small" }}> (Silver)</em>
                      ) : x !== "ALL" ? (
                        <em style={{ fontSize: "small" }}> (Gold)</em>
                      ) : null}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div
              id="filtersMainDropdownOuterLeftGenderOption"
              onMouseEnter={() => filterIsOpen("gender")}
              onMouseLeave={() => filterIsClose("gender")}
              className="filtersMainDropdownOuterLeftPriceBox"
            >
              <p>
                Gender{" "}
                {isDropdownOpen === "gender" ? (
                  <AiOutlineDown style={{ marginLeft: "10px" }} />
                ) : (
                  <AiOutlineUp style={{ marginLeft: "10px" }} />
                )}{" "}
                {!selectedGenderRanges.includes("ALL") ? (
                  <div className="filterEditedIcon"></div>
                ) : null}
              </p>
              <div className="filtersMainDropdownOuterLeftHoverBox">
                {genders.map((x) => (
                  <div className="filtersMainDropdownOuterLeftInnerBox" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedGenderRanges.includes(x)}
                      onChange={() => handleGenderRangeChange(x)}
                    />
                    <label htmlFor={x}>{x}</label>
                  </div>
                ))}
              </div>
            </div>
            {/* <p>Price</p>
              <p>Price</p>
              <p>Price</p>
              <p>Price</p> */}
          </div>

          <div
            id="filtersMainDropdownOuterRightSortByOption"
            onMouseEnter={() => filterIsOpen("sortBy")}
            onMouseLeave={() => filterIsClose("sortBy")}
            className="filtersMainDropdownOuterRightBox"
          >
            {/* <div
              id="filtersMainDropdownOuterRightSortByOption"
              onMouseEnter={() => filterIsOpen("sortBy")}
              onMouseLeave={() => filterIsClose("sortBy")}
              className="filtersMainDropdownOuterRightSortByBox"
              > */}
            <p> Sort By:</p>
            <select
              value={selectedSortOption}
              onChange={(e) => setSelectedSortOption(e.target.value)}
              style={{ padding: "10px" }}
            >
              {sortBy.map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>
          {/* </div> */}
        </div>
        <div className="shopPage">{allProductsAvailable()}</div>
        <div className={listItems === true ? "new" : "new2"}></div>
      </>
    );
  };

  const MobileFilters = () => {
    return (
      <div
        id="shoppageMobileFiltersBoxMain"
        className="shoppageMobileFiltersBox"
      >
        <div
          id="shoppageMobileFiltersBoxMobileMain"
          className="shoppageMobileFiltersBoxMobileMain"
        >
          {" "}
          <div className="shoppageMobileFiltersCloseBox">
            <RxCross2
              onClick={() => handleOpenMobileFilterBox()}
              style={{ color: "black" }}
            />
            <p style={{ color: "black", marginLeft: "10px" }}>Filters</p>
          </div>
          <div className="shoppageMobileFiltersBoxMobileLeftMain">
            <p
              style={
                isDropdownOpen === "price" || isDropdownOpen === ""
                  ? { backgroundColor: "#1a4c2d", color: "white" }
                  : null
              }
              onClick={() => setIsDropdownOpen("price")}
            >
              {!selectedPriceRanges.includes("ALL") ? (
                <div className="filterEditedIcon"></div>
              ) : null}
              Prices
            </p>
            <p
              style={
                isDropdownOpen === "metals"
                  ? { backgroundColor: "#1a4c2d", color: "white" }
                  : null
              }
              onClick={() => setIsDropdownOpen("metals")}
            >
              {!selectedMetalRanges.includes("ALL") ? (
                <div className="filterEditedIcon"></div>
              ) : null}
              Metals
            </p>
            <p
              style={
                isDropdownOpen === "category"
                  ? { backgroundColor: "#1a4c2d", color: "white" }
                  : null
              }
              onClick={() => setIsDropdownOpen("category")}
            >
              {!selectedCategoryRanges.includes("ALL") ? (
                <div className="filterEditedIcon"></div>
              ) : null}
              Category
            </p>
            <p
              style={
                isDropdownOpen === "purity"
                  ? { backgroundColor: "#1a4c2d", color: "white" }
                  : null
              }
              onClick={() => setIsDropdownOpen("purity")}
            >
              {!selectedPurityRanges.includes("ALL") ? (
                <div className="filterEditedIcon"></div>
              ) : null}
              Purity
            </p>
            <p
              style={
                isDropdownOpen === "gender"
                  ? { backgroundColor: "#1a4c2d", color: "white" }
                  : null
              }
              onClick={() => setIsDropdownOpen("gender")}
            >
              {" "}
              {!selectedGenderRanges.includes("ALL") ? (
                <div className="filterEditedIcon"></div>
              ) : null}
              Gender
            </p>
          </div>
          <div className="shoppageMobileFiltersBoxMobileRightMain">
            {isDropdownOpen === "price" || isDropdownOpen === ""
              ? allPrices.map((x) => (
                  <div className="shoppageMobileFilterItemsList" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedPriceRanges.includes(x)}
                      onChange={() => handlePriceRangeChange(x)}
                    />
                    <label htmlFor={x}>{x}</label>
                  </div>
                ))
              : null}
            {isDropdownOpen === "metals"
              ? metals.map((x) => (
                  <div className="shoppageMobileFilterItemsList" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedMetalRanges.includes(x)}
                      onChange={() => handleMetalRangeChange(x)}
                    />
                    <label htmlFor={x}>{x}</label>
                  </div>
                ))
              : null}
            {isDropdownOpen === "category"
              ? categories.map((x) => (
                  <div className="shoppageMobileFilterItemsList" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedCategoryRanges.includes(x)}
                      onChange={() => handleCategoryRangeChange(x)}
                    />
                    <label htmlFor={x}>{x}</label>
                  </div>
                ))
              : null}
            {isDropdownOpen === "purity"
              ? purities2.map((x) => (
                  <div className="shoppageMobileFilterItemsList" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedPurityRanges.includes(x)}
                      onChange={() => handlePurityRangeChange(x)}
                    />
                    <label htmlFor={x}>
                      {x}

                      {x !== "24CT" && x !== "22CT" && x !== "ALL" ? (
                        <em style={{ fontSize: "small" }}> (Silver)</em>
                      ) : x !== "ALL" ? (
                        <em style={{ fontSize: "small" }}> (Gold)</em>
                      ) : null}
                    </label>
                  </div>
                ))
              : null}
            {isDropdownOpen === "gender"
              ? genders.map((x) => (
                  <div className="shoppageMobileFilterItemsList" key={x}>
                    <input
                      type="checkbox"
                      id={x}
                      name={x}
                      checked={selectedGenderRanges.includes(x)}
                      onChange={() => handleGenderRangeChange(x)}
                    />
                    <label htmlFor={x}>{x}</label>
                  </div>
                ))
              : null}
          </div>
        </div>

        {/* {allPrices.map((x) => (
      <>
        <div className="filtersMainDropdownOuterLeftInnerBox" key={x}>
          <input
            type="checkbox"
            id={x}
            name={x}
            checked={selectedPriceRanges.includes(x)}
            onChange={() => handlePriceRangeChange(x)}
          />
          <label htmlFor={x}>{x}</label>
        </div>*/}
        <div className="shoppageMobileFiltersBoxBottomOptions">
          <div
            onClick={() => handleOpenMobileFilterBox()}
            className="shoppageMobileFiltersLeftBox"
          >
            Apply
          </div>
          <div
            onClick={() => resetAllFilters()}
            className="shoppageMobileFiltersRightBox"
          >
            Reset
          </div>
        </div>
        {/* </>
    ))}  */}
      </div>
    );
  };
  const MobileSortBy = () => {
    return (
      <div
        style={{ color: "black" }}
        id="shoppageMobileSortByBoxMain"
        className="shoppageMobileFiltersBox"
      >
        <div
          id="shoppageMobileSortByInnerBox"
          className="shoppageMobileSortByInnerBox"
        >
          <div className="shoppageMobileSortByCloseBox">
            <RxCross2
              onClick={() => handleOpenMobileSortByBox()}
              style={{ color: "black" }}
            />
            <p style={{ color: "black", marginLeft: "10px" }}>Sort By</p>
          </div>
          <div className="shoppageMobileSortByList">
            {sortBy.map((x) => (
              <p key={x} onClick={() => setMobileSortOption(x)}>
                {x}
              </p>
            ))}
          </div>
        </div>

        <div className="shoppageMobileFiltersBoxBottomOptions">
          <div
            onClick={() => handleOpenMobileSortByBox()}
            className="shoppageMobileFiltersLeftBox"
          >
            Apply
          </div>
          <div
            onClick={() => resetSorting()}
            className="shoppageMobileFiltersRightBox"
          >
            Reset
          </div>
        </div>
        {/* </>
    ))}  */}
      </div>
    );
  };
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      {loading ? (
        // <div style={{ height: "150px", margin: "100px 0" }}>
        //   <Hearts width={200} color="#4fa94d" />
        // </div>
        <div
          style={{
            height: "150px",
            margin: "100px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ColorRing
            height="100"
            width="100"
            // color="#4fa94d"
            colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
          />
        </div>
      ) : (
        DesktopFilters()
      )}

      {!loading || mobileFilterBox ? MobileFilters() : null}
      {!loading || mobileSortByBox ? MobileSortBy() : null}

      <div className="shoppageMobileFiltersMainBox">
        <div
          className="shoppageMobileFiltersLeftBox"
          onClick={() => handleOpenMobileFilterBox()}
        >
          <FiFilter />
          <p>Filter</p>
        </div>
        <div
          className="shoppageMobileFiltersRightBox"
          onClick={() => handleOpenMobileSortByBox()}
        >
          <MdSort />
          <p>Sort</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
