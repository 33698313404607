import React from "react";

function Dots({ activeIndex, onClick, imageSlider }) {
  return (
    <div className="newHomePageSliderAllDots">
      {imageSlider.map((slide, index) => (
        <span
          key={index}
          className={`${
            activeIndex === index
              ? "newHomePageSliderDot newHomePageSliderActveDot"
              : "newHomePageSliderDot"
          }`}
          onClick={() => onClick(index)}
        ></span>
      ))}
    </div>
  );
}

export default Dots;
