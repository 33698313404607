import React, { useEffect, useState } from "react";
import "../PagesStyles/CustomerProfile.css";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { a3, a60 } from "../../Api/RootApiPath";
import men from "../../Images/avatarMen.jpg";
import women from "../../Images/avatarWomen.jpg";
import { SlUser, SlUserFemale } from "react-icons/sl";
import CustomerDetails from "./CustomerDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  userLoggedIn,
  userLoggedOut,
} from "../../../redux/action/Actions";
import CustomerDetailsMobile from "./CustomerDetailsMobile";

export default function CustomerProfile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showPage, setShowPage] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Customer_id = params.get("customerId");
  const ProductId = params.get("productId");

  const dispatch = useDispatch();
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
    console.log("I am here");
  };

  const loggedOut = (index) => {
    dispatch(userLoggedOut(index));
  };
  const csStates = useSelector((state) => state);
  let userDetails = csStates.reducer4;

  const accountDetails = async () => {
    const formData = {
      Id: Customer_id,
    };

    try {
      const response = await fetch(a3, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // console.log("Data:", data.data);
      loggedIn([data.data]);
      // setLoading(false);
      // setShowPage(true);
      // window.scrollTo(0, 0);
      fetchAllCartItems();
    } catch (error) {
      console.error(error);
      navigate("/customerloginpage");
      alert(error);
    }
  };
  const fetchAllCartItems = async () => {
    const formData = {
      Customer_Id: Customer_id,
    };

    try {
      const response = await fetch(a60, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const cartData = await response.json();
      const cartItems = cartData.data || [];

      console.log(cartItems, "cartItems");

      cartItems.forEach((item) => {
        if (!item.tblProduct) return; // Safety check if tblProduct is undefined

        const {
          netWt,
          making_per_gram,
          making_Percentage,
          making_Fixed_Amt,
          making_Fixed_Wastage,
          stoneAmount,
          mrp,
          offerPrice,
          id,
          phuid,
          product_Name,
          product_No,
          images,

          size,
        } = item.tblProduct;

        // Extract the purityRate from tblPurity.todaysRate
        const purityRate = item.tblProduct.tblPurity
          ? parseFloat(item.tblProduct.tblPurity.todaysRate)
          : 0;

        const imagesArray = images.split(","); // Assuming images are a comma-separated string

        const { finalPrice, totalMaking, netGoldRate } = calculateFinalPrice({
          netWt,
          making_per_gram,
          making_Percentage,
          making_Fixed_Amt,
          making_Fixed_Wastage,
          stoneAmount,
          mrp,
          offerPrice,
          purityRate,
          id,
          phuid,
        });

        const myObject = {
          Product_id: item.productId,
          Type:
            offerPrice && offerPrice !== "0"
              ? "OfferPrice"
              : mrp && mrp !== "0"
              ? "Mrp"
              : "Weight",
          productNo: product_No,
          title: product_Name,
          image: imagesArray.length > 0 ? imagesArray[0] : "",
          making: totalMaking,
          finalGrossRate: netGoldRate,
          discount: 0,
          coupon: "",
          originalPrice: offerPrice || mrp,
          originalMaking: totalMaking,
          originalFinalGrossRate: netGoldRate,
          price: finalPrice,
          count: parseInt(item.cartCount),
          size: size,
          finalPrice: finalPrice,
          id: item.id,
        };
        // console.log(myObject, "myObject");
        dispatch(addToCart(myObject));
      });

      setLoading(false);
      setShowPage(true);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      navigate("/customerloginpage");
      alert("Failed to fetch cart items: " + error.message);
    }
  };

  const calculateFinalPrice = ({
    netWt,
    making_per_gram,
    making_Percentage,
    making_Fixed_Amt,
    making_Fixed_Wastage,
    stoneAmount,
    mrp,
    offerPrice,
    purityRate,
    phuid,
  }) => {
    // console.log(
    //   netWt,
    //   "netWt",
    //   making_per_gram,
    //   "making_per_gram",
    //   making_Percentage,
    //   "making_Percentage",
    //   making_Fixed_Amt,
    //   "making_Fixed_Amt",
    //   making_Fixed_Wastage,
    //   "making_Fixed_Wastage",
    //   stoneAmount,
    //   "stoneAmount",
    //   mrp,
    //   "mrp",
    //   offerPrice,
    //   "offerPrice",
    //   purityRate,
    //   "purityRate",
    //   phuid,
    //   "phuid"
    // );
    let netGoldRate = (parseFloat(netWt) * parseFloat(purityRate)) / 10;
    let makingCharges1 = parseFloat(netWt) * parseFloat(making_per_gram);
    let makingCharges2 =
      (parseFloat(netGoldRate) * parseFloat(making_Percentage)) / 100;
    let makingCharges3 = making_Fixed_Amt;
    let makingCharges4 =
      (parseFloat(purityRate) * parseFloat(making_Fixed_Wastage)) / 10;
    let GST = 0.03;
    let grossTotalRate = 1;
    let totalMaking =
      parseFloat(makingCharges1) +
      parseFloat(makingCharges2) +
      parseFloat(makingCharges3) +
      parseFloat(makingCharges4);

    if (offerPrice !== "" && offerPrice !== 0 && offerPrice !== "0") {
      grossTotalRate = offerPrice;
      // console.log("i am Offer");
    } else if (mrp !== "" && mrp !== 0 && mrp !== "0") {
      grossTotalRate = mrp;
      // console.log("i am MRP");
    } else {
      grossTotalRate =
        parseFloat(netGoldRate) +
        parseFloat(makingCharges1) +
        parseFloat(makingCharges2) +
        parseFloat(makingCharges3) +
        parseFloat(makingCharges4) +
        parseFloat(stoneAmount) +
        parseFloat(phuid);
      // console.log("i am Weight");
    }
    // console.log(id, making_Fixed_Wastage);
    let GSTAdded = GST * grossTotalRate;
    let finalPrice = (
      parseFloat(grossTotalRate) + parseFloat(GSTAdded)
    ).toFixed(0);

    if (offerPrice !== "" && offerPrice !== 0 && offerPrice !== "0") {
      GSTAdded = GST * offerPrice;
      finalPrice = (parseFloat(offerPrice) + GSTAdded).toFixed(0);
    } else if (mrp !== "" && mrp !== 0) {
      GSTAdded = GST * mrp;
      finalPrice = (mrp + GSTAdded).toFixed(0);
    }

    return { finalPrice, totalMaking, netGoldRate };
  };
  useEffect(() => {
    if (Customer_id) {
      setShowPage(false);
      accountDetails();
      if (ProductId) {
        navigate(`/productpage/${ProductId}`);
      }
      // console.log("first");
    } else if (!Customer_id && userDetails.length > 0) {
      setShowPage(true);
      if (ProductId) {
        navigate(`/productpage/${ProductId}`);
      }
      // console.log("second");
    } else {
      navigate("/customerloginpage");
      setShowPage(false);
    }
  }, [Customer_id]);

  const customerProfile = () => {
    return (
      <div>
        <div className="headingBox orderSummaryHeadingBox">
          <Heading />
        </div>
        <div className="profilePageMainBox">
          <div style={{ display: "none" }} className="profileBox">
            {userDetails.gender === "Female" ? (
              <img className="profileImage" src={women} alt="WomenClipArt" />
            ) : (
              <img className="profileImage" src={men} alt="MenClipArt" />
            )}
            <div className="profileMyDetailsBox">
              <h2>
                {userDetails[0].firstName} {userDetails[0].middleName}{" "}
                {userDetails[0].lastName}
              </h2>
              <h3>Email: {userDetails[0].email}</h3>
              <h3>Contact No: {userDetails[0].mobile}</h3>
              <h3>
                Status: {userDetails[0].statusType ? "Active" : "Inactive"}
              </h3>
            </div>
            <div className="profileMyDetailsBox">
              <h2>Delivery Address</h2>
              <h3>
                {userDetails[0].currAddStreet}, {userDetails[0].currAddTown},{" "}
                {userDetails[0].currAddState}
              </h3>
              <h3>{userDetails[0].currAddPinCode}</h3>
            </div>
          </div>
          <div className="customerDetailsDesktop">
            <CustomerDetails csData={userDetails[0]} orderId="" />
          </div>
          {/* <div className="customerDetailsMobileview">
            <CustomerDetailsMobile csData={userDetails[0]} orderId="" />
          </div> */}
        </div>
        <Footer />
      </div>
    );
  };

  return <div>{showPage ? customerProfile() : null}</div>;
}
