import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import Footer from "./Footer";
import "../Components Styles/Footer.css";
export default function RefundAndExchangePolicy() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <div>
      <div className="headingBox">
        <Heading />
      </div>
      <div className="ourContactMainBox termsMain">
        <h1>Return & Exchange</h1>
        <div className="terms-items">
          <p>
            1. Any product related complaint about purity, workmanship,
            hallmarking, weight, or any other complaint should be brought to our
            notice within 15 Days from the date of purchased product delivered.
            After 15 Days, such complaints will not be entertained. We will try
            our best to repair the product.
          </p>
          <p>
            2. If there is a valid reason for which a product needs to be
            replaced/exchanged/returned, it must be done within the 15 days of
            the date of purchased product delivered. The product must not be
            damaged or altered. The full value of the product will be considered
            for the exchange/Refund after verification & confirmation by our
            Authorized personnel.
          </p>
          <p>3. PROCESS FOR RETURN</p>
          <li>A. Print out Bluedart Waybill Form (2pages) </li>
          <li>B. Give the Bluedart Agent your Waybill Form that you printed</li>
          <li>
            C. Write "Reverse Pickup" on the outer box once product is packaged
            securely
          </li>
          <p>
            It is important to follow all these procedures for a successful
            return and refund. Keep in mind that a retum request is valid only
            ONCE, in case you happen to fail to return the product.
          </p>
          <p>4. PROCESS FOR REFUND, DETAILS REQUIRED</p>
          <li>Bank Name -</li>
          <li>Bank's Branch -</li>
          <li>IFSC code-</li>
          <li>Beneficiary a/c number-</li>
          <li>Beneficiary a/c name -</li>
          <p>
            5. Refunds will be promptly initiated and processed within a maximum
            of 10 days via either UPI or Bank Transfer.
          </p>
          <p>
            6. Purchase policy of MKG will be applied in case a customer wants
            to return after 15 Days.
          </p>
          <p>
            7. The customer is required to carefully maintain the bill with
            themselves for any future queries or returns.
          </p>
          <h3 style={{ textAlign: "left", margin: "1rem", marginBottom: "0" }}>
            Repurchase / Buyback policy
          </h3>

          <p>
            1. The customer is required to visit any of the showrooms of
            M.K.Ghare Jewellers - Ghare Jewellers Private Limited to sell the
            ornaments.
          </p>
          <p>
            2. For information regarding Old Gold Exchange, kindly contact us
            through WhatsApp or drop us an email.
          </p>
          <p>
            3. Gold & Silver will be repurchased after deduction of
            Encashment-Commission. This is the difference between buying &
            selling. Gold & Silver will be repurchased only when accompanied by
            the original invoice. The deduction percentage will be updated time
            to time according to the buying and selling difference in the then
            current Market condition.
          </p>
          <p>
            4. Diamonds would be repurchased only when accompanied by the
            original invoice and the certificate of authenticity.
          </p>
          <p>
            5. In case of Precious Gems, Pearls, Artificial Diamonds, repurchase
            is not possible.
          </p>
          <p>
            6. Photo frames/ Gold- & Silver-Plated articles and Decorative
            products sold on MRP have no relevance to weight and all such
            articles shall not be repurchased.
          </p>
          <p>
            7. Amount of Repurchase will be paid by A/c Payee Cheque only OR
            RTGS to the person whose name is mentioned on the bill. No direct
            cash will be given for the same anywhere.
          </p>
          <p>
            8. Repurchased Jewellery / Articles are melted and refined to
            extract pure Gold / Silver. Hence, once the repurchase transaction
            is completed, it cannot be canceled and any requests at a later date
            for returning of jewelry/ Article shall not be entertained.
          </p>
          <p>
            9. We reserve the right to repurchase or not and all Jewellery,
            Articles, Diamonds & other products are subject to
            weighing/testing/verification and confirmation by our authorized
            personnel.
          </p>
          <h3 style={{ textAlign: "left", margin: "1rem", marginBottom: "0" }}>
            Product Warranty
          </h3>

          <p>
            1. Any Jewellery/ Article when used reduces in weight due to normal
            wear and tear. When any Jewellery is presented to us for repurchase,
            the weight of the jewellery on the day will be considered.
          </p>
          <p>
            2. In some Jewellery, dirt, cotton, powder, or other external
            elements accumulate in the open areas. When such Jewellery is
            presented for repurchase, appropriate weight will be deducted while
            the ornament is heated.
          </p>
          <p>
            3. Gold, Silver products are soft, delicate, and can be bent or
            broken. Therefore, we do not guarantee it against breaking. However,
            we will do our best to repair the products purchased from us.
          </p>
          <p>
            4. Discoloration of Gold Jewellery: This may sometimes happen due to
            the reaction of mercury, perfumes, spillage of chemicals and acids
            as well as by using gold and other different metals together. The
            Effect of mercury is the sudden appearance of white spots on gold
            jewellery. In other cases, jewellery turns reddish/blackish. In such
            a case, the jewellery should be immediately brought for cleaning and
            repolishing. In such cases, the ornament should not be considered of
            inferior quality as it is hall-marked.
          </p>
          <p>
            5. Silver Turning black: This happens when silver comes in contact
            with normal air pollution which has chemicals. It does not mean
            silver is impure.
          </p>
          <h3 style={{ textAlign: "left", margin: "1rem", marginBottom: "0" }}>
            Customer’s Responsibility
          </h3>

          <p>
            1. When registering on www.mkgharejewellers.com / mkghare.com, you
            must give truthful, accurate, current, and complete information
            about yourself.
          </p>
          <p>
            2. In order to keep your registration information truthful,
            accurate, current, and complete, you must immediately maintain and
            update it. M.K.Ghare Jewellers - Ghare Jewellers Private Limited has
            the right to at any moment verify and validate the information and
            any details you have provided. In some circumstances, Ghare
            Jewellers Private Limited also retains the right to require more
            information prior to accepting orders or before delivering
            deliveries. M.K.Ghare Jewellers - Ghare Jewellers Private Limited
            reserves the right to permanently suspend or terminate your
            membership and deny you access to our Website and other affiliated
            services if, upon confirmation, your details are found to be false
            (whether entirely or partially), you provide any information that is
            false, inaccurate, incomplete, or out-of-date, or if Ghare Jewellers
            Private Limited has reasonable grounds to suspect that such
            information is false, inaccurate, incomplete, not current, or not in
            accordance with these Terms and Conditions.
          </p>
          <p>
            3. You attest that the delivery address for the item(s) you ordered
            is accurate and appropriate in every way. In the case that a
            non-delivery happens as a result of an error on your part (such as a
            wrong name, address, or other incorrect information), Ghare
            Jewellers Private Limited may charge you an additional fee for
            redelivery, which you will be responsible for paying.
          </p>
          <p>
            4. Carefully read the product description before placing your order.
            You accept the terms of sale and invitation to offer stated in the
            product's description by making an order for that item.
          </p>
          <p>
            5. Whatever product you purchase from our website is entirely up to
            you, and you agree to only place an order after carefully
            researching the item and being familiar with all of its features,
            qualities, usage, etc. M.K.Ghare Jewellers - Ghare Jewellers Private
            Limited is not responsible for any losses or damages of any type
            incurred by consumers as a result of using the product. Both
            domestic and foreign orders are affected by this.
          </p>
          <h3 style={{ textAlign: "left", margin: "1rem", marginBottom: "0" }}>
            Cancellation by Us
          </h3>
          <p>
            M.K.Ghare Jewellers - Ghare Jewellers Private Limited may be unable
            to accept some orders; as a result, we reserve the right, in our
            sole discretion, to reject or cancel any order. Possible
            explanations for not accepting the order could be due to
            restrictions on the number of items that can be purchased,
            inaccuracies in product information or pricing, problems found by
            our fraud prevention team, or any other problem that M.K.Ghare
            Jewellers - Ghare Jewellers Private Limited determines.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
