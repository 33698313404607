import React, { useState, useEffect } from "react";
import { s1, s3 } from "../../../Api/RootApiPath";
export default function AdminProductCard({
  key,
  product_id,
  product_No,
  title,
  Pieces,
  category_id,
  description,
  images,
}) {
  let allImages = [];

  if (images !== null && images !== undefined) {
    allImages = images.split(",");
  }

  return (
    <div className="adminProductCard">
      <p style={{ marginTop: "2rem", fontWeight: "bolder" }}>
        Product ID = {product_id}
      </p>
      <p>{title}</p>
      <p>Quantity: {Pieces}</p>
      <div className="productCardImageBoxMain">
        <img
          style={{ width: "200px", height: "200px" }}
          // src={`${s1}${imageList1}`}
          src={`${s3}/${allImages[0]}`}
          // src={images}
          alt="images"
        />
        <div className="productCardImageBoxSmallImages">
          {allImages.map((x) => (
            <img style={{ width: "50px" }} src={`${s3}/${x}`} alt="images" />
          ))}
        </div>
      </div>
      {/* <p>{description?.slice(0, 100)}</p> */}
    </div>
  );
}
