import React, { useEffect } from "react";
import Heading from "../../Heading/Heading";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { RxCross2, RxCheck } from "react-icons/rx";
import { a1, a4, a50, a54, a63 } from "../../Api/RootApiPath";
import { userLoggedIn } from "../../../redux/action/Actions";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { GrFacebook } from "react-icons/gr";

export default function CheckOutPageNewLogin() {
  const allStates = useSelector((state) => state);
  const userDetails = allStates.reducer4;

  const [email, setEmail] = useState("");
  const [allCsData, setAllCsData] = useState([]);
  const [csData, setCsData] = useState([]);
  const [oldUser, setOldUser] = useState("EmailPage");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [dataRcvd, setDataRcvd] = useState(false);
  const [otpRcvd, setOtpRcvd] = useState("");
  const [csType, setCsType] = useState("New");

  const [searchParams] = useSearchParams();

  // Retrieving the ProductId from the URL query parameters
  const ProductId = searchParams.get("productId");

  // console.log(ProductId, "ProductId");
  // console.log(ProductId, "ProductId");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[0-9]{10}$/;
    return emailRegex.test(email);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    checkUserExist();
  }, []);
  const checkUserExist = async () => {
    fetch(a1)
      .then((res) => res.json())
      .then((result) => setAllCsData(result.data));
    setDataRcvd(true);
  };
  // console.log(allCsData);
  console.log(userDetails);
  const ifNewUser = (email) => {
    let checkEmailInput = document.getElementById("emailInput");

    if (emailInput.classList.contains("valid")) {
      // console.log("Email Accepted");
      const filteredData = allCsData.filter(
        // (x) => x.customer_login_id === email
        (x) => x.mobile === email
      );
      if (filteredData.length > 0) {
        console.log("exist"),
          console.log("exist"),
          setCsData(filteredData),
          setOldUser("OldUser"),
          setCsType("Old"),
          handleSendOtp("Old"),
          window.scrollTo(0, 0);
      } else {
        setCsData(filteredData),
          setOldUser("NewUser"),
          setCsType("New"),
          window.scrollTo(0, 0);
        // handleSendOtp();
      }
    } else {
      setEmail(" ");
      validateEmail(email), window.scrollTo(0, 0);
    }
  };
  const handleSendOtp = async (cs) => {
    const formData = {
      Mobile: email,
    };
    console.log(cs, "cs");
    console.log(cs, "cs");
    console.log(formData, "formData");
    console.log(formData, "formData");
    try {
      const response = await fetch(a63, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // let data = "112233";
      // console.log(data, "otpdata");
      // console.log(data, "otpdata");

      // alert(data.Message);
      setOtpRcvd(data.data);
      // setOtpRcvd(data);
      setOldUser("OldUser");
      window.scrollTo(0, 0);
      // loggedIn(data.Employee);
      // navigate("/adminhome");
    } catch (error) {
      // if (response.status === 500) {
      // console.log(response.status);
      // console.log(response.statusText);
      // }
      console.error(error);
      // alert(error.message);
    }
  };
  const checkLogin = (cs) => {
    if (cs == "Old" && password === otpRcvd) {
      if (ProductId) {
        return (
          loggedIn(csData), navigate(`/customerprofile?productId=${ProductId}`)
        );
      } else {
        return loggedIn(csData), navigate(`/customerprofile`);
      }
    } else if (cs == "New" && password === otpRcvd) {
      handleRegister();
    } else {
      setIncorrectPassword(true);
    }
  };
  const handleRegister = async () => {
    // const formData = new FormData();
    const formData = {
      currAddPinCode: "",
      currAddState: "",
      currAddStreet: "",
      currAddTown: "",
      customer_login_id: "",
      dob: "",
      email: "",
      firstName: "First",
      lastName: "Last",
      middleName: "Middle",
      mobile: email,
      onlineStatus: "Active",

      password: "",
      perAddPinCode: "",
      perAddState: "",
      perAddStreet: "",
      perAddTown: "",
      statusType: true,
    };
    // formData.append("mobile", mobile);
    // formData.append("email", email);
    // formData.append("firstName", firstName);
    // formData.append("lastName", lastName);
    // console.log("formdata", formData);
    // console.log("formdata", formData);
    try {
      const response = await fetch(a4, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      // console.log(data, "1st Hit");
      // console.log(data, "1st Hit");
      if (data.message === undefined) {
        if (ProductId) {
          return (
            loggedIn([data.data]),
            navigate(`/customerprofile?productId=${ProductId}`)
          );
        } else {
          return loggedIn([data.data]), navigate(`/customerprofile`);
        }
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };
  const loggedIn = (user) => {
    dispatch(userLoggedIn(user));
  };
  const addNewUser = () => {
    const newUser = [
      {
        currAddStreet: "",
        currAddTown: "",
        currAddState: "",
        currAddPinCode: "",
        dob: "",
        email: email,
        firstName: "Firstname",
        gender: "",
        lastName: "Lastname",
        middleName: "",
        mobile: "",
        password: "",
        perAddStreet: "",
        perAddTown: "",
        perAddState: "",
        perAddPinCode: "",
      },
    ];
    // loggedIn(newUser);
    // navigate("/checkoutpagenew/n2");
    navigate("/customersignuppage");
  };
  const googleLogin = () => {
    window.location.href = a50;
  };

  const facebookLogin = () => {
    window.location.href = a54;
  };
  return (
    <div className="checkOutMainBox">
      <div className="headingBox">
        <Heading />
      </div>
      <div
        style={{ marginTop: 0, paddingTop: "50px" }}
        className="checkoutNewLoginMainBox"
      >
        <h2 style={{ marginBottom: "20px" }}> Login / Register to Ghare's</h2>
        {oldUser === "EmailPage" ? (
          <div className="checkoutNewLoginFormBox">
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px",
                  cursor: "pointer",
                }}
                // style={{ width: "100%" }}
                className="socialMediaOption"
                onClick={() => {
                  googleLogin();
                }}
              >
                <FcGoogle size={"1.5rem"} />
                <li
                  style={{
                    color: "grey",
                    listStyle: "none",
                    marginInline: "10px",
                  }}
                >
                  Google
                </li>
              </button>
            </div> */}
            <div className="loginSocialMediaOptionsBox">
              <button
                className="socialMediaOption"
                onClick={() => {
                  googleLogin();
                }}
              >
                <FcGoogle size={"1.5rem"} style={{ marginRight: "8px" }} />
                <li style={{ color: "grey", listStyle: "none" }}>Google</li>
              </button>
              <button
                className="socialMediaOption"
                onClick={() => {
                  facebookLogin();
                }}
              >
                <GrFacebook
                  size={"1.5rem"}
                  style={{ color: "#1877F2", marginRight: "8px" }}
                />
                <li style={{ color: "#1877F2", listStyle: "none" }}>
                  Facebook
                </li>
              </button>
            </div>
            <h4
              style={{
                textAlign: "center",
                marginTop: "1rem",
                marginBottom: "0rem",
                color: "grey",
              }}
            >
              Or
            </h4>
            {/* <h3 style={{ color: "black" }}>
              Enter your email address to proceed
            </h3>
            <p>We need your email address to send updates about your order.</p> */}
            <label>
              Mobile<sup style={{ color: "red" }}>*</sup>
            </label>

            <div
              id="emailInput"
              className={`password-input ${
                email && !validateEmail(email) ? "invalid" : ""
              } ${email && validateEmail(email) ? "valid" : ""} ${
                !email ? "blank" : ""
              }`}
            >
              <input
                id="emailInputField"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="tel"
                pattern="[0-9]{10}"
                maxLength={10}
              />
              {email && !validateEmail(email) && (
                <p style={{ margin: "0" }} className="invalid">
                  Please enter a valid number
                </p>
              )}
              {email && validateEmail(email) && (
                <RxCheck className="checkMark" size="20px" />
              )}
              {email && !validateEmail(email) && (
                <RxCross2
                  color="red"
                  style={{ top: "40%" }}
                  className="checkMark"
                  size="20px"
                />
              )}
            </div>
            {/* <p>{email}</p> */}
            {dataRcvd ? (
              <button
                className="checkoutNewLoginFormBoxButton"
                onClick={() => ifNewUser(email)}
              >
                Continue
              </button>
            ) : null}
            <p
              id="newToGhareLine"
              onClick={() => {
                navigate("/customersignuppage");
                // setPageFor("Register");
                // setOldUser("RegisterPage");
              }}
              style={{ textAlign: "center" }}
            >
              New To Ghare's, <span>Create an Account</span>
            </p>
          </div>
        ) : oldUser === "OldUser" ? (
          <div className="checkoutNewLoginFormBox">
            <h3 style={{ color: "black" }}>Welcome !</h3>
            <p style={{ margin: "10px 0", marginBottom: "50px" }}>
              **{email && email.substr(-10, 100)}
            </p>
            <p style={{ margin: "20px 0" }}>
              Please login to proceed to checkout.
            </p>
            <label>
              OTP<sup style={{ color: "red" }}>*</sup>
            </label>
            {/* <div id="password-input" className={`password-input`}> */}
            <div
              className={`password-input ${incorrectPassword ? "invalid" : ""}`}
            >
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="toggle-password-visibility"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "HIDE" : "SHOW"}
              </button>
              {incorrectPassword ? (
                <p style={{ margin: "0" }} className="invalid">
                  Incorrect OTP, please try again.
                </p>
              ) : null}
              {incorrectPassword ? (
                <RxCross2
                  color="red"
                  style={{ top: "40%" }}
                  className="checkMark"
                  size="20px"
                />
              ) : null}
              {/* {password && !checkPassword(password) && ( */}
            </div>
            {/* </div> */}
            {/* <p>{email}</p> */}

            <Link to="/changepassword">
              <p style={{ textAlign: "end", color: "black" }}>
                Forgot Password
              </p>
            </Link>
            {dataRcvd ? (
              <button
                className="checkoutNewLoginFormBoxButton"
                onClick={() => checkLogin(csType)}
              >
                Continue
              </button>
            ) : null}
            <p
              onClick={() => {
                setOldUser("EmailPage"),
                  setEmail(""),
                  setPassword(""),
                  window.scrollTo(0, 0);
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              <u>Use another mobile number</u>
            </p>
          </div>
        ) : (
          <>
            <div
              style={{ marginBottom: "10px" }}
              className="checkoutNewLoginFormBox"
            >
              <h3 style={{ color: "black" }}>Welcome !</h3>
              <p style={{ margin: "10px 0", marginBottom: "20px" }}>{email}</p>
              <p>Become a member today – It’s quick and free!</p>
            </div>
            <div
              style={{ marginTop: "10px", marginBottom: "20px" }}
              className="checkoutNewLoginFormBox"
            >
              <h3 style={{ color: "black" }}>New customer checkout</h3>
              <p>Become a member today – It’s quick and free!</p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                100 bonus points when you join
              </p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                Free standard delivery
              </p>
              <p style={{ margin: "0", display: "flex", alignItems: "center" }}>
                <RxCheck
                  style={{ color: "grey", marginRight: "10px" }}
                  size={"20px"}
                />
                Access to brand partnerships and offers
              </p>
              {/* <div id="password-input" className={`password-input`}> */}
              <div className="password-input">
                {/* <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                /> */}
                <button
                  className="toggle-password-visibility"
                  onClick={togglePasswordVisibility}
                >
                  {/* {showPassword ? "HIDE" : "SHOW"} */}
                </button>
              </div>
              {/* </div> */}
              {/* <p>{email}</p> */}
              {dataRcvd ? (
                <button
                  className="checkoutNewLoginFormBoxButton"
                  onClick={() => handleSendOtp("New")}
                >
                  Continue
                </button>
              ) : null}
            </div>
            <p
              onClick={() => {
                setOldUser("EmailPage"),
                  setEmail(""),
                  setPassword(""),
                  window.scrollTo(0, 0);
              }}
              style={{
                textAlign: "center",
                cursor: "pointer",
                fontSize: "13px",
                marginBottom: "100px",
              }}
            >
              <u>Use another email address</u>
            </p>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
}
