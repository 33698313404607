import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import newLogo from "../../../Images/gharesNewLogo4.png";
import { adminLoggedIn } from "../../../../redux/action/Actions";
import { useDispatch } from "react-redux";

export default function AdminPanelLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loggedIn = (user) => {
    dispatch(adminLoggedIn(user));
  };
  const handleLogin = () => {
    if (username == "Shivamghare@123" && password == "9819662720") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("1");
    } else if (username == "Staff1" && password == "sunshine21") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("2");
    } else if (username == "Staff2" && password == "soccerlover") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("3");
    } else if (username == "Staff3" && password == "chocolate123") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("4");
    } else if (username == "Staff4" && password == "beach2020") {
      // alert(`Welcome ${username}`);
      navigate("/adminhome");
      loggedIn("5");
    } else {
      alert("Sorry Incorrect Details");
    }
  };

  return (
    // <div className="adminLoginPageMain">
    //   <div
    //     className="adminLoginForm"
    //     style={{
    //       // width: "20rem",
    //       borderRadius: "5px",
    //       boxShadow: "5px 5px 20px black",
    //       padding: "2rem 3rem",
    //     }}
    //   >
    //     <img
    //       style={{
    //         width: "15rem",
    //         backgroundColor: "rgb(255, 250, 220",
    //         borderRadius: "5px",
    //       }}
    //       src={newLogo}
    //       alt="Ghare's New Logo"
    //     />
    //     <h3 style={{ margin: "1rem" }}>Admin Login</h3>
    //     {/* <h3> */}
    //     <label style={{ color: "black", textAlign: "left", fontSize: "small" }}>
    //       USERNAME
    //     </label>
    //     {/* </h3> */}
    //     <input
    //       value={username}
    //       type="text"
    //       onChange={(e) => setUsername(e.target.value)}
    //     />
    //     {/* <h3> */}
    //     <label
    //       style={{
    //         color: "black",
    //         textAlign: "left",
    //         fontSize: "small",
    //         marginTop: "1rem",
    //       }}
    //     >
    //       {/* <label> */}
    //       PASSWORD
    //     </label>
    //     {/* </h3> */}
    //     <input
    //       value={password}
    //       type="password"
    //       onChange={(e) => setPassword(e.target.value)}
    //     />
    //     <button style={{ borderRadius: "5px" }} onClick={() => handleLogin()}>
    //       Login
    //     </button>
    //   </div>
    // </div>

    <div
      style={{
        width: "100%",
        backgroundColor: "#1a4c2d",
        height: "100vh",
      }}
      className="checkoutFullPageFormSideBox"
      // data-aos="fade-down"
    >
      <div
        className="loginPageBox"
        style={{
          backgroundColor: "rgb(255, 250, 220",
          // backgroundColor: "white",
          padding: "2rem",
          borderRadius: "5px",
        }}
      >
        {/* <div className="loginSocialMediaOptionsBox">
        <button className="socialMediaOption">
        <FcGoogle size={"1.5rem"} style={{ marginRight: "8px" }} />
        <li style={{ color: "grey", listStyle: "none" }}>Google</li>
        </button>
        <button className="socialMediaOption">
        <GrFacebook size={"1.5rem"} style={{ marginRight: "8px" }} />
        <li style={{ color: "grey", listStyle: "none" }}>Facebook</li>
        </button>
        </div>
        <h4
        style={{ marginTop: "2rem", marginBottom: "1rem", color: "grey" }}
        >
        Or
      </h4> */}

        <form
          className="loginFormCheckoutFullPage adminLoginPanelMainBox"
          onSubmit={handleLogin}
        >
          <img
            style={{
              width: "15rem",
              margin: "2rem",
              marginBottom: "0",
              marginTop: "0",
              marginLeft: "0",
              backgroundColor: "rgb(255, 250, 220",
              borderRadius: "5px",
            }}
            src={newLogo}
            alt="Ghare's New Logo"
          />
          <hr></hr>
          <p
            style={{
              alignSelf: "flex-start",
              color: "black",
              marginBottom: "2rem",
              marginTop: "3rem",
            }}
          >
            Admin Login to Ghare's
          </p>
          {/* <hr></hr> */}
          <p style={{ alignSelf: "flex-start" }}>Enter Username</p>
          <input
            required="required"
            type={"text"}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <p style={{ alignSelf: "flex-start" }}>Enter Password</p>
          <input
            required="required"
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="checkoutNewLoginFormBoxButton"
            style={{ margin: "32px 0", fontSize: "small" }}
            type="submit"
            // className="checkoutSecurelyBtn checkoutLoginBtn"
          >
            Log In
          </button>
        </form>
        {/* <h4 style={{ color: "grey", marginBottom: "1rem", marginTop: "1rem" }}>
          Or
        </h4> */}
        {/* <p> 
          <span>New to Ghare's? </span>
          <span
            className="createAccountLink"
            onClick={() => {
              setRegister("Register"), window.scrollTo(0, 0);
              calculateProgress();
            }}
          >
            Create an Account
          </span>
        </p> */}
        {/* <button
        onClick={() => {
          setRegister("deliveryDetails");
          setRegistersteps(registerSteps + 1);
          calculateProgress();
        }}
      >
        Next Step
      </button> */}
      </div>
    </div>
  );
}
