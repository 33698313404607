import React, { useEffect, useState } from "react";
import AdminNavbar from "./AdminComponent/AdminNavbar";
import { useSelector } from "react-redux";
import { a33, a41, a43 } from "../../Api/RootApiPath";
import { BsImages } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";

export default function AdminBulkUpdateProducts() {
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collectionData, setCollectionData] = useState([]);
  const [collectionId, setCollectionId] = useState("");
  const [size, setSize] = useState("");
  const [sizesData, setSizesData] = useState([]);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [totalMatchedItems, setTotalMatchedItems] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [productData, setProductData] = useState({
    product_No: "",
    collectionId: "",
    product_Code: "",
    grosswt: "",
    hsn_code: "",
    huidCode: "",
    making_Fixed_Amt: "",
    making_Fixed_Wastage: "",
    making_Percentage: "",
    making_per_gram: "",
    mrp: 0,
    offerPrice: "",
    netWt: "",
    stoneWeight: "",
    images: "",
    videos: "",
    estimatedDays: "",
    description: "",
    stoneAmount: "",
    phuid: "",
    product_Name: "",
    pieces: "",
    size: "",
    occasion: "",
    edition: "",
    gender: "",
    saleFrom: "",
  });

  const navigate = useNavigate();
  const fetchAllProducts = async () => {
    fetch(a41)
      .then((res) => res.json())
      .then((data) => {
        setAllProducts(data.data);
        setLoading(false);
      });
  };

  const fetchAllCollection = async () => {
    fetch(a33)
      .then((res) => res.json())
      .then((data) => {
        setCollectionData(data.data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllProducts();
    fetchAllCollection();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getSingleProductByCollecton = (e) => {
    setDataAvailable(false);
    const { value } = e.target;
    const filteredProducts = sizesData.filter(
      (x) => x.collectionId == collectionId && x.size == value
    );

    if (filteredProducts.length > 0) {
      setSelectedProductData(filteredProducts[0]);
      setDataAvailable(true);
      setTotalMatchedItems(filteredProducts.length);

      // Update the productData state with the data of the currently selected product
      setProductData({
        ...filteredProducts[0],
        images:
          selectedFiles.length > 0 ? selectedFiles : filteredProducts[0].images,
      });
    } else {
      setDataAvailable(false);
      setSelectedProductData([]);
      setTotalMatchedItems(filteredProducts.length);
    }
  };

  console.log(selectedProductData, "selectedProductData");
  console.log(allProducts, "allProducts");
  const handleSubmit = async () => {
    setLoading(true);

    try {
      const formData = new FormData();

      // Add other fields to the formData
      formData.append("Product_No", productData.product_No);
      formData.append("CollectionId", productData.collectionId);
      formData.append("Product_code", productData.product_Code);
      formData.append("grosswt", productData.grosswt);
      formData.append("hsn_code", productData.hsn_code);
      formData.append("HUIDCode", productData.huidCode);
      formData.append("Making_Fixed_Amt", productData.making_Fixed_Amt);
      formData.append("Making_Fixed_Wastage", productData.making_Fixed_Wastage);
      formData.append("Making_Percentage", productData.making_Percentage);
      formData.append("Making_per_gram", productData.making_per_gram);
      formData.append("MRP", productData.mrp);
      formData.append("OfferPrice", productData.offerPrice);
      formData.append("NetWt", productData.netWt);
      formData.append("StoneWeight", productData.stoneWeight);
      formData.append("EstimatedDays", productData.estimatedDays);
      formData.append("description", productData.description);
      formData.append("StoneAmount", productData.stoneAmount);
      formData.append("Phuid", productData.phuid);
      formData.append("Product_Name", productData.product_Name);
      formData.append("Pieces", productData.pieces);
      formData.append("Size", productData.size);
      formData.append("occasion", productData.occasion);
      formData.append("edition", productData.edition);
      formData.append("gender", productData.gender);
      formData.append("videos", productData.videos);
      formData.append("saleFrom", productData.saleFrom);
      // formData.append("OnlineStatus", productData.onlineStatus);

      // If there are new images selected, add them to the formData
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file, index) => {
          formData.append(`file${index}`, file);
        });
      } else {
        // If no new images are selected, keep the existing images from productData.images
        // if (productData.images && productData.images.length > 0) {
        formData.append("Images", productData.images); // Assuming that productData.images is an array
        // }
      }

      console.log("Sending formData:", formData);

      // Send the POST request with the formData
      const response = await fetch(a43, {
        method: "POST",
        body: formData,
      });

      console.log("Response:", response);

      const rcvdData = await response.json();
      console.log("rcvdData", rcvdData);

      alert("Product data changed successfully");
      navigate("/adminaddbulkstock");
      setProductData(rcvdData.data[0]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // const handleSubmit = async () => {
  //   setLoading(true);

  //   try {
  //     const requestData = {
  //       Product_No: productData.product_No,
  //       CollectionId: productData.collectionId,
  //       Product_code: productData.product_Code,
  //       grosswt: productData.grosswt,
  //       hsn_code: productData.hsn_code,
  //       huidCode: productData.huidCode,
  //       Making_Fixed_Amt: productData.making_Fixed_Amt,
  //       Making_Fixed_Wastage: productData.making_Fixed_Wastage,
  //       Making_Percentage: productData.making_Percentage,
  //       Making_per_gram: productData.making_per_gram,
  //       MRP: productData.mrp,
  //       NetWt: productData.netWt,
  //       StoneWeight: productData.stoneWeight,
  //       EstimatedDays: productData.estimatedDays,
  //       description: productData.description,
  //       StoneAmount: productData.stoneAmount,
  //       Product_Name: productData.product_Name,
  //       Pieces: productData.pieces,
  //       Size: productData.size,
  //       occasion: productData.occasion,
  //       gender: productData.gender,
  //       OnlineStatus: productData.onlineStatus,
  //     };

  //     // If there are new images selected, add them to the requestData
  //     if (selectedFiles.length > 0) {
  //       requestData.images = selectedFiles;
  //     } else {
  //       // If no new images are selected, keep the existing images from selectedProductData
  //       requestData.images = selectedProductData.images;
  //     }

  //     console.log("Sending requestData:", requestData);

  //     // Send the POST request with the requestData as JSON
  //     const response = await fetch(a43, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     const rcvdData = await response.json();
  //     console.log("rcvdData", rcvdData);
  //     alert("Product data changed successfully");
  //     // navigate("/adminaddbulkstock");
  //     setProductData(rcvdData.data[0]);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     setLoading(false);
  //   }
  // };

  //   const handleSubmit = async () => {
  //     setLoading(true);

  //     try {
  //       const formData = new FormData();

  //       // Add other fields to the formData
  //       formData.append("Product_No", productData.product_No);
  //       formData.append("CollectionId", productData.collectionId);
  //       formData.append("Product_code", productData.product_Code);
  //       formData.append("grosswt", productData.grosswt);
  //       formData.append("hsn_code", productData.hsn_code);
  //       formData.append("HUIDCode", productData.huidCode);
  //       formData.append("Making_Fixed_Amt", productData.making_Fixed_Amt);
  //       formData.append("Making_Fixed_Wastage", productData.making_Fixed_Wastage);
  //       formData.append("Making_Percentage", productData.making_Percentage);
  //       formData.append("Making_per_gram", productData.making_per_gram);
  //       formData.append("MRP", productData.mrp);
  //       formData.append("NetWt", productData.netWt);
  //       formData.append("StoneWeight", productData.stoneWeight);
  //       formData.append("EstimatedDays", productData.estimatedDays);
  //       formData.append("description", productData.description);
  //       formData.append("StoneAmount", productData.stoneAmount);
  //       formData.append("Product_Name", productData.product_Name);
  //       formData.append("Pieces", productData.pieces);
  //       formData.append("Size", productData.size);
  //       formData.append("occasion", productData.occasion);
  //       formData.append("gender", productData.gender);
  //       formData.append("OnlineStatus", productData.onlineStatus);

  //       // If there are new images selected, add them to the formData
  //       if (selectedFiles.length > 0) {
  //         selectedFiles.forEach((file, index) => {
  //           formData.append(`file${index}`, file);
  //         });
  //       } else {
  //         // If no new images are selected, keep the existing images from selectedProductData
  //         formData.append("Images", selectedProductData.images);
  //       }

  //       console.log(formData);
  //       //   console.log("Current FormData values:");

  //       //   for (const [key, value] of formData.entries()) {
  //       //     console.log(key, value);
  //       //   }
  //       //   Send the POST request with the formData
  //       //   Replace YOUR_API_ENDPOINT with the actual API endpoint URL
  //       const response = await fetch(a43, {
  //         method: "POST",
  //         body: formData,
  //       });

  //       const rcvdData = await response.json();
  //       console.log("rcvdData", rcvdData);

  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //       setLoading(false);
  //     }
  //   };
  let uniqueSizes = Array.from(
    new Set(sizesData.map((x) => x.collectionId == collectionId && x.size))
  );
  const resetProductData = () => {
    setProductData({
      product_No: "",
      collectionId: "",
      product_Code: "",
      grosswt: "",
      hsn_code: "",
      huidCode: "",
      making_Fixed_Amt: "",
      making_Fixed_Wastage: "",
      making_Percentage: "",
      making_per_gram: "",
      mrp: 0,
      offerPrice: "",
      netWt: "",
      stoneWeight: "",
      images: "",
      videos: "",
      estimatedDays: "",
      description: "",
      stoneAmount: "",
      phuid: "",
      product_Name: "",
      pieces: "",
      size: "",
      occasion: "",
      edition: "",
      gender: "",
      saleFrom: "",
    });
  };
  useEffect(() => {
    uniqueSizes = allProducts.filter((x) => x.size);
    setSizesData(uniqueSizes);
  }, [collectionId]);
  useEffect(() => {
    setProductData(selectedProductData);
  }, [selectedProductData]);

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };

  const allStates = useSelector((state) => state);
  const sidebarState = allStates.reducer6.isOpen;
  const adminLoggedIn = allStates.reducer5;
  let Entryby_Staff_id = parseInt(adminLoggedIn);

  return (
    <div className="adminHomeMainBox">
      <div className="navBox">
        <AdminNavbar />
      </div>
      <div
        className={
          sidebarState ? "adminContentbox" : "adminContentbox adminContentbox2"
        }
      >
        <h3 style={{ marginBottom: "3rem", width: "95%" }}>
          Update All Products of any collection
        </h3>
        <div className="adminBulkUpdateProductsProductMainBox">
          <div className="adminBulkUpdateProductsProductBox">
            <label>Select Collection</label>
            <select
              required="required"
              value={collectionId}
              onChange={(e) => {
                setCollectionId(e.target.value);
                setSelectedProductData([]);
                setDataAvailable(false);
                setSize("");
                resetProductData();
                // getSingleProductByCollecton(e);
              }}
            >
              <option value="">Select an option</option>
              {collectionData.map((x) => {
                return (
                  <option
                    key={x.id}
                    value={x.id}
                  >{`${x.collection_Name}  (${x.label})`}</option>
                );
              })}
            </select>

            <label>Select Size</label>
            <select
              required="required"
              value={size}
              onChange={(e) => {
                setSize(e.target.value);
                setDataAvailable(true);
                getSingleProductByCollecton(e);
              }}
            >
              <option value="">Select an option</option>
              {uniqueSizes.map((x, index) => {
                return <option key={index} value={x.size}>{`${x}`}</option>;
              })}
            </select>
            <div
              style={{ height: "100px", marginBottom: "1rem" }}
              className={loading == true ? "loading" : "none"}
            >
              <InfinitySpin
                className={loading == true ? "loading" : "none"}
                width="150"
                color="#4fa94d"
              />
            </div>
            <h4>Total {totalMatchedItems} items</h4>
            <div
              className="bulkProductAddingTableMain"
              style={{ margin: "1.5rem 0", overflowX: "auto" }}
            >
              <table>
                <thead>
                  <tr>
                    <th>Product name</th>
                    <th>Product Code</th>
                    <th>HSN Code</th>
                    <th>HUID Code</th>
                    <th>GrossWt</th>
                    <th>StoneWt</th>
                    <th>NetWt</th>
                    <th>Stone Amount</th>
                    <th>Product Huid</th>
                    <th>Making Per Gram</th>
                    <th>Making Percentage</th>
                    <th>Fixed Making</th>
                    <th>Fixed Wastage</th>
                    <th>Pieces</th>
                    <th>Size</th>
                    <th>MRP</th>
                    <th>Offer Price</th>
                    <th>Description</th>
                    <th>Occasion</th>
                    <th>Edition</th>
                    <th>Gender</th>
                    <th>Estimated Days</th>
                    <th>Videos</th>
                    <th>Sale From</th>
                  </tr>
                </thead>
                <tbody>
                  {dataAvailable ? (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="product_Name"
                          placeholder={selectedProductData.product_Name}
                          value={productData.product_Name}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="product_Code"
                          placeholder={selectedProductData.product_Code}
                          value={productData.product_Code}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="hsn_code"
                          placeholder={selectedProductData.hsn_code}
                          value={productData.hsn_code}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="huidCode"
                          placeholder={selectedProductData.huidCode}
                          value={productData.huidCode}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="grosswt"
                          placeholder={selectedProductData.grosswt}
                          value={productData.grosswt}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="stoneWeight"
                          placeholder={selectedProductData.stoneWeight}
                          value={productData.stoneWeight}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="netWt"
                          placeholder={selectedProductData.netWt}
                          value={productData.netWt}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="stoneAmount"
                          placeholder={selectedProductData.stoneAmount}
                          value={productData.stoneAmount}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="phuid"
                          placeholder={selectedProductData.phuid}
                          value={productData.phuid}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="making_per_gram"
                          placeholder={selectedProductData.making_per_gram}
                          value={productData.making_per_gram}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="making_Percentage"
                          placeholder={selectedProductData.making_Percentage}
                          value={productData.making_Percentage}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="making_Fixed_Amt"
                          placeholder={selectedProductData.making_Fixed_Amt}
                          value={productData.making_Fixed_Amt}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="making_Fixed_Wastage"
                          placeholder={selectedProductData.making_Fixed_Wastage}
                          value={productData.making_Fixed_Wastage}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="pieces"
                          placeholder={selectedProductData.pieces}
                          value={productData.pieces}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="size"
                          placeholder={selectedProductData.size}
                          value={productData.size}
                          readOnly
                          // onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="mrp"
                          placeholder={selectedProductData.mrp}
                          value={productData.mrp}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="offerPrice"
                          placeholder={selectedProductData.offerPrice}
                          value={productData.offerPrice}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="description"
                          placeholder={selectedProductData.description}
                          value={productData.description}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="occasion"
                          placeholder={selectedProductData.occasion}
                          value={productData.occasion}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="edition"
                          placeholder={selectedProductData.edition}
                          value={productData.edition}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="gender"
                          placeholder={selectedProductData.gender}
                          value={productData.gender}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="estimatedDays"
                          placeholder={selectedProductData.estimatedDays}
                          value={productData.estimatedDays}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="videos"
                          placeholder={selectedProductData.videos}
                          value={productData.videos}
                          onChange={handleInputChange}
                        />
                      </td>
                      <td>
                        {/* <input
                          type="text"
                          name="saleFrom"
                          placeholder={selectedProductData.saleFrom}
                          value={productData.saleFrom}
                          onChange={handleInputChange}
                        /> */}
                        <select
                          value={productData.saleFrom}
                          name="saleFrom"
                          placeholder={selectedProductData.saleFrom}
                          onChange={handleInputChange}
                        >
                          <option value={"online"}>Online</option>
                          <option value={"offline"}>Offline</option>
                        </select>
                      </td>
                      {/* <td>
                        <input
                          type="text"
                          name="onlineStatus"
                          placeholder={selectedProductData.onlineStatus}
                          value={productData.onlineStatus}
                          onChange={handleInputChange}
                        />
                      </td> */}
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <label style={{ margin: "20px 0", cursor: "pointer" }}>
                Images {`${selectedFiles.length}`}
                {/* <div className="bulkStockAddProductDetailsImageBox"> */}
                <BsImages style={{ marginInline: "1rem" }} size={"1rem"} />
                <input
                  id="images"
                  style={{ display: "none" }}
                  type="file"
                  multiple
                  value={""} // Add an empty string here to clear the previous file selection
                  onChange={handleFileInputChange}
                />
                {/* </div> */}
              </label>
            </div>
            <button
              style={{
                backgroundColor: "#f1556c",
                padding: "10px 40px",
                border: "none",
                borderRadius: "5px",
              }}
              className="adminAddBulkToUpdatePageButton"
              onClick={handleSubmit}
            >
              Update All Products
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
